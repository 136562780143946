<template>
  <section style="overflow: auto; height: 100vh; display: flex; justify-content: center; align-items: center; padding: 1%;">
          <div style="display: flex; justify-content: flex-start; align-self: center; flex-direction: column; gap:10px; width:98%; height: 108%; margin-top: 10%;">
            <div style="display: flex; flex-direction: row; justify-content: center; align-items: center; gap: 800px; width:100%; margin-top: 35px;">
              <div style="flex: 1; text-align: center; font-weight: 600; font-size: 1.5vw; text-transform: capitalize;">
                Recent Concepts
              </div>
              <div style="flex: 1; text-align: center; font-weight: 600; font-size: 1.5vw; text-transform: capitalize;">
                <Tour/>
              </div>
            </div>
            <div style="display: flex; flex-direction: column; flex-grow: 1; justify-content: flex-start; margin-top: 15px; width:100%; max-height: 35% ">
              <div v-if="loading" style="display: flex; justify-content: space-around; width:100%;">
                <LoadingSpinner></LoadingSpinner>
              </div>
              <div v-if="assets.length > 0 && !loading" style="display: flex; justify-content: space-around; width:100%;">
                <div v-for="(asset, index) in assets.slice(0, 3)" :key="asset.id" style="width: 30%;" @click="redirectToLibrary" class="clickable-div">
                  <div class="lifecycle-container" style="display: flex; flex-direction: column; align-items: center; width:100%; gap:10px; justify-content: space-around;">
                    <div style="display: flex; align-items: center;">
                      <font-awesome-icon :icon="['fas', 'info-circle']" style="color: #7878E9; margin-right: 10px;" />
                      <p style="font-size: 14px; margin: 0;">Click on the concept to explore your options</p>
                    </div>
                    <asset-tile :asset="asset" :isMyLibrary="false"  style="pointer-events: none; width: auto;"></asset-tile>
                    <ConceptLifecycle :lifecycle="asset.lifecycle" :lightVersion="true" :inverted="false" :showTitle="false" style="height:50px;"></ConceptLifecycle>
                  </div>
                </div>
              </div>
              <div v-if="assets.length ===0 && !loading" @click="redirectToLibrary" class="placeholder">
                You need to add some concepts first
              </div>
            </div>
            <div style="display: flex; justify-content: space-around; width:100%;">
              <div style="width: 40%; font-weight: 600; font-size: 1.5vw; text-transform: capitalize;">
                Mindspeller Tips
              </div>
              <div style="width: 50%; font-weight: 600; font-size: 1.5vw; text-transform: capitalize;">
                Mindspeller Tools
              </div>
            </div>
            <div style="display: flex; justify-content: space-around; width:100%;">
              <div style="width: 40%;">
                <div style="text-align: justify;">You can leverage Mindspeller’s implicit association network in various ways to create meaningful and resonant marketing campaigns. <b>Here are some strategies:</b></div>
              </div>
              <div style="width: 50%;">
                <div style="text-align: justify;">Mindspeller helps you create impactful campaigns that resonate with your audience on a deeper level, fostering connections, loyalty, and positive brand perceptions. <b>To do so, we propose the following tools:</b></div>
              </div>
            </div>
            <div style="display: flex; justify-content: space-around; width:100%; overflow-y: auto">
              <div style="width: 40%; overflow-y: auto">
                <div style="position: sticky; top: 0; background-color: whitesmoke; z-index: 1; padding: 2px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); overflow-y: auto">
                  <div class="instVideoHome">
                    <p>Crash Course:</p>
                    <instructions-video-button
                      v-if="instructionsVideoUrl1 !== ''"
                      id="instructionsVideoUrl1"
                      tooltipText="How to identify an asset or a liability - pt.1"
                      :instructionsVideoUrl="instructionsVideoUrl1"
                      instructionsVideoTitle="How to identify an asset or a liability - pt.1"
                      instructionsName="How to identify an asst or a liability - pt.1"
                      :suppress-video="true"
                      :showSupressFooter="false" 
                    >
                    </instructions-video-button>
                    <instructions-video-button
                      v-if="instructionsVideoUrl2 !== ''"
                      id="instructionsVideoUrl2"
                      tooltipText="How to identify an asset or a liability - pt.2"
                      :instructionsVideoUrl="instructionsVideoUrl2"
                      instructionsVideoTitle="How to identify an asset or a liability - pt.2"
                      instructionsName="How to identify an asst or a liability - pt.2"
                      :suppress-video="true"
                      :showSupressFooter="false" 
                    >
                    </instructions-video-button>
                    <instructions-video-button
                      v-if="instructionsVideoUrl3 !== ''"
                      tooltipText="How to identify an asset or a liability - pt.3"
                      :instructionsVideoUrl="instructionsVideoUrl3"
                      instructionsVideoTitle="How to identify an asset or a liability - pt.3"
                      instructionsName="How to identify an asst or a liability - pt.3"
                      :suppress-video="true"
                      :showSupressFooter="false" 
                    >
                    </instructions-video-button>
                    <instructions-video-button
                      v-if="instructionsVideoUrl4 !== ''"
                      tooltipText="How to identify an asset or a liability - pt.4"
                      :instructionsVideoUrl="instructionsVideoUrl4"
                      instructionsVideoTitle="How to identify an asset or a liability - pt.4"
                      instructionsName="How to identify an asst or a liability - pt.4"
                      :suppress-video="true"
                      :showSupressFooter="false" 
                    >
                    </instructions-video-button>
                    <instructions-video-button
                      v-if="instructionsVideoUrl5 !== ''"
                      tooltipText="How to identify an asset or a liability - pt.5"
                      :instructionsVideoUrl="instructionsVideoUrl5"
                      instructionsVideoTitle="How to identify an asset or a liability - pt.5"
                      instructionsName="How to identify an asst or a liability - pt.5"
                      :suppress-video="true"
                      :showSupressFooter="false" 
                    >
                    </instructions-video-button>
                  </div>
                </div>
                <div class="scrollable-cards" overflow-y: auto>
                  <q-card
                    v-for="(item, index) in tipItems"
                    :key="index"
                    class="q-mb-md"
                  >
                    <q-card-section style="display: flex; flex-direction: column; gap:20px; margin-left: 10px; margin-top: 10px; margin-right: 10px;">
                      <q-card-title>
                        <font-awesome-icon style="color: #7878E9; width: 15px; height: 15px; margin-right: 10px;" :icon="['fas', 'info-circle']" />
                        <span style="font-weight: bolder;">{{ item.title }}</span>
                      </q-card-title>
                      <q-card-text style="text-align: justify;">{{ item.description }}</q-card-text>
                    </q-card-section>
                  </q-card>
                </div>
              </div>
              <div style="width: 50%;">
                <div class="scrollable-tools" v-if="!isLoading">
                    <div style="margin-left: 20px; margin-top: 10px; margin-right: 20px;"
                      v-for="items in this.tools"
                      v-bind:key="items"
                      @click="handleApps(items.name)"
                    >
                      <dashboard-apps
                        :name="items.name"
                        :desc="items.desc"
                        :showTickBtn="true"
                      ></dashboard-apps>
                    </div>
                </div>
              </div>
            </div>

        </div>
  </section>
</template>

<script>
import axios from 'axios';
import DashboardApps from './common/DashboardApps.vue';
import LoadingSpinner from '../common/LoadingSpinner.vue';
import ConceptLifecycle from '../asset_library/ConceptLifecycle.vue';
import AssetTile from '../asset_library/AssetTile.vue';
import Tour from '../header/Tour.vue';
import InstructionsVideoButton from '@/components/common/InstructionsVideoButton.vue';

export default {
  name: 'Help',
  components: {
    DashboardApps,
    LoadingSpinner,
    ConceptLifecycle,
    AssetTile,
    InstructionsVideoButton,
    Tour
  },
  data() {
    return {
      decoder: [],
      drive: [],
      develop: [],
      tools: [],
      instructionsVideoUrl1: this.$store.getters.getInstructionalVideoUrl('cc1'),
      instructionsVideoUrl2: this.$store.getters.getInstructionalVideoUrl('cc2'),
      instructionsVideoUrl3: this.$store.getters.getInstructionalVideoUrl('cc3'),
      instructionsVideoUrl4: this.$store.getters.getInstructionalVideoUrl('cc4'),
      instructionsVideoUrl5: this.$store.getters.getInstructionalVideoUrl('cc5'),
      selectedFile: null,
      image: '',
      total_asset: '',
      isLoading: true,
      loading: true, 
      assets:[],
      graphData: {},
      archetypes: [],
      dataset: [],
      tipItems: [
        {
          title: 'Cultural Sensitivity',
          description: 'Understand the cultural nuances and historical context relevant to your target audience by customizing your respondent panel. Tailor your messaging and imagery to resonate with their collective memory while avoiding stereotypes or insensitive portrayals.',
        },
        {
          title: 'Storytelling',
          description: 'Use storytelling techniques to evoke emotions and connect with your audience on a deeper level. Highlight narratives that tap into shared historical experiences or cultural symbols, fostering a sense of belonging and identification with your brand.',
        },
        {
          title: 'Authenticity',
          description: 'Authenticity is key to building trust and credibility with consumers. Incorporate genuine elements of history or heritage into your marketing narratives, demonstrating a sincere understanding and appreciation of your audience\'s collective memory.',
        },
        {
          title: 'Inclusive Representation',
          description: 'Ensure that your marketing materials reflect the diversity of experiences and perspectives within collective memory. Celebrate the multiplicity of voices and narratives, avoiding oversimplified or monolithic portrayals of historical events.',
        },
        {
          title: 'Dialogue and Engagement',
          description: 'Foster dialogue and engagement with your audience around topics related to collective memory. Encourage sharing of personal stories, memories, and reflections, creating opportunities for connection and community building.',
        },
        {
          title: 'Relevance and Timeliness',
          description: 'Stay attuned to current events, cultural trends, and commemorative occasions that resonate with collective memory. Align your marketing campaigns with relevant historical milestones or anniversaries, capitalizing on moments of heightened public interest and reflection.',
        },
        {
          title: 'Education and Awareness',
          description: 'Use your channels to educate and raise awareness about lesser-known aspects of history or underrepresented voices within collective memory. By shining a light on overlooked narratives, you can position your brand as a champion of inclusivity and social consciousness.',
        }
      ]
    };
  },
  mounted: async function() {
    this.isLoading = true;
    try {
      const resultData = await axios.get(`/api/cas/users/get_decoder_info`);
      if (resultData.data) {
        this.decoder = resultData.data;
      }
    } catch (error) {
      console.log(error);
    }
    try {
      const resultData = await axios.get(`/api/cas/users/get_develop_info`);
      if (resultData.data) {
        this.develop = resultData.data;
      }
    } catch (error) {
      console.log(error);
    }
    try {
      const resultData = await axios.get(`/api/cas/users/get_drive_info`);
      if (resultData.data) {
        this.drive = resultData.data;
      }
    } catch (error) {
      console.log(error);
    }
    this.tools = this.decoder.concat(this.develop, this.drive);
    this.isLoading = false;
    document.getElementById('mindspeller').style.overflow = 'auto';
    this._fetchAssets();
  },
  methods: {
    goToLibrary: function(){
      this.$router.push({name:"Library"});
    },
    handleApps: function(value) {

      var splitStr = value.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      this.$router.push({
        name: splitStr.join(' '),
      });
    },
    _fetchAssets: async function () {
      this.loading = true;
      const payload = {
        page_size: 4,
        page: 0,
        filter: {
          "search_string": "",
          "asset_type": "all",
          "asset_state": "all",
          "brand": "all",
          "monitoring_status":"all",
          "sort_by":"newest"
        },
        ping: 1,
        include_positionings: true,
      };
      const response = await axios.post('/api/cas/assets/search', payload);
      let concatenate_assets = response.data.assets.filter(asset => asset.state !== 5);
      this.assets = [...this.assets, ...concatenate_assets];
      this.loading = false;
      },
    redirectToLibrary() {
      this.$router.push({name:'Library'})
    }
  }
};
</script>

<style scoped>

.chart-bx-img {
  height: 100%;
}

.functions-div {
  position: relative;
}

.spinner-border {
  width: 5vw;
  height: 5vw;
  top: 15vw;
}
.scrollable-cards {
  height: 250px;
  overflow-y: auto;
  border-radius: 5px; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
}
/* Hide scrollbar for Chrome, Safari, and Opera */
.scrollable-cards::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}
.scrollable-cards::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}
/* Optional: You can also hide scrollbar for Firefox */
.scrollable-cards {
  scrollbar-width: 1; /* For Firefox */
}
.scrollable-tools{
  height: 282px;
  overflow-y: auto;
  border-radius: 5px; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
  display: flex; 
  width:100%; 
  flex-wrap: wrap;
  justify-content: center;
}
.lifecycle-container{
  height: fit-content;
  border-radius: 5px; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
  margin-left: 10px;
  margin-right: 10px;
}
/* Hide scrollbar for Chrome, Safari, and Opera */
.scrollable-tools::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}
.scrollable-tools::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}
/* Optional: You can also hide scrollbar for Firefox */
.scrollable-tools {
  scrollbar-width: 1; /* For Firefox */
}
.clickable-div {
  cursor: pointer;
}
.placeholder{
  display: flex; 
  margin-left:20px ;
  margin-top: 50px; 
  justify-content: space-around;
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
}
.instVideoHome{
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}
.instVideoHome p {
  margin: 0;
}

.tour-icon {
  /* display: flex;
  justify-content: right;
  align-items: right; */
  width: 50%;
  right: 0%;
 }
</style>
