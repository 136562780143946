<template>
  <div class="filter-bar">
    <div class="bar">
      <q-select
        dense
        borderless
        map-options
        emit-value
        v-model="type"
        :options="filterOptions.type"
        :label="`${$t('message.type').toUpperCase()} (${getLabel(type, filterOptions.type)})`"
      >
      </q-select>
    </div>
    <div class="bar">
      <q-select
        dense
        borderless
        map-options
        emit-value
        v-model="assetState"
        :options="filterOptions.status"
        :label="`${$t('message.status').toUpperCase()} (${getLabel(assetState, filterOptions.status)})`"
        :disable="disable_tracking"
        :no-ripple="disable_tracking"
      >
      </q-select>
    </div>
    <div class="bar">
      <q-select
        dense
        borderless
        map-options
        emit-value
        v-model="monitoringStatus"
        :options="filterOptions.tracking"
        :label="`${$t('message.tracking').toUpperCase()} (${getLabel(monitoringStatus, filterOptions.tracking)})`"
        :disable="disable_tracking"
        :no-ripple="disable_tracking"
      >
      </q-select>
    </div>
    <div class="bar">
      <q-select
        dense
        borderless
        map-options
        emit-value
        v-model="branding"
        :options="filterOptions.branding"
        :label="`${$t('message.branding').toUpperCase()} (${getLabel(branding, filterOptions.branding)})`"
      >
      </q-select>
    </div>
    <div class="bar">
      <q-select
        dense
        borderless
        map-options
        emit-value
        v-model="sortBy"
        :options="filterOptions.sorting"
        :label="`${$t('message.sort_by').toUpperCase()} (${getLabel(sortBy, filterOptions.sorting)})`"
      >
      </q-select>
    </div>
  </div>
</template>


<script>
export default {
  emits: [
    "typeChanged",
    "sortChanged",
    "monitoringStatusChanged",
    "assetStateChanged",
    "brandChanged",
  ],
  props: {
    allowed_types: { required: true, type: Array },
    positioned_assets_only: { type: Boolean, default: false },
    disable_tracking: { type: Boolean, default: false },
    libraryKey: {
      type: String,
      default: false,
    },
  },
  data: function () {
    let defaultOptions = {
      type:
        this.libraryKey === "my_library"
          ? this.allowed_types[0]
          : this.allowed_types[1],
      sortBy: "newest",
      monitoringStatus: "all",
      assetState: this.disable_tracking ? "positioned" : "all",
      branding: "all",
    };
    const types = [
      { label: this.$t("message.all"), value: "all" },
      { label: this.$t("message.images"), value: "images" },
      { label: this.$t("message.words"), value: "words" },
      { label: this.$t("message.videos_and_sounds"), value: "multimedia" },
    ];
    const filteredTypes = types.filter((t) =>
      this.allowed_types.includes(t.value)
    );
    return {
      filterOptions: {
        type: filteredTypes,
        status: [
          { label: this.$t("message.all"), value: "all" },
          { label: this.$t("message.positioned_assets"), value: "positioned" },
          {
            label: this.$t("message.assets_being_positioned"),
            value: "being_positioned",
          },
        ],
        tracking: [
          { label: this.$t("message.all"), value: "all" },
          { label: this.$t("message.only_tracked"), value: "monitored" },
        ],
        branding: [
          { label: this.$t("message.all"), value: "all" },
          { label: this.$t("message.branded"), value: "brand" },
          { label: this.$t("message.not_branded"), value: "not_brand" },
        ],
        sorting: [
          { label: this.$t("message.newest"), value: "newest" },
          { label: this.$t("message.oldest"), value: "oldest" },
          { label: this.$t("message.alphabetical"), value: "alphabetical" },
          {
            label: this.$t("message.reverse_alphabetical"),
            value: "reverse_alphabetical",
          },
          {
            label: this.$t("message.rating_desc"),
            value: "rating_desc",
          },
          {
            label: this.$t("message.rating_asc"),
            value: "rating_asc",
          },
        ],
      },
      watchPause: false,
      type: defaultOptions.type,
      sortBy: defaultOptions.sortBy,
      monitoringStatus: defaultOptions.monitoringStatus,
      assetState: defaultOptions.assetState,
      branding: defaultOptions.branding,
      showFilters: false,
      showOrdering: false,
    };
  },
  methods: {
    getLabel(value, options) {
      const selectedOption = options.find((option) => option.value === value);
      return selectedOption ? selectedOption.label : this.$t("message.all");
    },
    resetFilterBy: function () {
      this.watchPause = true;
      this.type = this.defaultOptions.type;
      this.monitoringStatus = this.defaultOptions.monitoringStatus;
      this.assetState = this.defaultOptions.assetState;
      this.watchPause = false;
    },
    closeFilters: function () {
      this.showFilters = false;
    },
    closeOrdering: function () {
      this.showOrdering = false;
    },
    toggleFilters: function () {
      this.showFilters = !this.showFilters;
    },
    toggleOrdering: function () {
      this.showOrdering = !this.showOrdering;
    },
  },
  watch: {
    type: function (value) {
      if (!this.watchPause) {
        this.$emit("typeChanged", value);
      }
    },
    sortBy: function (value) {
      if (!this.watchPause) {
        this.$emit("sortChanged", value);
      }
    },
    monitoringStatus: function (value) {
      if (!this.watchPause) {
        this.$emit("monitoringStatusChanged", value);
      }
    },
    assetState: function (value) {
      if (!this.watchPause) {
        this.$emit("assetStateChanged", value);
      }
    },
    branding: function (value) {
      if (!this.watchPause) {
        this.$emit("brandChanged", value);
      }
    },
  },
  mounted: function () {
    this.$emit("typeChanged", this.type);
    this.$emit("sortChanged", this.sortBy);
    this.$emit("monitoringStatusChanged", this.monitoringStatus);
    this.$emit("assetStateChanged", this.assetState);
    this.$emit("brandChanged", this.branding);
  },
};
</script>

<style scoped lang="scss">
.filter-bar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 50px;
  @media (min-width: 1024px) {
    margin-top: 20px;
  }

  @media (min-width: 1600px) {
    margin-top: 20px;
  }

  @media (min-width: 1920px) {
    margin-top: 30px;
  }
  ::v-deep .q-menu {
    left: auto !important;
    right: 0 !important;
    /* Other positioning adjustments */
  }
}
.bar {
  margin-left: 0px;
  min-width: 100px;

}

::v-deep .q-menu {
    left: auto !important;
    right: 0 !important;
    min-width: 200px;
  }

.filter-btn-text-top {
  font-family: "Open Sans";
  font-size: 0.8rem;
  font-weight: 300;
}
.filter-btn-text-bottom {
  font-family: "Open Sans";
  font-size: 0.6rem;
  font-weight: 500;
}
</style>
