<template>
    <div class="asset first-asset">
      <div class="asset_img">
        <img src="../../assets/plus-icon.png" alt="Add Concept" />
        <span class="add-text">Add Concept</span>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AssetCreator',
    props: {
      isModal: {
        type: String,
        default: false,
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .asset.first-asset {
    width: 125px;
    height: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background-color: #fff;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
  
    &:hover {
      transform: scale(1.05);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    }
  }
  
  .asset_img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px; /* Spacing between image and text */
    font-size: 0.9rem; /* Fixed font size */
    color: #333;
  }
  
  .asset_img img {
    width: 35px;
    height: 35px;
    display: block;
  }
  
  </style>