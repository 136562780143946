<template>
    <div class="wheel-container" style="width: 100%; height: 100%;">
        <div class="wheel-graph">
            <loading-app-wide v-if="loading" />
            <div v-if="!loading || afterloading" style="display: flex;justify-content: space-around;margin-bottom: 10px;">
                <q-btn round icon="eva-list-outline" @click="openAssociationList" style="margin-right: -100px;" />
                <a class="btn btn-outline btn3 marginTop" @click="openImageModal">
                    Reveal image
                </a>
            </div>
            <div :id="uniqueId" />
        </div>
        <q-dialog v-model="imageModalVisible" ref="myDialog" @show="onShow" @hide="onHide">
            <q-card>
                <q-bar class="bg-primary text-white" :class="draggable ? 'cursor-move' : ''">
                    <q-space />
                </q-bar>
                <q-card-section>
                    <p style="text-align: center; font-size: larger; font-weight: bolder;">Add This New Image To Your
                        Library? </p>
                    <p style="font-weight: lighter; font-size: small;">(This will add your concept to the upcoming mapping
                        survey, <br>
                        you will get notified when your concept is ready for testing)</p>
                </q-card-section>
                <!-- display the images below -->
                <!-- <div style="display: flex; justify-content: flex-end; margin-right: 1.3vw;margin-top: -1vw;">
                    <a class="download-link" @click="exportImage">Download</a>
                </div> -->
                <div style="display: flex; flex-direction: column;margin: 0 auto; margin-bottom: 2vw;">
                    <a class="download-link" @click="exportImage" style="text-align: end;margin-right: 65px;">Download</a>
                    <div style="text-align: center;">
                        <img :src="image" style="width:280px;" id="image">
                    </div>
                </div>

                <q-card-actions>
                    <div style="display: flex; justify-content: flex-end; margin-right: 1.3vw;">
                        <btn style="background-color:#92a0b3 !important;margin-right: 1vw;" class="button_pay"
                            @click="closeImageModal">Cancel​</btn>
                        <btn class="button_pay" @click="addToLibrary(imageURL)">Add Concept</btn>
                    </div>
                </q-card-actions>
            </q-card>
        </q-dialog>
        <color-picker ref="color_picker" @colorSelected="onColorSelected">
        </color-picker>
        <association-list ref="association-list" :data="d3_wheel.data">
        </association-list>
    </div>
</template>

<script>
import LoadingAppWide from '@/components/common/LoadingAppWide.vue';
import axios from 'axios';
import Wheel from './wheel.js';
import AssociationList from './AssociationList.vue';
import AwInformation from './AwInformation.vue';
import ColorPicker from './ColorPicker.vue';
import {
    alert_error,
    genericDialog,
    toast_success
} from '@/helpers/alert_helper.js';
import {
    v4 as uuidv4
} from 'uuid';
import { getTransitionRawChildren } from 'vue';

export default {
    components: {
        AssociationList,
        AwInformation,
        LoadingAppWide,
        ColorPicker
    },
    emits: ['wordColorPicked', 'chartError'],
    props: {
        asset_and_positioning: {
            required: true,
            type: Object
        },
        options: {
            required: true,
            type: Object
        },
        draggable: { type: Boolean, default: true },
        modelDialog: { type: Boolean, default: false },
    },
    data: function () {
        return {
            swDialog: false,
            target: null,
            nodeDragg: null,
            imageURL: '',
            image: '',
            afterloading: false,
            imageModalVisible: false,
            proportion: null,
            uniqueId: '',
            data: {},
            d3_wheel: {
                data: {}
            },
            loading: true,
            showInformation: true,
            brandExperiencePercentage: 0.0,
            descriptionTranslations: {
                stability: this.$t('message.stability'),
                responses_no: this.$t('message.number_of_responses'),
                date: this.$t('message.date'),
            },
        };
    },
    methods: {
        onShow() {
            let dialogElements = document.getElementsByClassName("q-dialog");
            this.target = dialogElements[0].querySelector(".q-card");
            this.nodeDragg = this.target.querySelector(".q-bar");
            if (this.draggable) {
                this.nodeDragg.addEventListener("mousedown", this.onGrab);
            }

            this.$emit("onShow");
        },
        onHide() {
            if (this.draggable) {
                document.removeEventListener("mousemove", this.onDrag);
                document.removeEventListener("mouseup", this.onLetGo);
                this.nodeDragg.removeEventListener("mousedown", this.onGrab);
            }
            this.$emit("onHide");
        },
        onDrag(e) {
            let originalStyles = window.getComputedStyle(this.target);
            this.target.style.left =
                parseInt(originalStyles.left) + e.movementX + "px";
            this.target.style.top = parseInt(originalStyles.top) + e.movementY + "px";
        },

        onLetGo() {
            document.removeEventListener("mousemove", this.onDrag);
            document.removeEventListener("mouseup", this.onLetGo);
        },

        onGrab() {
            document.addEventListener("mousemove", this.onDrag);
            document.addEventListener("mouseup", this.onLetGo);
        },
        async addToLibrary(imageURL) {
            const dialog_options = {
                title: "Upload this image to Your Library",
                text: this.$t('new_asset.this_will_cost', {
                    price: 200
                }),
                confirmButtonText: this.$t("message.asset_creator_add"),
                cancelButtonText: this.$t("message.cancel"),
            };

            const dialog_result = await genericDialog(dialog_options);
            if (!dialog_result.isConfirmed) {
                return;
            }
            if (dialog_result.isConfirmed) {
                // next, check if the user has enough credits
                await this.$store.dispatch('get_mindcoin_balance');
                if (this.$store.state.mindcoins < 200) {
                    alert_error(this.$t('new_asset.insufficient_credits'));
                    return;
                }
                try {
                    this.loading = true;
                    this.imageModalVisible = false;
                    const response = await axios.post('/api/cas/assets/story_creator', { "imageURL": imageURL });
                    this.loading = false;
                    if (response.status == 204) {
                        toast_success(`Concept ordered successfully, you will be notified when your concept(s) is analysed`);
                        this.$store.dispatch('get_mindcoin_balance');
                    }
                } catch (error) {
                    if (error.response.status === 500) {
                        alert_error("Our generative AI was unable to return an image due to one or more associations not passing our content moderation filters");
                        
                    } else {
                        alert_error(this.$t('message.general_server_error'));
                    }
                }
            }
        },

        async exportImage() {
            try {
                var a = document.createElement("a"); //Create <a>
                a.href = this.image //Image Base64 Goes here
                a.download = "Image.png"; //File name Here
                a.click(); //Downloaded file
            } catch (error) {
                console.error('Error downloading image:', error);
            }
        },

        async openImageModal() {
            const dialog_options = {
                title: 'Generate a new image',
                text: "Image costs 10 Mindcoins",

                confirmButtonText: "Yes",
                cancelButtonText: "Cancel",
            };
            const dialog_result = await genericDialog(dialog_options);
            if (!dialog_result.isConfirmed) {
                alert_error("Our generative AI was unable to return an image due to one or more associations not passing our content moderation filters");
                return;
            }
            if (dialog_result.isConfirmed) {
                // next, check if the user has enough credits
                await this.$store.dispatch('get_mindcoin_balance');
                if (this.$store.state.mindcoins < 10) {
                    alert_error(this.$t('new_asset.insufficient_credits'));
                    return;
                }
                try {
                    this.loading = true;
                    const words = this.data.children
                        .slice(0, 20)
                        .map((child) => `${child.word}: ${child.percentViz}`)
                        .join(', ');
                    const payload = { 'description': words };
                    const response = await axios.post('/api/cas/association_wheels/image_generator', payload);
                    this.image = response.data.image;
                    this.imageURL = response.data.imageURL;
                    this.loading = false;
                    await this.$store.dispatch('get_mindcoin_balance');
                    this.imageModalVisible = true;
                } catch (error) {
                    if (error.response.status === 500) {
                        alert_error("Our generative AI was unable to return an image due to one or more associations not passing our content moderation filters");
                    } else {
                        alert_error(this.$t('message.general_server_error'));
                    }
                }
                
                /*
                catch (error) {
                    console.log(error)
                }*/
            }
        },

        closeImageModal() {
            this.imageModalVisible = false;
        },

        openAssociationList: function () {
            this.$refs['association-list'].open();
        },

        wordClicked: async function (word, depth) {
            try {
                const color = await this.$refs.color_picker.pickColor();
                this.$emit('wordColorPicked', word, color, depth);
            } catch (e) { }
        },

        onColorSelected: function (color) { },
        setWordColor: function (word, color, depth) {
            this.d3_wheel.setWordColor(word, color, depth);
        },
        clearWordColor: function () {
            this.d3_wheel.clearAllColors();
        },
        setPercentage: function (value) {
            this.d3_wheel.setPercentage(value);
        },
        setWheelInformation: function (value) {
            this.showInformation = value;
        },
        /*
            Apply the provided color scheme to the chart. Returns a list of objects
            specifying which word received which color.
            */
        applyScheme: function (scheme) {
            const words = this.getFirstOrderWords();
            const colors = this.d3_wheel.getColorScheme(this.data, scheme);
            for (let i = 0; i < words.length; i++) {
                this.setWordColor(words[i], colors[i], 1);
            }
            return words.map(function (word, index) {
                return {
                    word: word,
                    color: colors[index]
                };
            });
        },
        getFirstOrderWords: function () {
            return this.data.children.map((child) => child.word);
        },
        exportChart: function () {
            const svg = document.querySelector(`#${this.uniqueId} svg`);
            this.$exportD3.saveSvgAsPng(svg, 'Association Wheel.png', {
                scale: 2
            });
        },
    },
    watch: {
        // we need a deep watch (since we're watching an object) of the options
        // prop so that any time options change we reinitialize the wheel
        options: {
            handler(val, oldVal) {
                this.d3_wheel.init(
                    this.data,
                    this.options,
                    this.descriptionTranslations
                );
            },
            deep: true,
        },
        showInformation: {
            handler(val, oldVal) {
                this.$props.options.display_info = val;
                this.d3_wheel.init(
                    this.data,
                    this.options,
                    this.descriptionTranslations
                );
            },
            deep: true,
        },
        modelDialog(val) {
            this.swDialog = val;
        }
    },
    beforeMount: async function () {
        this.uniqueId = `aw-graph-${uuidv4()}`;
        const asset = this.asset_and_positioning.asset;
        const positioning = this.asset_and_positioning.positioning;

        let payload = {
            proportion: 0.0,
            asset: asset,
            filters: [],
            from_date: '',
            to_date: ''
        };
        if (Object.keys(positioning).length > 0) {
            payload['from_date'] = positioning.from_;
            payload['to_date'] = positioning.to;
        }
        if ('mock' in this.asset_and_positioning) {
            payload['mock'] = true;
        }

        try {
            // we get the brand experience data first; this should be fast
            const brandExperienceUrl = `/api/cas/assets/brand_experience/total/${asset.id}`;
            const brandExperienceResponse = await axios.get(brandExperienceUrl);
            this.brandExperiencePercentage = brandExperienceResponse.data.percentage;

            // next we get the wheel data
            const url = '/api/cas/association_wheels/analyze';
            const response = await axios.post(url, payload);
            this.data = response.data[0];
            this.loading = false;
            this.afterloading = true;

            // last we get the no idea proportion and add to the data sent to wheel
            const no_idea_proportion = await axios.post('/api/cas/association_wheels/no_idea', asset);
            this.proportion = no_idea_proportion.data.toFixed(1);
            this.data.proportion = this.proportion

            this.d3_wheel = new Wheel(this.uniqueId, this);
            this.d3_wheel.init(this.data, this.options, this.descriptionTranslations);


        } catch (error) {
            this.$emit('chartError', this.asset_and_positioning);
            // TODO delete this AwChart if there's an error
            if (error.response.status === 403) {
                alert_error(this.$t('message.no_access_to_items'));
            } else {
                alert_error(this.$t('message.general_server_error'));
            }
        }
    },
};
</script>

<style scoped>
.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.aw-button {
    position: absolute;
    width: 50px;
    z-index: 100;
}

.wheel-graph {
    height: 100%;
    width: 100%;
    position: relative;
}

.wheel-graph svg {
    width: 100%;
    height: 100%;
    font: 18px sans-serif;
    position: absolute;
}

/* i.q-icon.eva.eva-list-outline {
  width: 40px;
} */

.wheel-container {
    width: 92%;
    height: 76%;
}

svg:not(:root) {
    overflow: hidden;
    width: 84%;
}

text {
    font-size: 1.4vw !important;
}

.download-link {
    text-decoration: none;
    color: grey;
    transition: color 0.3s ease;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
        color: grey;
    }
}

/* 
.q-btn .q-icon, .q-btn .q-spinner {
    font-size: 1.715em;
    margin-right: 0.5vw;
} */

.button_pay {
    padding: 0.5vw 1.0vw;
    background: #0A00FF;
    border-radius: 2vw;
    font-style: normal;
    font-weight: 1000;
    font-size: 0.8vw;
    color: #ffffff !important;
    cursor: pointer;
    text-transform: capitalize;
    transition: all 1s;
}

.button_pay a {
    color: #ffffff !important;
}

.button_pay:hover {
    background-color: #92a0b3;
}

.cursor-move {
    cursor: move;
}
</style>
