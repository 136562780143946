<template>

    <div class="popup-overlay">
      <loading-app-wide v-if="loading" />
      <div class="popup-container">
        <!-- Close Button -->
        <q-btn icon="close" flat round dense class="close-button" @click="$emit('exit')" />
  
        <!-- Header -->
        <div class="popup-header">
          <h2 class="popup-title">TRACKING BRAND ASSETS</h2>
        </div>
  
        <div class="content-container">
          <!-- Left Panel: Tracking Details -->
          <div class="left-panel">
            <div class="pricing-details">
              <p v-html="$t('new_asset.tracking_popup_1')">
              </p>

              <ul class="processing-info">
                <!-- Enabled: Auto-tagged and Eligible for Tracking -->
                <li class="status-item">
                  <div class="status-icons">
                    <span class="checkbox-label-active">
                      <font-awesome-icon :icon="currencyIcon" />
                    </span>
                    <font-awesome-icon :icon="['fas', 'right-left']" class="toggle-arrow"/>
                    <span class="checkbox-label" style="background-color: #7878e9;"></span>
                  </div>
                  <div class="status-text-container">
                    <span class="status-text" v-html="$t('new_asset.tracking_popup_2')">
                    </span>
                    <font-awesome-icon :icon="['fas', 'right-left']"  style="margin-left: 5px;" class="toggle-arrow"/>
                    <span class="status-text" v-html="$t('new_asset.tracking_popup_3')">
                    </span>
                  </div>
                </li>

                <!-- Empty: Not Eligible or Disabled -->
                <li class="status-item">
                  <div class="status-icons">
                    <span class="checkbox-label"></span>
                    <font-awesome-icon :icon="['fas', 'right-left']" class="toggle-arrow"/>
                    <span class="checkbox-label">
                      <font-awesome-icon :icon="currencyIcon" />
                    </span>
                  </div>
                  <div class="status-text-container">
                    <span class="status-text" v-html="$t('new_asset.tracking_popup_4')">
                    </span>
                    <font-awesome-icon :icon="['fas', 'right-left']" style="margin-left: 5px;" class="toggle-arrow"/>
                    <span class="status-text" v-html="$t('new_asset.tracking_popup_5')">
                    </span>
                  </div>
                </li>
              </ul>

              <p v-html="$t('new_asset.tracking_popup_6')"></p>
  
              <table class="pricing-table">
              <thead>
                <tr>
                  <th>Concept Type</th>
                  <th>{{$t('new_asset.number_concepts')}}</th>
                  <th>{{$t('new_asset.tracking_table_1')}}</th>
                  <th>Total Charge</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="imageTotalCharge > 0">
                  <td>Image</td>
                  <td>{{ imageCount }}</td>
                  <td>{{ imageConceptFee }} Mindcoins</td>
                  <td>{{ imageTotalCharge }} Mindcoins</td>
                </tr>
                <tr v-if="avTotalCharge > 0">
                  <td>Audio/Video</td>
                  <td>{{ avCount }}</td>
                  <td>{{ avConceptFee }} Mindcoins</td>
                  <td>{{ avTotalCharge }} Mindcoins</td>
                </tr>
                <tr v-if="wordTotalCharge > 0">
                  <td>Word</td>
                  <td>{{ wordCount }}</td>
                  <td>{{ wordConceptFee }} Mindcoins</td>
                  <td>{{ wordTotalCharge }} Mindcoins</td>
                </tr>
              </tbody>
            </table>
  
            <p>Note: <button style="padding: 0.2vw 1vw" class="yes-button" @click.prevent="redirectToLibrary" @click="$emit('exit')">
                Go to 'My Library'</button> {{$t('new_asset.tracking_popup_7')}}</p>

            </div>
          </div>
  
          <!-- Right Panel: Checkout Section -->
          <div class="right-panel">
            <div class="checkout-box">
              <h3>CHECKOUT</h3>
              <!-- Duration Selection -->
              <div v-if="assetCount != 0" class="line-item">
                <span>{{$t('new_asset.tracking_popup_9')}}</span>
              </div>
            <div v-if="assetCount != 0" class="duration-selection">

              <button 
                v-for="duration in durations" 
                :key="duration" 
                :class="{'selected': selectedDuration === duration}" 
                @click="selectDuration(duration)">
                {{ duration }} {{$t('new_asset.months')}}
              </button>
            </div>

              <div v-if="assetCount != 0" class="line-item">
                <span>Total Charge / {{$t('new_asset.month')}}</span>
                <span>+{{ totalCharge}}</span>
              </div>
              <div v-if="assetCount != 0" class="line-item">
                <span>{{$t('new_asset.tracking_popup_10')}}</span>
                <span>{{ this.selectedDuration}} {{$t('new_asset.months')}}</span>
              </div>
              <div v-if="assetCount != 0"class="line-item">
                <span>Total Charge ({{ this.selectedDuration }} {{$t('new_asset.months')}})</span>
                <span>+{{ totalMindcoins}}</span>
              </div>
              <div class="line-item total">
                <span>Total Mindcoins</span>
                <span>{{ totalMindcoins }}</span>
              </div>
              <button class="customize-button" disabled>ORDER</button>
              <p style="color: red; font-size: 10px; line-height: normal; margin-top: 5px;" v-if="assetCount == 0">{{ $t('new_asset.tracking_popup_8') }}</p>
            </div>
          </div>
        </div>
  
        <!-- Navigation Buttons -->
        <div class="popup-footer">
          <button class="back-button" @click="$emit('exit')">Close</button>
          <button class="next-button" @click="openSummaryPopup" :disabled="isAssetCountZero">Next</button>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import axios from 'axios';
  import LoadingAppWide from '@/components/common/LoadingAppWide.vue';

  export default {
    name: "TrackingPopup",
    props: {
      trackingStatus: Number, // 0 = Not Eligible, 1 = Eligible, 2 = Disabled
      tracking_auto_tag: Number, // Auto-tagging indicator
    },
    emits: ["exit", "back", "openSummaryPopup"],
    components: { LoadingAppWide },

    data() {
      return {
        assetCount: 0,
        currentLang: '',
        dollarStatus: 1,
        assetDisplayNames: [],
        imageCount: 0,
        avCount: 0,
        wordCount: 0,
        imageConceptFee: 0,
        avConceptFee: 0,
        wordConceptFee: 0,
        durations: [3, 6, 12],
        selectedDuration: 3,
        loading: false,
        
      };
    },
    created() {
    this.currentlang = this.$store.getters.getEnvVar('VUE_APP_DEFAULT_LANGUAGE') || 'nl';
  },
    computed: {
      currencyIcon() {
        return this.currentlang === 'nl' ? ['fas', 'euro-sign'] : ['fas', 'dollar-sign'];
      },
      imageTotalCharge() {
      return this.imageCount * this.imageConceptFee;
      },
      avTotalCharge() {
        return this.avCount * this.avConceptFee;
      },
      wordTotalCharge() {
        return this.wordCount * this.wordConceptFee;
      },
      totalCharge() {
        return this.imageTotalCharge + this.avTotalCharge + this.wordTotalCharge;
      },
      totalMindcoins() {
        return this.totalCharge * this.selectedDuration;
      },
      isAssetCountZero() {
        return this.assetCount === 0;
      },
      isOverridden() {
        return this.tracking_auto_tag !== this.trackingStatus;
      },
    },
    methods: {
      selectDuration(duration) {
        this.selectedDuration = duration;
      },
      _determinePrice(type) {
        let priceType = '';
        if (type === 2) {
          priceType = 'private_image_asset';
        } else if (type === 5 || type === 7) {
          priceType = 'private_av_asset';
        } else if (type === 1) {
          priceType = 'private_word_asset';
        }
        const basePrice = this.$store.getters.get_price(priceType);
        return Math.round(basePrice * 1.10);
      },
      async fetchAssetCount() {
        try {
          this.loading = true;
          const response = await axios.get("/api/cas/assets/mylibrary_tracking_count");
          this.assetCount = response.data.asset_count || 0;
          console.log(response.data);
          this.assetDisplayNames = response.data.assets_display_names || [];
          this.imageCount = this.assetDisplayNames.filter(asset => asset.type === 2).length;
          this.avCount = this.assetDisplayNames.filter(asset => asset.type === 5 || asset.type === 7).length;
          this.wordCount = this.assetDisplayNames.filter(asset => asset.type === 1).length;
          this.imageConceptFee = this._determinePrice(2);
          this.avConceptFee = this._determinePrice(5); // Assuming same price for both 5 and 7
          this.wordConceptFee = this._determinePrice(1);
        } catch (error) {
          console.error("Error fetching asset count:", error);
        } finally {
          this.loading = false;
        }
      },
      openSummaryPopup() {
        if (!this.isAssetCountZero) {
          this.$emit("openSummaryPopup", {
            assetCount: this.assetCount,
            totalMindcoins: this.totalMindcoins,
            totalCharge: this.totalCharge,
            assetDisplayNames: this.assetDisplayNames,
            selectedDuration: this.selectedDuration
          });
        }
      },
      redirectToLibrary(){
        this.$router.push({name:"Library"});
      }
    },
    mounted() {
      this.fetchAssetCount();
    }
  };
  </script>
  
  <style scoped>
  /* Background Overlay */
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  /* Popup Container */
  .popup-container {
    background: white;
    width: 80%;
    max-width: 1000px;
    padding: 20px;
    border-radius: 14px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  /* Header */
  .popup-header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid #ccc;
    padding-bottom: 10px;
    position: relative;
  }
  
  .popup-title {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
  }
  
  /* Close Button */
  .close-button {
    position: absolute;
    right: 15px;
    top: 15px;
  }
  
  /* Content Layout */
  .content-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-size: 14px;
  }
  
  /* Left Panel */
  .left-panel {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .pricing-details {
    background: #f9f9f9;
    padding: 15px;
    border-radius: 14px;
    font-size: 12px;
    color: #333;
  }
  
  .pricing-details p {
    font-size: 14px;
  }
  
  .pricing-table {
    width: 100%;
    border-collapse: separate; /* Change from collapse to separate */
    border-spacing: 0;
    margin-top: 10px;
    border-radius: 8px;
    margin-bottom: 20px;
    color: #000;
    overflow: hidden; /* Ensures rounded corners */
  }
  
  /* Target the first and last rows to round the top and bottom */
  .pricing-table thead tr:first-child th:first-child {
    border-top-left-radius: 3px;
  }
  .pricing-table thead tr:first-child th:last-child {
    border-top-right-radius: 3px;
  }
  .pricing-table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 3px;
  }
  .pricing-table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 3px;
  }
  
  /* Ensure each cell has a border */
  .pricing-table th,
  .pricing-table td {
    border: 1px solid #ccc;
    padding: 5px;
    text-align: center;
  }
  
  /* Optional: Style the header */
  .pricing-table th {
    background: #7878e9;
    color: white;
    font-weight: bold;
  }
  
  .processing-info {
    list-style: none;
    padding: 0;
    font-size: 10px;
  }
  
  .processing-info li {
    margin-bottom: 8px;
  }
  
  /* Right Panel */
  .right-panel {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  
  .checkout-box {
    background: #ffffff;
    border: 1px solid #ccc;
    border-radius: 14px;
    padding: 20px;
    width: 300px;
    text-align: left;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  .checkout-box h3 {
    margin: 0;
    margin-bottom: 15px;
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
  }
  
  .line-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 14px;
    color: #555;
  }
  
  .line-item.total {
    font-weight: bold;
    font-size: 16px;
    color: #000;
    border-top: 1px solid #ccc;
    padding-top: 5px;
    margin-top: 5px;
  }
  
  /* Buttons */
  .customize-button {
    background: #7878e9;
    color: white;
    border: none;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    margin-top: 15px;
    width: 100%;
    cursor: not-allowed;
    opacity: 0.6;
  }
  
  .customize-button:disabled {
    background: #cccccc; /* Lighter grey background */
    color: #666666; /* Darker grey text */
    cursor: not-allowed; /* Change cursor to indicate disabled state */
    opacity: 0.6; /* Make the button more transparent */
  }
  
  /* Tooltip */
  .tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .tooltip {
    position: absolute;
    bottom: 130%;
    left: 40%;
    width: 200px;
    transform: translateX(-50%);
    background-color: #555;
    color: #fff;
    font-size: 12px;
    padding: 8px;
    border-radius: 6px;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s ease-in-out;
    z-index: 10;
  }
  
  .info-icon {
    margin-left: 5px;
    cursor: pointer;
    color: #7878e9;
  }
  
  /* Navigation Buttons */
  .popup-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .next-button {
    background: #5a5dbb;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 8px;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .back-button {
    background: #000;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .back-button:hover, .next-button:hover {
    background: #444477;
  }
  
  .footnote {
    font-size: 12px;
    color: #666;
    margin-top: 10px;
  }
  
  .footnote {
    margin-top: 10px;
    font-size: 0.9em;
    color: red;
  }

/* Dollar Icon */
.checkbox-container {
  position: absolute;
  bottom: 6px;
  right: 10px;
  cursor: pointer;
}

/* Separate container for icons */
.status-icons {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 2%;
  min-width: 80px; /* Ensures a fixed width for consistent alignment */
}

/* Icon Boxes */
.checkbox-label-active, .checkbox-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px; /* Fixed width */
  height: 28px; /* Fixed height */
  border-radius: 4px;
  border: 1px solid #7878e9;
  font-size: 16px; /* Keep icon size consistent */
}

/* Active State */
.checkbox-label-active {
  background-color: #7878e9;
  color: white;
}

/* Empty State */
.checkbox-label {
  background-color: white;
  color: transparent;
}

/* Toggle Arrow */
.toggle-arrow {
  font-size: 16px;
  color: #7878e9;
}

/* Separate container for text */
.status-item {
  display: flex;
  align-items: flex-start; /* Aligns items at the top */
  gap: 12px;
  margin-bottom: 12px;
}

.status-text-container {
  flex: 1;
  font-size: 14px;
  color: #333;
  line-height: 1.5; /* Ensures proper line height */
  display: flex;
  flex-direction: row;
  align-items: center; /* Ensures alignment with icons */
}


/* Icons for different tracking states */
.status-icon {
  font-size: 18px;
}

/* Eligible for Tracking */
.status-active {
  color: green;
}

/* Not Eligible */
.status-not-eligible {
  color: grey;
  opacity: 0.5;
}

/* Disabled by User */
.status-disabled {
  color: red;
}

/* Auto-Tagging Override */
.status-warning {
  color: orange;
}

/* Ensure the text aligns with the icon */
.status-item span {
  font-size: 12px;
  color: #333;
}

.status-text{
  font-size: 10px;
  color: #333;
  margin-left: 10px;
  padding: 2%;
  /* border: solid 1px #ccc; */
}

.yes-button {
  background: #0a00ff;
  color: #fff;
  border: none;
  border-radius: 2vw;
  padding: 0.5vw 2vw;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.yes-button:hover{
  background: #0800ff8f;
}

/* Duration Selection */
.duration-selection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.duration-selection button {
  background: #f0f0f0;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 10px;
  border-radius: 8px;
  cursor: pointer;
  flex: 1;
  margin: 0 5px;
}

.duration-selection button.selected {
  background: #5a5dbb;
  color: white;
  border: none;
}
  </style>