<template>
  <div class="library-container">
    <!-- Asset Grid with Independent Scroll -->
    <div v-if="assets.length > 0 && libraryKey === 'my_library'" class="divider-tile-assets">
        <span class="divider-text">{{ $t('new_asset.asset_grid_concept_title') }}</span>
      </div>
    <div class="library scroll asset-container" @scroll="handleScroll" ref="assetGrid">
      <div class="asset-workspace mdspscrollbar">
        <div class="text-center" v-if="assets.length === 0 && !loading">
          {{ $t('message.library_no_match') }}
        </div>
        <div class="asset-grid">
          <asset-creator
            v-if="libraryKey === 'my_library'"
            @click="onAddNewAsset"
            :isModal="isModal"
          />
          <asset-tile
            v-for="a in assets"
            :key="a.id"
            :asset="a"
            :ref="tilesRef"
            @assetSelected="onAssetSelected"
            :isModal="isModal"
            :isMyLibrary="libraryKey === 'my_library'"
          />
          <div class="loaders" v-if="loading && assets.length">
            <loading-spinner />
          </div>
          <span v-if="loading && !assets.length">
            <loading-spinner class="centered" />
          </span>
        </div>
      </div>
    </div>

    <!-- Words Grid with Independent Scroll -->
    <div v-if="words.length > 0" class="divider-tile">
        <span class="divider-text">{{ $t('new_asset.asset_grid_title') }}</span>
      </div>
    <div v-if="libraryKey === 'my_library' && words.length > 0" class="library scroll words-container">

      <div class="asset-grid">
        <asset-tile
          v-for="w in words"
          :key="`word-${w.word_id}`"
          :asset="w"
          :ref="tilesRef"
          @assetSelected="onAssetSelected"
          :isModal="isModal"
          :isMyLibrary="libraryKey === 'my_library'"
        />
        <div class="loaders" v-if="loading && words.length">
            <loading-spinner />
          </div>
          <span v-if="loading && !words.length">
            <loading-spinner class="centered" />
          </span>
    </div>
      </div>

  </div>
</template>

<script>
import axios from 'axios';
import AssetTile from './AssetTile.vue';
import AssetCreator from './AssetCreator';
import LoadingSpinner from '../common/LoadingSpinner.vue';

export default {
  components: {
    AssetTile,
    AssetCreator,
    LoadingSpinner,
  },
  props: {
    filter: {
      required: true,
      type: Object,
    },
    showAddAssetButton: {
      default: true,
      type: Boolean,
    },
    value: {
      type: String,
      default: false,
    },
    libraryKey: {
      type: String,
      default: false,
    },
    onCountTotalAssets: Function,
    onAddNewAsset: Function,
    isModal: String,
  },
  emits: ['assetClick', 'addNewAsset', 'wordSelected'],

  data: function () {
    return {
      assets: [],
      words: [],
      page: 0,
      loading: true,
      tile_elements: [],
      requests_sent: 0,
      asset_num: null,
      word_num: null,
      total_assets: null,
    };
  },

  methods: {
    tilesRef: function (element) {
      this.tile_elements.push(element);
    },
    resetSelection: function () {
      for (const tile of this.tile_elements) {
        tile.unselect();
      }
    },
    onAssetSelected: function (asset) {
      this.resetSelection();
      if (asset.tag !== "dictionary_word") {
        this.$emit('assetClick', asset);
      }
    },
    handleScroll: function (el) {
      const assetGrid = this.$refs.assetGrid;
      if (!assetGrid) return;

      const targetPosition = assetGrid.offsetHeight + assetGrid.scrollTop;
      const scrollHeight = assetGrid.scrollHeight;

      if (scrollHeight - targetPosition < 20 && !this.loading) {
        this.loadNextPage();
      }
    },
    loadNextPage: function () {
      this.page++;
      if (this.libraryKey == 'mindspeller'){
        this._fetchMindspellerAssets();
      }else{
        this._fetchAssets();
      }
    },
    _fetchAssets: async function () {
      if (Object.keys(this.filter).length === 0) {
        return;
      }
      this.requests_sent++;
      this.loading = true;
      const ping = this.requests_sent;
      const payload = {
        page_size: 54,
        page: this.page,
        filter: this.filter,
        ping: ping,
        include_positionings: true,
      };

      // Perform both API calls concurrently
      const [responseAssets, responseWords] = await Promise.all([
        axios.post(
          `${this.libraryKey === 'my_library'
            ? '/api/cas/assets/search'
            : this.libraryKey === 'mindspeller'
              ? '/api/cas/assets/store/search_new'
              : ''}`,
          payload
        ),
        axios.post(
          `${this.libraryKey === 'my_library'
            ? '/api/cas/words/owned_search'
            : ''}`,
          payload
        )
      ]);

      const responseMylibraryCount = await axios.get(
          `${this.libraryKey === 'my_library'
            ? '/api/cas/assets/mylibrary_count'
            :''}`
        );
      // Process assets response
      if (responseAssets.data.ping === this.requests_sent) {
        const concatenate_assets = responseAssets.data.assets.filter(asset => asset.state !== 5);
        this.assets = [...this.assets, ...concatenate_assets];

        // Update total asset count
        if (responseAssets.data.assets.length > 0) {
          this.asset_num = this.assets.length;
          this.total_assets = responseMylibraryCount.data.asset_count;
        } else if (this.assets.length === 0) {
          this.asset_num = 0;
        }
      };

      // Process words response
      if (responseWords.data && responseWords.data.words && responseWords.data.words.length > 0) {
        this.words = responseWords.data.words.map((word) => {
          return {
            ...word,
            display_name: word.word,
            id: word.word_id,
            tag: word.type_ ? word.type_.tag : null,
          };
        });

        // Update total word count
        this.word_num = this.words.length;
      } else {
        console.warn("No words found in the response.");
        this.words = [];
        if (this.words.length === 0) {
          this.word_num = 0;
        }
      }

      this.loading = false;
    },
    
    _fetchMindspellerAssets: async function () {
      // if the filter is not yet set (i.e. is empty), we skip fetching
      if (Object.keys(this.filter).length === 0) {
        return;
      }
      // otherwise, we proceed with fetching the assets
      this.requests_sent++;
      // we will send this ping along with the request and compare it
      // to this.requests_sent after we receive a response
      this.loading = true;
      const ping = this.requests_sent;
      const payload = {
        page_size: 50,
        page: this.page,
        filter: this.filter,
        ping: ping,
        include_positionings: true,
      };

      const response = await axios.post(
        `${this.libraryKey === 'my_library'
          ? '/api/cas/assets/search'
          : this.libraryKey === 'mindspeller'
            ? '/api/cas/assets/store/search_new'
            : ''
        }`,
        payload
      );
      // we only want to process the latest query so we disregard all
      // responses that are not a result of the latest request (i.e. the
      // request whose ping is this.requests_sent)
      if (response.data.ping === this.requests_sent) {
        // get the total number of the assets of A user or mindspeller library
        if (response.data.assets.length > 0 && this.assets.length==0) {
          if (this.libraryKey === 'my_library'){
            this.asset_num = response.data.assets.length;
          }
          if (this.libraryKey === 'mindspeller'){
            this.asset_num = response.data.assets[0].total_count;
          }
        } 
        if (response.data.assets.length == 0 && this.assets.length==0) {
          this.asset_num = 0;
        } 

        // here we filter out the assets that state = 5
        let concatenate_assets = response.data.assets.filter(asset => asset.state !== 5);
        this.assets = [...this.assets, ...concatenate_assets];

        this.loading = false;
      }



    },
    addAsset: function (asset) {
      this.assets.unshift(asset);
    },
    resetSearch: function () {
      this.page = 0;
      this.requests_sent = 0;
      this.assets = [];
      this.words = [];
      if (this.libraryKey == 'mindspeller'){
        this._fetchMindspellerAssets();
      }else{
        this._fetchAssets();
      }
    },
  },

  watch: {
    filter: {
      deep: true,
      immediate: true,
      handler() {
        this.resetSearch();
      },
    },
    assets: {
      deep: true,
      immediate: true,
      handler() {
        this.onCountTotalAssets(this.asset_num, this.word_num, this.total_assets);
      },
    },
  },

  beforeUpdate() {
    this.tile_elements = [];
  },
};
</script>

<style lang="scss" scoped>
.library-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  height: 50vh; /* Viewport height */
  overflow-y: auto; /* Allow scrolling for the parent container */

  /* Custom scrollbar */
  &::-webkit-scrollbar {
    width: 10px; /* Thin scrollbar */
    
  }
  &::-webkit-scrollbar-thumb {
    background: #7878e9; /* Dark gray thumb */
    border-radius: 3px; /* Blunt ends */
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #3b3b72; /* Slightly darker on hover */
  }
  &::-webkit-scrollbar-track {
    background: #f0f0f0; /* Light gray track */
  }
}

.asset-container,
.words-container {
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow-y: auto; /* Independent scrolling for child containers */
  padding: 10px;
  flex-shrink: 0;
  width: 120vh;

  /* Custom scrollbar */
  &::-webkit-scrollbar {
    width: 8px; /* Thin scrollbar */
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  &::-webkit-scrollbar-track {
    background: #f0f0f0;
  }
}

.asset-container {
  min-height: 20vh;
  max-height: 40vh; /* Adjust based on preference */
  background-color: #fff; /* Light gray for debugging */
}

.words-container {
  min-height: 20vh;
  max-height: 40vh; /* Adjust based on preference */
  background-color: #f9f9f9; /* White for debugging */
}

.asset-workspace {
  width: 100%;
  min-height: 100%;
  overflow-y: visible;
}

.divider-tile-assets {
  width: 100%;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  color: #444;
}

.divider-tile {
  width: 100%;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  color: #444;
  border-top: 2px solid #ddd;
}

.divider-text {
  font-size: 0.8rem;
  margin-top: 5px;
  display: block;
}

.divider-note {
  font-size: 0.9rem;
  font-weight: normal;
  text-align: center;
  color: #777;
  margin: 0;
  line-height: 1.2;
}

.asset-grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
  justify-content: center;
  position: relative;
  min-height: 150px;
  padding: 1.5%;
}

.loaders {
  width: 121px;
  height: 121px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
</style>
