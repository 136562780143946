<template>
  <loading-app-wide v-if="loading" />
    <div class="popup-overlay" v-if="show" @click.self="$emit('exit')">
      
      <div class="popup-container">
        <q-btn icon="close" flat round dense @click="$emit('exit')" />
        <q-card-section>
          <div class="top-text">{{ $t('new_asset.add_new_item') }}</div>
        </q-card-section>
        <div class="content-container">
          <!-- Left Side: Dynamic steps -->
          <div class="left-panel">
            <!-- Step 0: Ask about public concept -->
            <div v-if="currentStep === 0" class="step-content">
              <p>{{ $t('new_asset.public_concept') }}</p>
              <div class="button-row">
                <button class="no-button" @click="applyPublicDiscount(true)">{{ $t('new_asset.no') }}</button>
                <button class="yes-button" @click="applyPublicDiscount(false)">{{ $t('new_asset.yes') }}</button>
              </div>
              <div class="info-box">{{ $t('new_asset.public_concept_info') }}</div>
            </div>
  
            <!-- Step 1: Ask about expedite -->
            <div v-if="currentStep === 1" class="step-content">
              <p>{{ $t('new_asset.results_asap') }}</p>
              <div class="button-row">
                <button class="no-button" @click="applyExpedite(false)">{{ $t('new_asset.no') }}</button>
                <button class="yes-button" @click="applyExpedite(true)">{{ $t('new_asset.yes') }}</button>
              </div>
              <div class="info-box">{{ $t('new_asset.results_asap_info') }}</div>
            </div>
  
            <!-- Step 2: If expedite chosen, select ETA -->
            <div v-if="currentStep === 2" class="step-content">
              <p>{{ $t('new_asset.results_asap_eta') }}</p>
              <table class="eta-table">
                <tr>
                  <th>PLAN</th>
                  <th>SERVICE</th>
                  <th>{{ $t('new_asset.surcharge') }} (Mindcoins)</th>
                  <th></th>
                </tr>
                <tr v-for="option in etaOptions" :key="option.eta">
                  <td>{{ option.plan }}</td>
                  <td>{{ option.eta }}</td>
                  <td>{{ option.surcharge }}</td>
                  <td><button class="select-button" @click="selectETA(option)">{{ $t('new_asset.select') }}</button></td>
                </tr>
              </table>
              <div class="info-box">{{ $t('new_asset.eta_info') }}</div>
            </div>
  
            <!-- Step 3: Final summary -->
            <div v-if="currentStep === 3" class="step-content">
              <p>{{ $t('new_asset.final_checkout_1') }}</p>
              <p v-if="!notEnoughFunds">{{ $t('new_asset.final_checkout_2') }}</p>
              <p v-if="notEnoughFunds">
                <strong>{{ $t('new_asset.final_checkout_no_mindcoins') }}</strong>
              </p>
              <p v-if="notEnoughFunds">
                <strong>
                  Click <button style="padding: 0.2vw 1vw" class="yes-button" @click.prevent="redirectToSubscription">HERE</button> 
                  to add more mindcoins.
                </strong>
              </p>

              <!-- Asset Summary Section -->
              <div class="asset-summary">
                <h4>SUMMARY</h4>
                <div class="scrollable-container">
                <table class="asset-summary-table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>CONCEPT</th>
                      <th>TYPE</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(asset, index) in acceptableAssets" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>{{ asset.info.displayName}}</td>
                      <td>{{ asset.info.type}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              </div>
            </div>
  
            <div class="popup-footer">
                <button class="back-button" v-if="currentStep == 0" @click="$emit('goBack')">{{ $t('new_asset.back') }}</button>

              <!-- BACK button always visible except maybe on first step -->
              <button class="back-button" v-if="currentStep > 0" @click="goBack">{{ $t('new_asset.back') }}</button>
            </div>
          </div>
  
          <!-- Right Side: Checkout -->
          <div class="right-panel">
            <div class="checkout-box">
              <h3>{{ $t('new_asset.checkout') }} - {{ assetSummary }} concept(s)</h3>
              <div class="line-item">
                <span>{{ $t('new_asset.concept_upload') }}</span>
                <span>{{basePrice}}</span>
              </div>
              <div v-if="!publicConceptDiscountApplied" class="line-item">
                <span>{{ $t('new_asset.table_public_concept_title') }}</span>
                <span>-{{ (basePrice * 10) /100 }}</span>
              </div>
              <div v-if="expediteApplied && chosenETA" class="line-item">
                <span>{{ chosenETA.plan }}</span>
                <span>+{{ chosenETA.surcharge  * acceptableAssets.length }}</span>
              </div>
              <hr />
              <div class="line-item total">
                <span>Total Mindcoins</span>
                <span>{{ totalCost }}</span>
              </div>
              <!-- On final step, show ADD button, else show disabled or hidden -->
              <button v-if="currentStep === 3" 
                      :disabled="notEnoughFunds"
                      class="add-button"
                      @click="addToSurvey">
                      {{ $t('new_asset.add') }}
              </button>
              <button v-else class="add-button" disabled>{{ $t('new_asset.add') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { alert_error, genericDialog, toast_success } from '@/helpers/alert_helper.js';
  import LoadingAppWide from '@/components/common/LoadingAppWide.vue';
  import axios from "axios";
  export default {
    name: "CheckoutBox",
    components: {LoadingAppWide },
    emits: ['goBack', 'resetSearch', 'exit', 'resetSearch', 'upload'],
    data() {
      return {
        show: false,
        loading: false,
        currentStep: 0,
        publicConceptDiscountApplied: true,
        expediteApplied: false,
        chosenETA: null,
        basePrice: null,
        mindcoinsTotal: null,
        acceptableAssets: [],
        files: [],
        userMindcoins: null, // Suppose user has 500 mindcoins. Adjust as needed.
        etaOptions: [],
      };
    },
    created() {
      this.etaOptions = [
        { plan: this.$t('new_asset.eta_plan_1'), eta: this.$t('new_asset.eta_description_1'), surcharge: 0 },
        { plan: this.$t('new_asset.eta_plan_2'), eta: this.$t('new_asset.eta_description_2'), surcharge: 200 },
        { plan: this.$t('new_asset.eta_plan_3'), eta: this.$t('new_asset.eta_description_3'), surcharge: 500 },
        { plan: this.$t('new_asset.eta_plan_4'), eta: this.$t('new_asset.eta_description_4'), surcharge: 1000 },
      ];
    },
    computed: {
      publicDiscount() {
        const PUBLIC_DISCOUNT_RATE = 10; // Define as a constant for clarity
        return !this.publicConceptDiscountApplied
          ? -(this.basePrice * PUBLIC_DISCOUNT_RATE) / 100
          : 0;
      },   
      etaSurcharge() {
        if (this.expediteApplied && this.chosenETA) {
          return this.chosenETA.surcharge * this.acceptableAssets.length;
        }
        return 0;
      },
      totalCost() {
        return this.basePrice + this.publicDiscount + this.etaSurcharge;
      },
      notEnoughFunds() {
        return this.totalCost > this.$store.state.mindcoins;
      },
      assetSummary() {
        return this.acceptableAssets.length || 1;
      }
    },
    methods: {
      close: function() {
        this.show = false;
        this.currentStep = 0;
        this.publicConceptDiscountApplied = true;
        this.expediteApplied = false;
        this.chosenETA = null;
        this.basePrice = null;
      },
      open: function({ files, mindcoinsTotal, acceptableAssets }) {
        this.show = true;
        this.files = files;
        this.acceptableAssets = Array.isArray(acceptableAssets) ? acceptableAssets : [];
        this.mindcoinsTotal = mindcoinsTotal/(this.acceptableAssets.length || 1);
        this.basePrice = this.mindcoinsTotal * (this.acceptableAssets.length || 1);;
        this.files = Array.isArray(files) ? files : [];
      },  
      redirectToSubscription() {
            window.open(this.$router.resolve('/subscription_plans').href, '_blank');
        },
      goBack() {
        // Simple back logic: if on final step, go to previous steps
        // If we are at step 3 and had chosen an ETA, maybe go back to step 2, etc.
        if (this.currentStep > 0) {
          // If we go back from step 3 and had expedite chosen, go to step 2 if an ETA was chosen
          // else if expedite chosen but no ETA chosen, also step 2
          // else step 1 if expedite not chosen
          if (this.currentStep === 1) {
            // Clear chosenETA if going back from final
            this.applyPublicDiscount(true);
          }
          if (this.currentStep === 2) {
            // Clear chosenETA if going back from final
            this.applyExpedite(false);
          }
          // For simplicity, just decrement:
          if (this.currentStep === 3) {
            // Clear chosenETA if going back from final
            this.chosenETA = null;
            this.currentStep--;
          }
          this.currentStep--;
        }
      },
      applyPublicDiscount(choice) {
        this.publicConceptDiscountApplied = choice;
        // Move to next step
        this.currentStep = 1;
      },
      applyExpedite(choice) {
        this.expediteApplied = choice;
        if (!this.expediteApplied){
          this.selectETA(this.etaOptions[0]);
        }
        // If expedite chosen, go to step 2, else skip to final step (3)
        this.currentStep = this.expediteApplied ? 2 : 3;
      },
      selectETA(option) {
        this.chosenETA = option;
        this.currentStep = 3;
      },
      addToSurvey() {
        // this.close();
        if (this.chosenETA.plan == 'Standaard levering') {
          this.chosenETA.plan = 'Standard delivery';
        }
        else if (this.chosenETA.plan == 'Weekend spoedlevering') {
          this.chosenETA.plan = 'Weekend Rush';
        }
        else if (this.chosenETA.plan == 'Midweekversnelling') {
          this.chosenETA.plan = 'Midweek Boost';
        }
        else if (this.chosenETA.plan == 'Bliksemsnel') {
          this.chosenETA.plan = 'Lightning Fast';
        }
        console.log("chosenETA", this.chosenETA.plan);
        // this.loading = true;
        this.$emit('upload', {totalMindcoins : this.totalCost, isConfidential: this.publicConceptDiscountApplied, chosenETA: this.chosenETA.plan})
        // this.loading = false;
      },
    }
  };
  </script>
  
  <style scoped>
  .popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6); /* Darker overlay for better focus */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  padding: 20px;
  box-sizing: border-box;
}

.popup-container {
  position: relative;
  background: #ffffff;
  border-radius: 12px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  max-width: 1000px;
  width: 100%;
  max-height: fit-content;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  text-align: center;
}

.q-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #333;
  font-size: 20px;
  cursor: pointer;
}

.top-text {
  font-size: 1.8rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  margin-bottom: 10px;
  color: #333;
}

.content-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 15px;
}

.left-panel {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.right-panel {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.step-content {
  background: #f9f9f9;
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 0px;
  border: 1px solid #ddd;
  text-align: center;
}

.step-content p{
    text-align: center;
}

.button-row {
  display: flex;
  gap: 15px;
  justify-content: center;
  margin: 20px 0;
}

.yes-button,
.no-button {
  background: #0a00ff;
  color: #fff;
  border: none;
  border-radius: 2vw;
  padding: 0.5vw 2vw;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.select-button {
background: #0a00ff;
  color: #fff;
  border: none;
  border-radius: 2vw;
  padding: 0.5vw 0.5vw;
  font-size: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.yes-button:hover,
.no-button:hover,
.select-button:hover {
  background: #0800ff8f;
}

.no-button {
  background: #92a0b3;
}

.no-button:hover {
  background: #d32f2f;
}

.info-box {
  background: #eef4ff;
  border: 1px solid #c5d6f2;
  border-radius: 8px;
  padding: 15px 20px;
  color: #4a76c3;
  font-size: 14px;
  line-height: 1.6;
  margin-top: 15px;
}

.popup-footer {
  text-align: left;
  margin-top: auto;
}

.back-button {
  background: #000000;
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background: #3d3d3d; /* Darker blue for hover */
}

.checkout-box {
  background: #ffffff;
  border: 1px solid #ccc;
  border-radius: 12px;
  padding: 20px;
  width: 300px;
  text-align: left;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.checkout-box h3 {
  margin: 0;
  margin-bottom: 15px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
}

.line-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px;
  color: #555;
}

.line-item.total {
  font-weight: bold;
  font-size: 16px;
  color: #000;
  border-top: 1px solid #ccc;
  padding-top: 10px;
}

.add-button {
  background: #0a00ff;
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  margin-top: 15px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.add-button:hover {
  background: #0800ff8f;
  transform: scale(1.02);
}

.add-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.eta-table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  margin-top: 5px;
}

.eta-table th {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  font-size: 14px;
}

.eta-table td {
  border: 1px solid #ddd;
  padding: 5px;
  text-align: center;
  font-size: 12px;
}
.eta-table th {
  background: #7878e9;
  color: #fff;
  text-align: center;
  font-weight: bold;
}

.asset-summary {
  margin-top: 20px;
  text-align: left;
}

.asset-summary h4 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.scrollable-container {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 3px solid #ddd;
  border-radius: 6px;
}

/* Custom Scrollbar for WebKit Browsers */
.scrollable-container::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

.scrollable-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
  border-radius: 10px; /* Rounded track */
}

.scrollable-container::-webkit-scrollbar-thumb {
  background: #d9e1ec; /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded scrollbar thumb */
  border: 2px solid transparent; /* Optional: Space around the thumb */
  background-clip: padding-box; /* Ensures thumb respects border */
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background: #b8c7d9; /* Thumb color when hovered */
}

.asset-summary-table {
  width: 100%;
  border-collapse: collapse;
}

.asset-summary-table th,
.asset-summary-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.asset-summary-table th {
  background-color: #f0f0f0;
  position: sticky;
  font-weight: bold;
}

.asset-summary-table td {
  font-size: 14px;
}

.asset-summary-table thead th {
  position: sticky; /* Make the table headers stick */
  top: 0; /* Stick to the top */
  background-color: #f9f9f9; /* Header background color */
  z-index: 2; /* Ensure it stays above the scrolling body */
  border-bottom: 2px solid #ccc;
  text-align: center;
  padding: 10px;
}

.asset-summary-table tbody td {
  text-align: center;
  border: 1px solid #ddd;
  padding: 8px;
}
  </style>
  