<template>
    <main>
        <landing-page></landing-page>
        <div style="font-weight: bold !important; font-size: 1.5vw; text-align: center; margin-top: 1.5vw;">
            Almost done! Share the scoop on your brand's mission
            <span class="info-tooltip" @click="showTip">
                <font-awesome-icon :icon="['fas', 'info-circle']" style="color: #7878E9; margin-left: 5px; cursor: pointer;" @click="showTip"/>
                <div class="tooptip-text">
                    {{ this.$t('message.brand_info_tip_tooltip_content') }}
                </div>
            </span>
        </div>

        <section class="container middle-box" v-if="showPage" style="display: flex; justify-content: center;">
            <div class="brand-img-sec">
                <div class="col-box-div">
                    <div class="brand-value-sec">
                        <div class="mb-3 desc">
                            <label>Enter your brand vision or corporate mission statement (25-100 characters)</label>
                            <textarea v-model="brand_desc" class="form-control textareafrm" id="exampleFormControlTextarea1"
                                rows="3" maxlength="100" placeholder="Enter your description here"></textarea>
                            <div class="bottom-no-col">
                                <span>{{ brand_desc ? brand_desc.length : 0 }}/100</span>{{ $nbsp }}
                                <span>{{ $t('message.characters').toLowerCase() }}</span>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label>Any big-shot investor with over 30K followers backing your brand?</label>
                            <div class="check-boxdiv">
                                <div class="form-check form-check-inline">
                                    <input v-model="investor" class="form-check-input" type="radio" id="inlineCheckbox1"
                                        value="1" />
                                    <label class="form-check-label" for="inlineCheckbox1">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input v-model="investor" class="form-check-input" type="radio" id="inlineCheckbox2"
                                        value="0" />
                                    <label class="form-check-label" for="inlineCheckbox2">No</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="margin-top:2vw;" v-if="show">
                    <span>Once your brand profile has been fully completed and you have used your free Mindcoins to add and
                        test your first assets from the Mindspeller Library qnd once you have ordered your first campaign
                        concept in Your Library, you will receive your brand valuation</span>
                </div>

                <div class="btn-col" style="margin-top: 2vw; text-align: center">
                    <button class="btngrey" @click="handleBack
                        " style="margin-right: 2vw">
                        Back
                    </button>
                    <button v-bind:class="getClass()" :disabled="!(this.brand_desc && this.brand_desc.length > 24)"
                        @click="handleBrandConfirm">
                        To your first brand concept 
                    </button>
                </div>
            </div>
            <OnboardingTipPopup 
                v-model="tipVisible" 
                :hasAccordion="false"
                :hasFooter="false"
                :dialogTitle="this.$t('message.brand_info_tip_title')"
                :dialogContent="this.$t('message.brand_info_tip_content')"
                :dialogExtendedContent="this.$t('message.brand_info_tip_extended')"
                :onboardingPhase="onboardingPhase"
                @update:tipVisible="tipVisible = $event">
            </OnboardingTipPopup>
        </section>
    </main>
    <loading-app-wide v-if="display_loading_overlay" />
</template>

<script>
import axios from 'axios';
import slider from 'vue3-slider';
import {
    alert_error
} from '@/helpers/alert_helper.js';
import LandingPage from './LandingPage.vue';
import LoadingAppWide from '@/components/common/LoadingAppWide.vue';
import OnboardingTipPopup from './common/OnboardingTipPopup.vue';

export default {
    name: 'BrandValue',
    components: {
        'vue3-slider': slider,
        LandingPage,
        LoadingAppWide,
        OnboardingTipPopup
    },
    props:
    {
        value: String,
    },

    data() {
        return {
            myNumber: '',
            brand_desc: '',
            investor: '',
            disableConfirm: true,
            show: false,
            showPage: false,
            pageText: localStorage.getItem('pageText'),
            finish_status: localStorage.getItem('finish_status'),
            display_loading_overlay: false,
            selectedTrack: "monthly",
            tipVisible: false,
            onboardingPhase:"brand_info",
        };
    },
    mounted: async function () {
        document.getElementById('mindspeller').style.overflow = "auto";
        const brand_description = await axios.get(
            '/api/cas/users/get_brand_description'
        );

        if (brand_description.status === 200) {
            this.brand_desc = brand_description.data.brand_description;
        }
        const investorData = await axios.get('/api/cas/users/get_investor');
        if (investorData.status === 200) {
            this.investor = investorData.data.investor;
        }
        window.scrollTo(0, 0);
        this.showPage = true;
    },
    methods: {
        showTip(){
            this.tipVisible = true;
        },
        async navigateToSubscriptionPlans() {
            if (this.brand_desc && this.brand_desc.length > 25) {
                const update_investor = await axios.post(
                    '/api/cas/users/update_investor', {
                    investor: parseInt(this.investor),
                }
                );

                if (update_investor.status !== 200) {
                    alert_error(this.$t('message.general_server_error'));
                }

                const update_brand_description = axios.post(
                    '/api/cas/users/update_brand_description', {
                    brand_description: this.brand_desc,
                }
                );
            }
            this.$router.push({
                name: 'Subscription Plans',
                params: {
                    charge_amount: 400,
                    showPrompt:true,
                    ifBack:true,
                },
            });

        },

        handleBack() {
            localStorage.setItem('pageName', 'brand')
            this.$router.push({
                name: 'Passions',
            })
        },

        getClass() {
            if (this.brand_desc && this.brand_desc.length > 25) {
                return 'btn3';
            } else {
                return 'disabled-btn';
            }
        },

        handleBrandConfirm: async function () {
            this.display_loading_overlay = true;
            try {
                if (this.brand_desc) {
                    const update_investor = await axios.post(
                        '/api/cas/users/update_investor', {
                        investor: parseInt(this.investor),
                    }
                    );

                    if (update_investor.status !== 200) {
                        alert_error(this.$t('message.general_server_error'));
                    }

                    const update_brand_description = axios.post(
                        '/api/cas/users/update_brand_description', {
                        brand_description: this.brand_desc,
                    }
                    );

                    if (update_brand_description) {
                        if ((this.finish_status && !this.finish_status["brand_value"]) || !this.pageText) {
                            const step_update = await axios.post(
                                `/api/cas/users/update_onboarding`, {
                                completed_step: 3,
                            }
                            );
                        }

                        await this.$store.dispatch('load_user_data');
                        this.$router.push("/brand-concept-onboarding");
                    } else {
                        return;
                    }
                } else {
                    return;
                }
                this.display_loading_overlay = false;
            } catch (error) {
                this.display_loading_overlay = false;
                alert_error(this.$t('message.general_server_error'));
            }
        },
    },
    computed: {
        disableConfirmBtn: function () {
            if (this.investor && this.brand_desc) {
                this.disableConfirm = false;
            } else {
                this.disableConfirm = true;
            }
            return this.disableConfirm;
        },
    },
};
</script>

<style scoped>
.vue3-slider .handle.hover {
    width: 1.8vw !important;
    height: 1.8vw !important;
    border-radius: 50% !important;
}

.slider {
    width: 84%;
    height: 1.3vw;
}

.bottom-no-col {
    bottom: -1.9vw;
    right: 0;
}

.desc {
    position: relative;
    margin-bottom: 3vw !important;
}

.middle-box {
    display: block;
}

.custom-select-container {
    display: flex;
    justify-content: center;
    margin: 2vw 0;
}

.select-label {
    margin-right: 10px;
}

.custom-select {
    position: relative;
    width: 15VW;
}

select {
    color: #595959;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
    outline: none;
}

.select-arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #555;
    pointer-events: none;
}
.info-tooltip {
    transform: none;
    background: no-repeat;
    vertical-align: text-bottom;
    position: relative;
    cursor: pointer;
}

.info-tooltip:hover .tooptip-text {
    display: block
}
.tooptip-text {
    position: absolute;
    font-size: 1.1vw;
    line-height: 1.3vw;
    font-weight: normal;
    background: #7878E9;
    color: #fff;
    padding: 1vw;
    border-radius: 1vw;
    text-align: justify;
    left: calc(100% + 25px);
    top: -1vw;
    display: none;
    min-height: 150px;
    width: 400px;
    z-index: 999999;
}
</style>
