<template>
  <div class="map-modal" @click.self="$emit('close')">
    <!-- Map in Background -->
    <div class="map-background">
      <vue-world-map
        id="worldmap"
        class="zoomed-map"
        :countryData="map.countryData"
        :defaultCountryFillColor="map.defaultCountryFillColor"
        :highColor="map.highColor"
        :lowColor="map.lowColor"
        :countryStrokeColor="map.countryStrokeColor"
      />
    </div>

    <!-- Split Layout -->
    <div class="split-container">
      <!-- Left Section -->
      <div class="side-container left" @click="navigateTo('https://cas-en.mindspeller.com/#/login', 'CAS-EN Network')">
        <div class="header">
          <font-awesome-icon :icon="['fas', 'users-line']" alt="Login" style="margin-right: 0;" />     
          <font-awesome-icon :icon="['fas', 'comment-dots']" alt="Login" style="margin-left: 0; margin-bottom: 18px; font-size: 14px;" />       
          <span style="margin-left: 15px; margin-right: 15px;">US English Crowdsourced Association Network</span>
          <font-awesome-icon :icon="['fas', 'comment-nodes']" alt="Login" class="header-icon" />          
        </div>
        <div class="body">
          <button class="body-button" @click="navigateTo('https://cas-en.mindspeller.com/#/login', 'CAS-EN Network')">
            Click to go to <strong>CAS-EN</strong> NETWORK
          </button>
        </div>
      </div>

      <!-- Right Section -->
      <div class="side-container right" @click="navigateTo('https://cas-nl.mindspeller.com/#/login', 'CAS-NL Network')">
        <div class="header">
          <font-awesome-icon :icon="['fas', 'users-line']" alt="Login" style="margin-right: 0;" />     
          <font-awesome-icon :icon="['fas', 'comment-dots']" alt="Login" style="margin-left: 0; margin-bottom: 18px; font-size: 14px;" />       
          <span style="margin-left: 15px; margin-right: 15px;">Belgian Dutch Crowdsourced Association Network</span>
          <font-awesome-icon :icon="['fas', 'comment-nodes']" alt="Login" class="header-icon" />          
        </div>
        <div class="body">
          <button class="body-button" @click="navigateTo('https://cas-nl.mindspeller.com/#/login', 'CAS-NL Network')">
            Click to go to <strong>CAS-NL</strong> NETWORK
          </button>
        </div>
      </div>
      </div>

    <!-- Close Button -->
    <button @click="$emit('close')" class="close-btn">Close</button>
  </div>
</template>

<script>
import WorldMap from "vue-world-map";

export default {
  name: "WorldMapModal",
  components: { WorldMap },
  emits: ["close"],
  data() {
    const northAmerica = ["US", "CA", "MX"];
    const europe = [
    "AL", // Albania
    "AD", // Andorra
    "AM", // Armenia (*transcontinental)
    "AT", // Austria
    "AZ", // Azerbaijan (*transcontinental)
    "BY", // Belarus
    "BE", // Belgium
    "BA", // Bosnia and Herzegovina
    "BG", // Bulgaria
    "HR", // Croatia
    "CY", // Cyprus (culturally/politically Europe)
    "CZ", // Czech Republic
    "DK", // Denmark
    "EE", // Estonia
    "FI", // Finland
    "FR", // France
    "GE", // Georgia (*transcontinental)
    "DE", // Germany
    "GR", // Greece
    "HU", // Hungary
    "IS", // Iceland
    "IE", // Ireland
    "IT", // Italy
    "KZ", // Kazakhstan (*mostly in Central Asia, a small part in Europe)
    "XK", // Kosovo (not universally recognized, but often included in "Europe" lists)
    "LV", // Latvia
    "LI", // Liechtenstein
    "LT", // Lithuania
    "LU", // Luxembourg
    "MK", // North Macedonia
    "MT", // Malta
    "MD", // Moldova
    "MC", // Monaco
    "ME", // Montenegro
    "NL", // Netherlands
    "NO", // Norway
    "PL", // Poland
    "PT", // Portugal
    "RO", // Romania
    "SM", // San Marino
    "RS", // Serbia
    "SK", // Slovakia
    "SI", // Slovenia
    "ES", // Spain
    "SE", // Sweden
    "CH", // Switzerland
    "TR", // Turkey (*transcontinental)
    "UA", // Ukraine
    "GB", // United Kingdom
    "VA", // Vatican City (Holy See)
    ];    const regionCountryData = {};
    northAmerica.concat(europe).forEach((code) => {
      regionCountryData[code] = 1;
    });
    regionCountryData["US"] = 10; 
    regionCountryData["BE"] = 10;

    return {
      map: {
        defaultCountryFillColor: "transparent",
        countryStrokeColor: "transparent",
        lowColor: "#f3f3f3",
        highColor: "#7878e9",
        countryData: regionCountryData,
      },
    };
  },
  methods: {
    navigateTo(url, navigationName) {
      this.$store.dispatch('setSelectedNavigation', navigationName); // Set navigation name
      window.location.href = url;
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
/* Map Modal Styling */
.map-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

/* Map Background Styling */
.map-background {
  background: white;
  /* padding: 5px; */
  border-radius: 10px;
  text-align: center;
  width: 80%;
  height: 70%;
  position: fixed;
  overflow: hidden;
  z-index: 999;
}

.zoomed-map {
  transform: scale(1.5);
  transform-origin: 20% 10%;
}

/* Split Layout Styling */
.split-container {
  display: flex;
  align-items: stretch;
  height: 75%;
  margin: 20px auto;
  width: 80%;
  position: relative;
  z-index: 1000;
}

.side-container {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 4px solid #000;
  border-radius: 10px;
  background-color: transparent;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.side-container:hover {
  background-color: #7878e959;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  z-index: 1001;
}

/* Headers Styling */
.header {
  background-color: #7878e9;
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 20px;
  display: flex;
  justify-content:center;
  align-items: center;
  border-bottom: 2px solid #000;
}

.header icon {
  font-size: 24px;
  color: white;
}

/* Body Styling */
.body {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

/* Button Styling */
.body-button {
  display: inline-block;
  margin-top: auto;
  padding: 10px 20px;
  background-color: #7878e9;
  color: white;
  text-align: center;
  border: none;
  border-radius: 10px;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.2s ease;
  margin-bottom: 10px; /* Push the button to the bottom */
}

.body-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}
/* Close Button Styling */
.close-btn {
  margin-top: 10px;
  background-color: #dc3545;
  color: white;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1002;
  position: relative;
}

.close-btn:hover {
  background-color: #c82333;
}
</style>
