<template>
<main>
    <div class="container-fluid full-width-pg">
        <div class="free-trial-pg">
            <MapBrand />
            <div class="rgt-sec-bar">
                <div class="free-login-box">
                    <a href="https://www.mindspeller.com/" target="_blank">
                        <div class="login-logo-imgbx">
                            <img src="../../assets/logo-with-text.png" alt="" />
                        </div>
                    </a>
                    <div class="login-header">
                    <div v-if="selectedNavigation" class="logintotext">Log in to:</div>
                    <div v-else class="logintotext">Log in to:</div>
                    <!-- Map Button -->
                    <div v-if="!selectedNavigation" class="map-btn-container">
                        <button @click="toggleMapModal" class="map-toggle-btn">{{$t('new_asset.choose_language')}}</button>
                    </div>
                    <div v-else class="map-btn-container">
                        <button @click="toggleMapModal" class="map-toggle-btn-selected">{{selectedNavigation}}</button>
                    </div>
                    </div>
                    <div class="formbx singup-from">
                        <form class="row g-3">
                            <div class="col-12">
                                <label for="inputEmail4" class="form-label impot">E mail</label>
                                <input type="email" class="form-control" id="inputEmail4" placeholder="Enter Your Corporate Email Address" v-model="loginSet.email" @click="isEmailNotValid = null" />
                                <p class="matchingPasswordText" v-if="isEmailNotValid">
                                    Please enter a valid email
                                </p>
                            </div>

                            <div class="col-12">
                                <label for="inputPassword4" class="form-label impot">Password</label>

                                <div class="password-div">
                                    <input v-bind:type="getTypePwd()" class="form-control" id="inputPassword4" placeholder="Enter Password" v-model="loginSet.password" />
                                    <span class="eye-icon" @click="this.showPassword = !this.showPassword">
                                        <img v-if="showPassword" src="../../assets/password-eye-open.png" />
                                        <img v-else src="../../assets/password-eye.png" />
                                    </span>
                                </div>
                            </div>
                            <div class="row mb-4 forgot-pass">
                                <div class="col d-flex justify-content-center">
                                    <!-- Checkbox -->
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" v-on:input="handleUser" />
                                        <label class="form-check-label" for="form1Example3">
                                            Remember me
                                        </label>
                                    </div>
                                    <div class="col forgot-link cursor-p">
                                        <!-- Simple link -->
                                        <a @click="forgotPassword">Forgot password?</a>
                                    </div>
                                </div>
                            </div>
                            <div class="btn-col">
                                <!-- <button class="btn btn-primary btn-round" @click="$router.push('/archetypes')">Log in</button> -->
                                <button type="button" v-bind:class="getClass()" @click="login" :disabled="!this.loginSet.email || !this.loginSet.password">
                                    Log In
                                </button>
                            </div>
                        </form>

                        <div class="google-login">
                            <div class="logindiv">
                                Don't have an account?
                                <router-link :to="{ name: 'Free Trial' }">
                                    Start Trial
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <WorldMapModal v-if="this.mapModalVisible" @close="toggleMapModal" />

</main>
<loading-app-wide v-if="loading" />
</template>

<script>
import axios from 'axios';
import WorldMapModal from "@/components/library/WorldMapModal.vue";
import MapBrand from './Common/MapBrand.vue';
import {
    changeLanguage
} from '@/helpers/language_helper.js';
import {
    isLoggedIn
} from '@/helpers/login_helper.js';
import AlternativeLangLink from '@/components/common/AlternativeLangLink';
import VueCookies from 'vue-cookies'
import {
    alert_error
} from '@/helpers/alert_helper.js';
import LoadingAppWide from '@/components/common/LoadingAppWide.vue';

export default {
    name: 'Login',
    created() {
        // Clear any existing tokens when the login page is visited
        localStorage.removeItem('jwt_token');
        localStorage.removeItem('jwt_refresh_token');
        localStorage.removeItem('is_anonymous');
    },
    components: {
        MapBrand,
        AlternativeLangLink,
        WorldMapModal,
        LoadingAppWide
    },
    data() {
        return {
            loginSet: {
                username: '',
                email: '',
                password: '',
                showPassword: false,
                loginFailed: false,
            },
            remember: false,
            showPassword: false,
            isEmailNotValid: null,
            loading: false,
            mapModalVisible: false,
        };
    },
    computed: {
        passwordInputType: function () {
            return this.loginSet.showPassword ? 'text' : 'password';
        },
        selectedNavigation() {
            return this.$store.getters.selectedNavigation;
        },
    },
    emits: ['close'],
    methods: {
        openNetworkPage(){
            localStorage.removeItem('jwt_token');
            localStorage.removeItem('jwt_refresh_token');
            localStorage.removeItem('is_anonymous');
            localStorage.removeItem('pageText');
            localStorage.removeItem('pageName');
            this.$router.push({name: 'Mindspeller_Network'});
            return axios.post('/api/cas/token/refresh_for_network', {}).then(response => {
            localStorage.setItem('jwt_token', response.data['x-jwt-access-token']);
            localStorage.setItem('jwt_refresh_token', response.data['x-jwt-refresh-token']);
            localStorage.setItem('is_anonymous', 'true');

            });
        },
        forgotPassword() {
            this.$router.push({
                name: 'Forgot Password'
            });
        },
        getTypePwd() {
            if (this.showPassword) {
                return 'text';
            } else {
                return 'password';
            }
        },
        getClass() {
            return {
                'btn3': this.loginSet.email && this.loginSet.password,
                'disabled-btn': !this.loginSet.email || !this.loginSet.password,
            };
        },
        handleUser() {
            const user = {
                email: `${this.loginSet.email}`,
                password: `${this.loginSet.password}`,
                username: `${this.loginSet.username}`,
            }
            $cookies.set('user', user);
        },
        login: async function () {
            const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (!this.loginSet.email || !this.loginSet.password) {
                return;
            } else if (!this.loginSet.email.toLowerCase().match(emailRegex)) {
                return (this.isEmailNotValid = 'Please enter a valid email');
            }
            this.loading = true;
            try {
                this.isEmailNotValid = null;
                const user = {
                    username: this.loginSet.username,
                    password: this.loginSet.password,
                    email: this.loginSet.email,
                    remember: this.remember,
                };

                await this.$store.dispatch( 'loginWe', user);
                await this.$store.dispatch('load_user_data');
                changeLanguage(this.$i18n, this.$store.state.current_user.language);

                // if (this.$store.state.current_user.onboarding_step === null || this.$store.state.current_user.onboarding_step === 0) {
                //     this.$router.push({
                //         name: 'Archetypes',
                //         params: {
                //             userId: this.$store.state.current_user.id,
                //             onboarding_step: this.$store.state.current_user.onboarding_step,
                //         },
                //     });
                // } else if (this.$store.state.current_user.onboarding_step === 1) {
                //     this.$router.push({
                //         name: 'Passions',
                //         params: {
                //             onboarding_step: this.$store.state.current_user.onboarding_step,
                //         },
                //     });
                // } else if (this.$store.state.current_user.onboarding_step === 2) {
                //     this.$router.push({
                //         name: 'Brand Value',
                //         params: {
                //             onboarding_step: this.$store.state.current_user.onboarding_step,
                //         },
                //     });
                // } else {
                    this.$router.push({
                        name: 'Dashboard',
                    });
                // }
            } catch (error) {
                if (error.response.status === 500) {
                    alert_error(this.$t('message.general_server_error'));
                } else {
                    this.loginFailed = true;
                    alert_error('Wrong username or password');
                }
            }
            this.loading = false;
        },
        toggleMapModal() {
            this.mapModalVisible = !this.mapModalVisible;
        },
    },
    beforeMount: function () {
        // if the user is not logged in we choose the language based on the
        // browser settings since we don't know their preferred language (once the
        // user logs in we get their preferred language from the API)
        if (!isLoggedIn()) {
            const lang = this.$store.getters.getEnvVar('VUE_APP_DEFAULT_LANGUAGE');
            if (lang !== '') {
                changeLanguage(this.$i18n, lang);
            } else {
                const browserLang = navigator.language;
                changeLanguage(this.$i18n, browserLang);
            }
        }
    },
    mounted: function () {
        const user = $cookies.get('user');
        if (user) {
            this.loginSet.email = user.email;
            this.loginSet.password = user.password;
            this.loginSet.username = user.username;
        }
    },
};
</script>

<style scoped>
.matchingPasswordText {
    text-align: left;
    color: red;
    margin-top: 14px;
}
.login-logo-imgbx {
    /* width: 80%; */
    margin: 0 0 2.5vw;
}
.user-icon-wrapper {
  position: fixed;
  top: 5%; /* Adjust the distance from the top edge */
  right: 3%; /* Adjust distance from the right edge */
  background-color: black; /* Background color of the circle */
  border-radius: 50%; /* Make it circular */
  width: 40px; /* Circle size */
  height: 40px; /* Circle size */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.user-icon {
  color: white; /* Icon color */
  font-size: 20px; /* Adjust size as needed */
}

.login-text {
  position: absolute;
  bottom: -50px; /* Position the text below the user icon */
  left: 50%;
  transform: translateX(-50%);
  background-color: #333; /* Dark background for the text */
  color: white; /* Text color */
  padding: 5px 8px;
  border-radius: 5px;
  font-size: 0.8em;
  opacity: 0; /* Initially hidden */
  pointer-events: none; /* Ignore pointer events on the text */
  transition: opacity 0.3s ease; /* Smooth transition for appearing/disappearing */
}

.logintotext{
    display: block;
    text-align: center;
    width: 30%;
    color: #000185;
    font-weight: 700;
    font-size: 1.8vw;
    line-height: 2vw;
    text-transform: capitalize;
    color: #1B2F3F;
    margin-bottom: 1.5vw;
}

.user-icon-wrapper:hover .login-text {
  opacity: 1; /* Show the text on hover */
}

.map-btn-container {
  margin: 0;
}

.login-header {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 1.5vw; */
}

.map-toggle-btn {
  background-color: #0a00ff;
  color: white; 
  font-weight: 600;
  font-size: 1vw;
  padding: 15px 10px;
  border: solid 2px #0d3a69;
  border-radius: 10px;
  margin-bottom: 1.5vw;
  animation: bounce 1.5s infinite;
  cursor: pointer;
}

.map-toggle-btn-selected {
  background-color: #0a00ff;
  color: white; 
  font-weight: 600;
  font-size: 1vw;
  padding: 15px 10px;
  border: solid 2px #0d3a69;
  border-radius: 10px;
  margin-bottom: 1.5vw;
  cursor: pointer;
}

.map-toggle-btn:hover {
  background-color: #423bffb7;
  transform: scale(1.1);
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
}
</style>
