<template>
  <div class="btn-col" v-if="showButton">
    <button class="btn3" :title="asset_empty ? $t('message.select_asset_before_adding') : null"
       @click="handleAddClick">
      <!-- <div v-if="selected_asset.state === 1">
        Asset not ready
      </div> -->
      <div>
        {{ $t('message.add') }}
      </div>
    </button>
  </div>
</template>

<script>
import axios from 'axios';
import { toast_success, genericDialog, alert_error } from '@/helpers/alert_helper.js';

export default {
  emits: ['add', 'close'],
  props: {
    selected_asset: { required: true, type: Object },
  },

  computed: {
    showButton: function () {

      return Object.keys(this.selected_asset).length > 0;
    },
  },
  methods: {
    async handleAddClick() {
      const mediaType = this.selected_asset.type_.title.split('/')[0];
      if (mediaType !== 'image ' && mediaType !== 'text') {
        const dialog_options = {
            title: "Unsupported Format",
            text: "Unfortunately, video or audio formats are not supported at this time. Please select an image asset.",
            confirmButtonText: "OK",
        };

        await genericDialog(dialog_options); // Show the dialog to inform the user
        return; // Exit the function if the asset is not an image
    }
      if (this.$store.getters.addedAssets.includes(this.selected_asset.display_name)) {
        // If already added, directly emit the add event without showing the dialog or deducting mindcoins
        this.$emit('add');
        return;
      }
      // Open a confirmation dialog before proceeding
      const dialog_options = {
        title: "Confirm Asset Addition",
        text: "For a limited time, enjoy our latest feature free of charge—normally 10 Mindcoins, but it’s on us during the beta!",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
      };

      const dialog_result = await genericDialog(dialog_options);
      if (!dialog_result.isConfirmed) {
        return; // Abort if user cancels the action
      }

      if (dialog_result.isConfirmed) {
          // next, check if the user has enough credits
          // await this.$store.dispatch('get_mindcoin_balance');
          // if (this.$store.state.mindcoins < 10) {
          //     alert_error(this.$t('new_asset.insufficient_credits'));
          //     return;
          // }
          // try {
          //   const response = await axios.post(
          //     '/api/cas/association_wheels/addingAsset',
          //     JSON.stringify(this.selected_asset),
          //     {
          //       headers: {
          //         'Content-Type': 'application/json'
          //       }
          //     }
          //   );
            this.$store.dispatch('addAsset', this.selected_asset.display_name);
          //   this.$store.dispatch('get_mindcoin_balance');
            this.$emit('add');
          // } catch (error) {
          //         alert_error("Error deducting mindcoins or adding asset");
          //         alert_error(this.$t('message.general_server_error'));
          // }
        }
    },
  },
};
</script>

<style scoped lang="scss">
.asset-picker-button {
  position: absolute;
  bottom: 0;
  right: 0;
  overflow-y: scroll;

  @media (min-width: 1024px) {
    right: ($sidebar-small - 200px) / 2;
  }

  @media (min-width: 1600px) {
    right: ($sidebar-medium - 200px) / 2;
  }

  @media (min-width: 1920px) {
    right: ($sidebar-big - 200px) / 2;
  }
}

.search-bar {
  margin: 55px 0 0 1px;
}
</style>
