<template>
    <div class="popup-overlay">
      <div class="popup">
        <!-- Close Button -->
        <q-btn icon="close" flat round dense class="close-button" @click="$emit('exit')" />
  
        <div class="popup-header">
          <h2 class="popup-title">CUSTOMIZE YOUR NETWORK</h2>
        </div>
  
        <!-- Instruction Box -->
        <div class="instruction-box">
          <p class="instruction-title">
            Unlock Your Customized Brand Network to Gain Deeper Insights
          </p>
  
          <ul class="instruction-list">
            <li>
              <font-awesome-icon :icon="['fas', 'check-circle']" class="check-icon" />
              <strong>Turn the Mindspeller Network into your own</strong> by integrating ‘My Library’ concepts.
            </li>
            <li>
              <font-awesome-icon :icon="['fas', 'check-circle']" class="check-icon" />
              <strong>Toggle between Mindspeller and Your Network</strong> to hide confidential concepts.
            </li>
            <li>
              <font-awesome-icon :icon="['fas', 'check-circle']" class="check-icon" />
              <strong>Uncover semantic distance scores</strong> to benchmark your concepts’ position.
            </li>
          </ul>
        </div>
  
        <!-- CTA Button -->
        <button class="cta-button" @click="$emit('openPricingPopup')">
          <font-awesome-icon :icon="['fas', 'tag']" /> See Pricing & Timing
        </button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "CustomizePopup",
    emits: ["exit", "openPricingPopup"]
  };
  </script>
  
  
  <style scoped>
  /* Popup Background */
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  /* Popup Container */
  .popup {
    background: white;
    width: 60%;
    max-width: 900px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  /* Popup Header */
  .popup-header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid #ccc;
    padding-bottom: 10px;
    position: relative;
  }
  
  /* Popup Title */
  .popup-title {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
  }
  
  /* Close Button */
  .close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    color: black;
  }
  
  /* Instruction Box */
  .instruction-box {
    background: #eaeaff;
    border-radius: 8px;
    padding: 15px;
    margin-top: 20px;
  }
  
  /* Instruction Title */
  .instruction-title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
  }
  
  /* Instruction List */
  .instruction-list {
    list-style: none;
    padding: 0;
  }
  
  .instruction-list li {
    /* display: flex;
    align-items: center; */
    font-size: 14px;
    margin-bottom: 18px;
  }
  
  /* Check Icon */
  .check-icon {
    color: #5a67d8;
    margin-right: 8px;
  }
  
  /* CTA Button */
  .cta-button {
    background: #7878e9;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto 0;
    cursor: pointer;
    transition: 0.3s;
    width: 100%;
  }
  
  .cta-button:hover {
    background: #5a5dbb;
  }
  
  .cta-button svg {
    margin-right: 5px;
  }
  </style>
  