<template>
    <div class="maintenance">
      <div>
        <div class="steps-container">
        <h2>We are currently performing maintenance work...</h2>
        <div class="step">
          <p>Please try reloading the page and clearing your browser cache/cookies.</p>
        </div>
        <div class="step">
          <p>If you don't have access within the next 30 minutes, please try <a href="https://cas-en.mindspeller.com/#/login" target="_blank">this link for English</a> / <a href="https://cas-nl.mindspeller.com/#/login" target="_blank">this link for Dutch</a>.</p>
        </div>
        <div class="step">
          <p>If you still don't have access, please contact your <a href="mailto:contact@mindspeller.com">account manager</a>.</p>
        </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        currentStep: 1
      };
    },
    methods: {
      nextStep() {
        if (this.currentStep < 3) {
          this.currentStep++;
        }
      }
    }
  };
  </script>
  
  <style>
  .maintenance {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
  }
  
  .steps-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

  .step {
    margin: 20px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    min-width: 500px;
    max-width: 800px;
    margin-left: 0;
    margin-right: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .step.active {
    border-color: #007bff;
    background-color: #e9f7ff;
  }
  
  button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  </style>