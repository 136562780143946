<template>
  <main>
    <div class="container-fluid full-width-pg forget-pass-pg">
      <div class="free-trial-pg">
        <MapBrand />
        <div class="rgt-sec-bar">
          <div class="free-login-box">
            <div class="logo-imgbx">
              <img src="../../assets/logo-no-text.png" alt="" />
            </div>
            <div class="textbx">
              Set the password for your account

              <ul class="set-passw-li">
                <li>
                  <span v-if="hasLength">✅ </span
                  ><span :style="hasLengthColor">{{
                    $t("message.characters_requirement")
                  }}</span
                  ><br />
                </li>
                <li>
                  <span v-if="hasUppercase">✅ </span
                  ><span :style="hasUppercaseColor">{{
                    $t("message.uppercase_requirement")
                  }}</span
                  ><br />
                </li>
                <li>
                  <span v-if="hasLowercase">✅ </span
                  ><span :style="hasLowercaseColor">{{
                    $t("message.lowercase_requirement")
                  }}</span
                  ><br />
                </li>
                <li>
                  <span v-if="hasNumberAndWhitespace">✅ </span
                  ><span :style="hasNumberAndWhitespaceColor">{{
                    $t("message.number_or_whitespace_requirement")
                  }}</span
                  ><br />
                </li>
              </ul>
            </div>
            <div class="formbx set-pass-from">
              <form>
                <div class="form-group" style="margin-bottom: 2vw;">
                  <label>{{ $t("message.set_password") }}</label>
                  <div style="display: flex; gap: 5px; align-items: center;">
                    <input
                      class="form-control"
                      :type="passwordInputType"
                      v-model="passwordOne"
                      @blur="checkPasswordMatch"
                    />
                    <span class="eye-icon1" @click="this.showPassword = !this.showPassword">
                        <img v-if="showPassword" src="../../assets/password-eye-open.png" />
                        <img v-else src="../../assets/password-eye.png" />
                    </span>
                  </div>
                </div>

                <div class="form-group">
                  <label>{{ $t("message.repeat_password") }}</label>
                  <div style="display: flex; gap: 5px; align-items: center;">
                    <input
                      class="form-control"
                      :type="passwordInputType"
                      v-model="passwordTwo"
                      @blur="checkPasswordMatch"
                    />
                    <span class="eye-icon1" @click="this.showPassword = !this.showPassword">
                        <img v-if="showPassword" src="../../assets/password-eye-open.png" />
                        <img v-else src="../../assets/password-eye.png" />
                    </span>
                  </div>
                </div>
                <p class="matchingPasswordText" v-if="!passwordMatch">
                  {{ $t("message.passwords_dont_match") }}
                </p>

                <button
                  type="button"
                  :disabled="buttonActive"
                  v-bind:class="getClass()"
                  style="margin-top: 10px;"
                  @click="proceedToOnboarding"
                >
                  {{ $t("message.start_trial") }}
                </button>
              </form>
              <div class="logindiv">
                Already have an account?
                <router-link :to="{ name: 'Login' }">Log In </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <loading-app-wide v-if="loading" />
</template>

<script>
import axios from "axios";
import MapBrand from "./Common/MapBrand.vue";
import { changeLanguage, alert_error } from "@/helpers/language_helper.js";
import LoadingAppWide from "@/components/common/LoadingAppWide.vue";

export default {
  name: "PasswordSetup",
  components: {
    MapBrand,
    LoadingAppWide,
  },
  props: {
    userEmail: {
      required: true,
      type: String,
    },
  },
  data: function () {
    return {
      passwordOne: new String(),
      passwordTwo: new String(),
      acceptedTOS: false,
      showPassword: false,
      passwordMatch: Boolean,
      email: this.$props.userEmail,
      loading: false,
    };
  },
  computed: {
    // All password requirements have to be met to be able to proceed to the next screen.
    buttonActive: function () {
      return !(
        this.passwordOne != "" &&
        this.passwordTwo !== "" &&
        this.passwordOne == this.passwordTwo &&
        this.hasLowercase &&
        this.hasUppercase &&
        this.hasLength &&
        this.hasNumberAndWhitespace
      );
    },
    passwordInputType: function () {
      return this.showPassword ? "text" : "password";
    },
    hasUppercase: function () {
      return /[A-Z]/.test(this.passwordOne);
    },
    // Coloring UI element if password meets the requirement.
    hasUppercaseColor: function () {
      return this.hasUppercase ? "color: green;" : "color: black;";
    },
    hasLowercase: function () {
      return /[a-z]/.test(this.passwordOne);
    },
    hasLowercaseColor: function () {
      return this.hasLowercase ? "color: green;" : "color: black;";
    },
    hasNumberAndWhitespace: function () {
      return /[1-9]/.test(this.passwordOne) || this.passwordOne.includes(" ");
    },
    hasNumberAndWhitespaceColor: function () {
      return this.hasNumberAndWhitespace ? "color: green;" : "color: black;";
    },
    hasLength: function () {
      return this.passwordOne.length >= 8;
    },
    hasLengthColor: function () {
      return this.hasLength ? "color: green;" : "color: black;";
    },
  },
  methods: {
    getClass: function () {
      return {
        btn3: !this.buttonActive,
        "disabled-btn": this.buttonActive,
      };
    },

    // If user set up his email and account, we transfer him to Onboarding where the
    // rest of registration takes place. The data collected so far gets sent as a
    // prop.
    proceedToOnboarding: async function () {
      this.loading = true;
      const payload = {
        user_data: {
          user_email: this.email,
          user_password: this.passwordOne,
        },
      };

      try {
        const result = await axios.post("/api/cas/register_demo", payload);

        if (result.status === 200) {
          const data = {
            email: this.email,
            password: this.passwordOne,
          };

          await this.$store.dispatch("loginWe", data);
          await this.$store.dispatch("load_user_data");
          changeLanguage(this.$i18n, this.$store.state.current_user.language);
          // this.$router.push({
          //   name: "Redirecting",
          //   params: {
          //     userEmail: this.email,
          //     userPassword: this.passwordOne,
          //   },
          // });
          this.$router.push({path: '/dashboard', query:{active: ''}});
        }
      } catch (error) {
        alert_error(this.$t("message.general_server_error"));
      }
      this.loading = false;
    },
    checkPasswordMatch: function () {
      if (this.passwordOne.length > 0 && this.passwordTwo.length > 0) {
        if (this.passwordOne != this.passwordTwo) {
          this.passwordMatch = false;
          return;
        } else {
          this.passwordMatch = true;
          return;
        }
      } else {
        this.passwordMatch = Boolean;
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.onboarding-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.signup-dialog {
  width: 55%;
  min-height: 65%;
}

input:focus {
  outline: 1px solid transparent;
}

.password-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-button {
  margin: 20px !important;
}

.mdsp_link {
  color: $mdspblue_ui;
  underline: 1px solid transparent;
}

.mdsp_link:hover {
  underline: 1px solid $mdspblue_ui;
}

.tosText {
  cursor: pointer;
}

.no-padding {
  padding-top: 10px;
  padding-bottom: 10px;
}

.no-padding-bottom {
  padding-top: 20px;
  padding-bottom: 0;
}

.text-center {
  text-align: center;
}

.matchingPasswordText {
  text-align: left;
  color: red;
  margin-top: 14px;
}
span.eye-icon1{width: 22px; cursor: pointer; display: inline-block; position: absolute; right: 10px;}

span.eye-icon1 img{width: 100%;}
</style>
