<template>
  <div id="app">
    <!-- White Taskbar -->
    <div class="top-taskbar">
      <!-- Centered Logo with Tagline -->
      <div class="logo-container">
        <img src="../../assets/logo-with-text.png" alt="Logo" class="taskbar-logo" />
        <div class="tagline"><span class="play-button" @click="openBrandGamePopup">PLAY</span> to Discover your Next Favorite Brand Today!</div>
      </div>
      <!-- User Icon on the top right -->
      <div class="user-icon-wrapper" @click="openWordPopup">
        <font-awesome-icon :icon="['fas', 'user']" alt="Login" class="user-icon" />
        <span class="login-text">Login</span>
      </div>

      <!-- <div class="brand-icon-wrapper" @click="openBrandGamePopup">
        <font-awesome-icon :icon="['fas', 'table']" alt="Login" class="user-icon" />
        <span class="login-text">Brand Game</span>
      </div> -->
    </div>

    
    <!-- Custom Sidebar for displaying the node information -->
    <div class="sidebar-wrapper" v-if="wordData && Object.keys(wordData).length">
      <Sidebar :wordData="wordData" />
    </div>
    <!-- Grouped Instructions Button and Pointing Label -->
    <div class="instructions-wrapper">
      <button class="instructions-button" @click="openInstructionsPopup">
        <font-awesome-icon :icon="['fas', 'question']" class="header-icon" />
      </button>
      <!-- <div v-if="this.showInstructionPopup" class="readme-label">Read Me!</div> -->
    </div>

    <!-- Instructions Popup -->
    <InstructionsPopup ref="instructionsPopup" />
    <WelcomePopup ref="welcomePopup"/>
    <B2CLoginPopup ref="b2cLoginPopup"/>
    <BrandGame ref="brandGame" />

    <!-- TensorBoard iframe embedded -->
    <iframe
      src="https://tensorboard.mindspeller.com/#projector"
      id="second-iframe"
    ></iframe>

    <!-- Bottom Taskbar -->
    <footer class="bottom-taskbar">
      <div class="bottom-taskbar-left">
        <button @click="handleAdvertising" class="bottom-button">Advertising</button>
        <button @click="handleAboutUs" class="bottom-button">About Us</button>
      </div>
      <div class="bottom-taskbar-center">
        <a href="https://cas-en.mindspeller.com/#/free_trial" target="_blank" rel="noopener noreferrer">Join Our Community </a> of over <CountingNumber :number="totalMembers" /> Minds Mapping Brand Memory
      </div>
      <div class="bottom-taskbar-right">
        <button @click="handleLegal" class="bottom-button">Legal</button>
      </div>
    </footer>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";
import Sidebar from "./Sidebar.vue";
import BrandGame from "../b2cgame/BrandGame.vue";
import InstructionsPopup from "./InstructionsPopup.vue";
import WelcomePopup from "./WelcomePopup.vue";
import { mapGetters } from "vuex";
import CountingNumber from "./CountingNumber.vue";
import B2CLoginPopup from './B2CLoginPopup.vue';


export default {
  name: "Mindspeller_Network",
  created() {
        // Clear any existing tokens when the login page is visited
        localStorage.removeItem('jwt_token');
        localStorage.removeItem('jwt_refresh_token');
        localStorage.removeItem('is_anonymous');
    },
  components: {
    Sidebar,
    InstructionsPopup,
    CountingNumber,
    BrandGame,
    WelcomePopup,
    B2CLoginPopup,
  },
  computed: {
    ...mapGetters(["showInstructionPopup"]), // Getter for instruction popup visibility
  },
  data() {
    return {
      index: null, // Index selected from TensorBoard
      wordData: null, // Stores word data received from API
      label: null,
      totalAssociations: 117021,
      totalMembers: 117021,
    };
  },
  methods: {
    openWordPopup() {
        this.$refs.b2cLoginPopup.openPopup();
      },
    openInstructionsPopup() {
      this.$refs.instructionsPopup.openPopup();
    },
    openBrandGamePopup() {
      this.$refs.brandGame.openPopup();
    },
    handleAdvertising: function () 
    {
        window.open("https://www.mindspeller.com/", "_blank")
    },
    handleAboutUs: function () 
    {
        window.open("https://www.mindspeller.com/about-us/", "_blank")
    },
    handleLegal: function () 
    {
        window.open("https://www.mindspeller.com/legal/", "_blank")
    },
    openLoginPage(){
      localStorage.removeItem('jwt_token');
      localStorage.removeItem('jwt_refresh_token');
      localStorage.removeItem('is_anonymous');
      localStorage.removeItem('pageText');
      localStorage.removeItem('pageName');
      this.$router.push({name: 'Login'})
    },
    handleIframeMessage(event) {
      if (event.data.type === "tensorboard-label") {
        const sidebar_selection = event.data.data;
        if (sidebar_selection) {
          this.label = sidebar_selection;
          this.get_label();
        } else {
          console.log("Sidebar selection is empty");
        }
      } else if (event.data.type === "clear-selection") {
        console.log("Clear selection received");
        this.wordData = null;
      }
    },
    async get_label() {
      let payload = {
        label: this.label,
        organisation: 'en',
      };
      try {
        // Send POST request to get the word at the specified index
        const response = await axios.post(
          "/api/cas/survey_cleaning/projector",
          payload
        );
        let wordData = response.data;
        this.wordData = wordData;
      } catch (error) {
        console.error("Error during API call:", error);
      }
    },
    async fetchCounts() {
      try {
        const response = await axios.get("/projector/taskbarcount");
        this.totalAssociations = response.data.total_word_associations;
        this.totalMembers = response.data.total_respondents;
      } catch (error) {
        console.error("Error fetching counts:", error);
      }
    },
    showAlert(message) {
      alert(message);
    },
  },
  watch: {
    wordData(newVal, oldVal) {
      if (!oldVal && newVal && Object.keys(newVal).length) {
        // Trigger alert when wordData transitions from null to full
        this.showAlert('Click "ISOLATE" to continue. Use the slider to limit the neighbors.');
      }
    },
  },
  mounted() {
    // Add event listener for messages from the iframe
    // this.openInstructionsPopup();
    window.addEventListener("message", this.handleIframeMessage);
    this.fetchCounts();

    // Set an interval to update the counts periodically (e.g., every 10 seconds)
    this.countUpdateInterval = setInterval(() => {
      this.fetchCounts();
    }, 100000); // 100000ms = 100 seconds
  },
  beforeDestroy() {
    // Remove event listener when the component is destroyed
    window.removeEventListener("message", this.handleIframeMessage);
  },
};
</script>

<style>
.top-taskbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 0;
  height: 15%; /* Adjust height as needed */
  background-color: white;
  z-index: 4; /* Highest z-index */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  /* border-bottom: 2px solid #6c63ff; */
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
}

.logo-container {
  text-align: center; /* Center tagline below the logo */
}

.taskbar-logo {
  height: 110px; /* Adjust image height */
  width: auto;
}

.tagline {
  text-align: center;
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
  flex: 1; /* Allow the center to grow and push left/right sections to edges */
}

.user-icon-wrapper {
  position: absolute;
  top: 25%; /* Adjust the distance from the top edge */
  right: 3%; /* Adjust distance from the right edge */
  background-color: black; /* Background color of the circle */
  border-radius: 50%; /* Make it circular */
  width: 40px; /* Circle size */
  height: 40px; /* Circle size */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.brand-icon-wrapper {
  position: absolute;
  top: 25%; /* Adjust the distance from the top edge */
  right: 10%; /* Adjust distance from the right edge */
  background-color: black; /* Background color of the circle */
  border-radius: 50%; /* Make it circular */
  width: 40px; /* Circle size */
  height: 40px; /* Circle size */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.user-icon {
  color: white; /* Icon color */
  font-size: 20px; /* Adjust size as needed */
}

.login-text {
  position: absolute;
  bottom: -25px; /* Position the text below the user icon */
  left: 50%;
  transform: translateX(-50%);
  background-color: #333; /* Dark background for the text */
  color: white; /* Text color */
  padding: 5px 8px;
  border-radius: 5px;
  font-size: 0.8em;
  opacity: 0; /* Initially hidden */
  pointer-events: none; /* Ignore pointer events on the text */
  transition: opacity 0.3s ease; /* Smooth transition for appearing/disappearing */
}

.user-icon-wrapper:hover .login-text {
  opacity: 1; /* Show the text on hover */
}

#second-iframe {
  width: 100%; /* Reduced width to 80% */
  height: 95vh; /* Adjust height as needed */
  border: none;
  position: fixed; /* Fix the iframe to the viewport */
  bottom: 0;
  margin: 0 auto; /* Center horizontally */
  padding-top: 20px; /* Optional: spacing from the top */
}

.sidebar-wrapper {
  position: fixed;
  top: 10%;
  left: 0;
  width: 22%;
  height: calc(100% - 60px);
  z-index: 2;
}

/* Wrapper for Instructions Button and Label */
.instructions-wrapper {
  position: fixed;
  top: 20%; /* Adjust this for vertical alignment */
  left: 22%; /* Adjust this for horizontal alignment */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px; /* Space between the button and the label */
  z-index: 4;
}

.icon {
  font-size: 1.5em;
  color: white;
  pointer-events: none;
}

.instructions-button {
  width: 30px;
  height: 30px;
  background-color: black;
  color: white;
  border: 1px solid black;
  border-radius: 15px;
   display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.instructions-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
  transform: scale(1.1);
}

.instructions-button:active {
  transform: scale(1);
}

.readme-label{
  display: inline-block !important;
  background-color: #9797eb;
  color: black;
  font-size: 0.9em;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-left: 10px; /* Ensures spacing from the button */
  position: relative;
}

.readme-label::after{
  content: "";
  position: absolute;
  top: 50%;
  right: 100%; /* Position arrow on the left side */
  transform: translateY(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: transparent #9797eb transparent transparent;
}

/* Bottom Taskbar Styles */
.bottom-taskbar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #b5b6b7; /* Light grey background */
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space items across the width */
  z-index: 5;
  /* border-top: 2px solid #6c63ff; */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Slight shadow above the taskbar */
}

.bottom-taskbar-left,
.bottom-taskbar-right {
  display: flex;
  gap: 10px; /* Spacing between buttons */
}

.bottom-taskbar-center {
  text-align: center;
  font-size: 1.1em;
  font-weight: bold;
  margin-right: 3%;
  color: #333;
  flex: 1; /* Allow the center to grow and push left/right sections to edges */
}

.bottom-button {
  background: none;
  border: none;
  font-size: 0.9em;
  /* font-weight: bold; */
  color: #333;
  cursor: pointer;
  transition: color 0.3s ease;
}

.bottom-button:hover {
  font-weight: bold;
}

.play-button {
  display: inline-block; /* Behave like a button */
  padding: 5px 10px; /* Padding for button-like appearance */
  background-color: #6c63ff; /* Button background color */
  color: white; /* Text color */
  font-weight: bold; /* Make text bold */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for a 3D effect */
  cursor: pointer; /* Pointer cursor */
  animation: flash 1.5s infinite; /* Flashing animation */
  transition: transform 0.2s; /* Smooth hover effect */
}

.play-button:hover {
  transform: scale(1.1); /* Enlarge slightly on hover */
}

@keyframes flash {
  0%, 100% {
    background-color: #bdbbe5; /* Original color */
  }
  50% {
    background-color: #7878e9; /* Slightly lighter color for flashing effect */
  }
}


@media screen and (min-width: 1600px) {
  .instructions-wrapper {
  position: fixed;
  top: 23.5%;
  left: 17%;
  /* margin-left: 10px; */

}
.top-taskbar {
 height: 15%; /* Adjust height as needed */

}
}

@media screen and (min-width: 1440px) and (max-width: 1599px) {
  .instructions-wrapper {
  position: fixed;
  top: 29%;
  left: 20%;
  /* margin-left: 10px; */

}
.top-taskbar {
  height: 17%; /* Adjust height as needed */

}
}

@media screen and (max-width: 1439px) and (min-width: 1024px) {
  .instructions-wrapper {
  position: fixed;
  top: 33%;
  left: 23%;
  /* margin-left: 10px; */
}
}
.top-taskbar {
   height: 20%; /* Adjust height as needed */

}
@media screen and (max-width: 1023px) and (min-width: 768px) {
  .instructions-button {
  position: fixed;
  top: 25%;
  left: 29%;
  /* margin-left: 10px; */

}
.top-taskbar {
  height: 22%; /* Adjust height as needed */

}

}
</style>
