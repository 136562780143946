<template>
<section class="middle-section">
    <div class="container">
        <div class="payment-successr-div">
            <h5>
                Payment Success
                <span class="ext-sml-txt">{{ mindcoins }} Mindcoins have been add to your account</span>
            </h5>

            <div class="comp-check">
                <img src="../../assets/suss-img.png" />
            </div>

            <div class="btn-div">
                <a class="btn3" @click="handleSuccess">Go to Dasboard</a>
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    name: 'PaymentSuccess',
    props: {
        mindcoins: {
            type: String,
            default: 0,
            required: true,
        },
    },
    mounted: function () {
        if (!this.mindcoins) {
            if (this.$store.state.current_license.monthly_fee > 0) {
                this.$router.push({
                    name: 'Upgrade Screen',
                });
            } else {
                this.$router.push({
                    name: 'Subscription Plans',
                });
            }
        }
    },
    methods: {
        handleSuccess: function () {
            if (this.$store.state.current_user.onboarding_step >= 3) {
                this.$router.push({
                    name: 'Dashboard',
                });
            } else {
                if (this.$store.state.current_user.onboarding_step <= 0) {
                    this.$router.push({
                        path: '/archetypes',
                        params: {
                            pageText: 'payment',
                        },
                    });
                } else if ((this.$store.state.current_user.onboarding_step = 1)) {
                    this.$router.push({
                        name: 'Passions',
                        params: {
                            pageText: 'payment',
                        },
                    });
                } else if ((this.$store.state.current_user.onboarding_step = 2)) {
                    this.$router.push({
                        name: 'Brand Value',
                        params: {
                            pageText: 'payment',
                        },
                    });
                }
            }
        },
    },
};
</script>
