const co_translation = {
    en: {
        message: {
            suggestions_tip: 'After selecting an Intended Association, ' +
                'start optimizing your copy by considering the forward and ' +
                'backward associations for rewriting your sentences.',
            enter_a_text: 'Enter a text and an Intended Association to start optimizing',
            content_optimization: 'COPY OPTIMIZATION',
            click_forward: "Click 'forward' for inspirational associations with " +
                "the selected word in your text. If the selected word in your " +
                "text is positioned, you can browse the list below to search for " +
                "higher scoring alternatives To trigger your Intended Association. " +
                "Consider amending your sentences by using higher scoring words to " +
                "optimize your copy. Click on 'backward' to browse inspirational " +
                "associations that are likely to trigger the selected word in " +
                "the mind of your audience.",
            this_word_image: "This word/image is not yet mapped in our network, " +
                "if you would like to order this word/image for positioning",
            word_included_in_next_survey: "This word/image was included in the next survey.",
            request_received: "We have received your request, thank you",
            content_optimization_tooltip: "Copy Optimization is based a your " +
                "Copy Score. The Copy Score is a proxy for the semantic" +
                " association distance between your Copy and your Intended Association." +
                " Copy Score is calibrated on a scale from 0 to 10. High Copy Scores" +
                " indicate a significant likelihood of your text triggering the " +
                "Intended Association. Tip: the shorter your text and the more (and stronger)" +
                " associations with your Intended Association, the higher your Copy Score." +
                " Try to find the right balance between originality and semantic proximity" +
                " relating to your Intended Association by considering alternatives based on the word scores" +
                " suggested in the right window pane when clicking on a word in your text.",
            words_no_more: "You no longer have any unique words left to analyze. " +
                            "The analysis will no longer be updated.",
            document_saved: 'Document successfully saved',
            new_document_created: 'New document created',
            document_opened: 'Document opened',
            document_deleted: 'Document deleted',
            document_delete_confirm: 'Are you sure you want to delete this document?',
            no_documents: 'No saved documents',
            included_next_survey: 'This word/image will be included in the next survey.',
            do_you_want_to_position: 'This word/image is not yet mapped ' +
                    'in our network, if you would like to order this word/image ' +
                    'for positioning, click below.',
            request_positioning: 'Request mapping',
            positioning_success: 'Mapping request for {word} has been received.',
            position_dialog_question: 'Do you want to map {word}?',
            ia_cost: "Requesting this asset's mapping will cost {price} Mindcoins.",
            co_instructions: "Content Tuner A/B instructions",
            exceeds_limit: "Your text must not exceed the character limit.",
            co_tooltip_select_ia: "Please select an implicit association",
            co_tooltip_fill_editor: "Please enter at least one image or text asset"
        },
    },
    nl: {
        message: {
            suggestions_tip: "Na het selecteren van een Gewenste Associatie, begin je met het " +
                            "optimaliseren van jouw tekst door onderstaande associaties te overwegen " +
                            "voor het herschrijven van jouw zinnen.",
            enter_a_text: 'Geef een tekst en een Gewenste Associatie in om jouw eerste optimalisatie te bekomen',
            content_optimization: 'CONTENT OPTIMALISATIE',
            click_forward: "Klik op 'voorwaarts' voor inspirerende associaties " +
                "met het gekozen woord in uw tekst. Als het geselecteerde woord " +
                "in ons netwerk is gepositioneerd, kunt u in de onderstaande " +
                "lijst associaties vinden met hogere scores voor uw Gewenste Associatie." +
                " Overweeg het gebruik van deze hoger scorende woorden door uw " +
                "tekst aan te passen om uw Copy Score te optimaliseren. Klik op " +
                "'achterwaarts' voor inspirerende associaties die het geselecteerde " +
                "woord in het onderbewustzijn van uw publiek triggeren.",
            this_word_image: "Dit woord/deze afbeelding is nog niet gepositioneerd in ons netwerk, als " +
                "u dit woord wilt bestellen voor positionering",
            word_included_in_next_survey: "Dit woord/deze afbeelding werd " +
                                    "opgenomen in de eerstvolgende bevraging.",
            request_received: "We hebben uw verzoek goed ontvangen, waarvoor dank",
            content_optimization_tooltip: "De Copy Optimialisatie is gebaseeerd op uw Copy Score. De Copy Score is " +
                                            "een proxy voor de semantische associatieafstand tussen uw Copy en uw " +
                                            "Gewenste Associatie. De Copy Score is gekalibreerd op een schaal van 0 " +
                                            "tot 10. Hoge Copy Scores geven aan dat de kans groot is dat uw tekst de " +
                                            "Gewenste Associatie activeert. Tip: hoe korter uw tekst en hoe meer (en " +
                                            "sterkere) associaties met uw Gewenste Associatie, hoe hoger uw Copy " +
                                            "Score. Probeer de juiste balans te vinden tussen originaliteit en " +
                                            "'semantische nabijheid' met betrekking tot uw Gewenste Associatie door " +
                                            "alternatieven te overwegen op basis van de woordscores die in het " +
                                            "rechtervenster worden gesuggereerd wanneer u op een woord in uw tekst " +
                                            "klikt.",
            words_no_more: "Je hebt geen unieke woorden meer om te analyseren. De analyse wordt niet " +
                            "meer bijgewerkt.",
            document_saved: 'Document succesvol opgeslagen',
            new_document_created: 'Nieuw document succesvol aangemaakt',
            document_opened: 'Document geopend', 
            document_deleted: 'Document verwijderd', 
            document_delete_confirm: 'Ben je zeker dat je dit document wil verwijderen?', 
            no_documents: 'Nog geen bewaarde documenten', 
            included_next_survey: 'Deze Asset zal gemapped worden tijdens de eerstvolgende survey.', 
            do_you_want_to_position: 'Deze Asset werd nog niet gemapped' +
                    ', indien je deze Asset wil toevoegen ' +
                    'voor mapping, klike hieronder.', 
            request_positioning: 'Map Asset', 
            positioning_success: 'Mapping verzoek goed ontvangen.', 
            position_dialog_question: 'Wil je dit woord mappen?', 
            ia_cost: "Mapping van dit woord bedraagt ", 
            co_instructions: "Content Tuner A/B instructies",
            exceeds_limit: "Je tekst mag de karakter limiet niet overschrijden.",
            co_tooltip_select_ia: "Gelieve een Gewenste Associatie te selecteren",
            co_tooltip_fill_editor: "Gelieve minstens één bestand of keyword/slogan in te geven"
        },
    }
};

export { co_translation };
