<template>
  <LoadingAppWide v-if="loading" />
    <div class="popup-overlay" v-if="visible" @click.self="$emit('exit')">
      
        <div class="popup-container-add">
        <!-- <q-card class="upload-item-card"> -->
            <q-card-section class="row items-center q-pb-none">
                <q-space />
                <q-btn icon="close" flat round dense @click="$emit('exit')" />
            </q-card-section>

            <q-card-section class="upload-info">
                <div class="top-text">{{ $t('new_asset.add_new_item') }}</div>
                <!-- <div class="bottom-text">{{ $t('new_asset.upload_from_computer') }}</div> -->
            </q-card-section>

            <upload-area @fileAdd="onFileAdd"
                :extensions="fileType === 'img' ? ['png', 'jpg', 'jpeg'] : ['mp3', 'mp4']"></upload-area>


                
            <q-list bordered class="rounded-borders upload-list scroll">
                <candidate-asset v-for="file in files" :key="file.uuid" :file="file" :ref="setCandidateAssetRef"
                    @remove="onFileRemove" :fullSize="true">
                </candidate-asset>
            </q-list>

            <!-- <div class="loaders" v-if="loading">
                <span>
                    <loading-spinner></loading-spinner>
                </span>
            </div> -->

            <div class="dialogs" v-if="files.size > 0">
                <p v-if="!allQuestionsAnswered">
                    {{ $t('new_asset.answer_image_questions') }}
                </p>
                <!-- <p v-if="insufficientMindcoins">
                    {{ $t('new_asset.insufficient_credits') }}
                </p>
                <p class="font-weight-bold">
                    {{ $t("new_asset.total_mindcoins", { total: mindcoinTotal }) }}
                </p> -->
            </div>
            <!-- <div class="buttons" v-if="files.size > 0">
                <button class="btngrey" @click="close">
                    {{ $t("message.cancel") }}
                </button>
                <button class="btn3" @click="upload">
                    {{ $t("message.asset_creator_add") }}
                </button>
            </div> -->

            <div class="popup-footer">
                <button class="back-button" @click="$emit('goBackMain')">{{ $t('new_asset.back') }}</button>
                <div v-if="acceptableAssets.length > 0" class="top-text"><font-awesome-icon :icon="['fas', 'info-circle']" class="header-icon" />{{ $t('new_asset.concept_tag') }}</div>
                <button v-if="acceptableAssets.length > 0" class="next-button" :disabled="!areAllQuestionsAnswered()" @click="goToChooseTrack">{{ $t('new_asset.next') }}</button>
            </div>
        <!-- </q-card> -->
    </div>

    </div>



<!-- Google vision api modal -->
 
    <q-dialog v-model="isModalVisible" v-if="imageList.length > 0">
    <q-modal @hide="closeModal">

        <div class="modal-content">
            <q-card-section class="row items-center q-pb-none" style="position: absolute; ">
                <q-space />
                <q-btn icon="close" flat round dense @click="closeModal" />
            </q-card-section>

            <div class="header-text">
                <p>As we survey your concept order <br>
                    Please explore these immediate testing options</p>
            </div>

            <!-- <div v-if="imageList.length === 0">
                <p>No similar concepts found in Mindspeller Library. You will receive an email when your concept has been surveyed and is ready for testing. You can monitor the survey status in the right window pane of My Library when clicking on your concept.</p>
            </div> -->

            <div v-if="imageList.length > 0">
                <div v-for="(image, index) in imageList" :key="index">
                    <div class="image-container">
                        <div class="image-frame"> 
                            <img :src="image.url" alt="Image" class="image">
                        </div>
                        <button class="button_pay" @click="addAsset(image.id, image.display_name)" 
                            :disabled="disabledButtons.includes(image.id)">Add to my library</button>
                    </div>
                </div>
            </div>
        </div>
    </q-modal>
</q-dialog>

</template>

<script>
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import { alert_error, genericDialog, toast_success } from '@/helpers/alert_helper.js';
import UploadArea from "./UploadArea.vue";
import CandidateAsset from "./CandidateAsset.vue";
import LoadingSpinner from '../common/LoadingSpinner.vue';
import LoadingAppWide from '@/components/common/LoadingAppWide.vue';


export default {
  components: {
    CandidateAsset,
    UploadArea,
    LoadingSpinner,
    LoadingAppWide,
  },
  emits: ['resetSearch', 'goBackMain', 'goToChooseTrack', 'exit'],
  data: function () {
    return {
      loading: false,
      visible: false,
      // Using arrays instead of sets
      files: [],
      candidateAssets: [],
      isModalVisible: false,
      imageList: [],
      disabledButtons: [],
      mindcoinTotalValue: 0,
      allQuestionsAnsweredFlag: false,
    }
  },
  props: {
    fileType: {
      type: String,
      required: true,
      default: 'img'
    }
  },
  watch: {
    acceptableAssets: {
      handler(newAssets) {
        this.allQuestionsAnsweredFlag = newAssets.every(asset => {
          const questions = asset.component.questions || {};
          return questions.isBrand !== undefined && questions.type !== undefined && questions.type !== '';
        });
      },
      deep: true, // Track deep changes
      immediate: true, // Run the watcher initially
    },
  },
  computed: {
    acceptableAssets() {
      return this.candidateAssets.filter(ca => ca.component.info.acceptable);
    },
    uploadAllowed() {
      return !this.insufficientMindcoins &&
             this.allQuestionsAnswered &&
             this.acceptableAssets.length > 0;
    },
    insufficientMindcoins() {
      return this.mindcoinTotalValue > this.$store.state.mindcoins;
    },
    allQuestionsAnswered() {
      return this.acceptableAssets.every(asset => {
        const questions = asset.component.questions || {};
        return questions.isBrand !== undefined && questions.tag !== undefined && questions.tag !== null && questions.tag !== '';
    });
  },
  },
  methods: {
        areAllQuestionsAnswered() {
          return this.candidateAssets.every(asset => {
            const questions = asset.component.questions || {};
            return questions.isBrand && questions.type; // Adjust for valid answers
          });
        },
        async addAsset(id, display_name) {
            const dialog_options = {
                title: this.$t("message.buy_asset", {
                    'asset': display_name
                }),
                text: this.$t("message.asset_cost", {
                    'price': 20
                }),
                confirmButtonText: this.$t("message.add"),
                cancelButtonText: this.$t("message.cancel"),
            };
            const dialog_result = await genericDialog(dialog_options);
            if (dialog_result.isConfirmed) {
                try {
                    const response = await axios.post('/api/cas/assets/store/purchase', { 'id': id });
                    this.$store.dispatch('get_mindcoin_balance');
                    toast_success(this.$t('message.purchased_asset'));
                    this.disabledButtons.push(id);
                    // trigger the re-render (search function)
                    this.$emit("resetSearch");
                } catch (error) {
                    if (error.response.status === 422) {
                        alert_error(this.$t('message.insufficient_credits_for_asset'));
                    } else {
                        alert_error(this.$t('message.general_server_error'));
                    }
                }
            }
        },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    open({ files = [], candidateAssets = [] } = {}) {
      // Clear arrays to prevent duplicates
      this.files = [];
      this.candidateAssets = [];

      // Re-add files
      if (files && Array.isArray(files)) {
        this.files = [...files];
      }

      // Re-add candidate assets
      if (candidateAssets && Array.isArray(candidateAssets)) {
        this.candidateAssets = [...candidateAssets];
      }

      this.recalculateMindcoinTotal();
      this.visible = true;
    },
    close() {
      this.candidateAssets = [];
      this.files = [];
      this.visible = false;
    },
    closeVisible() {
      this.visible = false;
    },
    recalculateMindcoinTotal() {
      const total = this.acceptableAssets
        .map(ca => ca.component.info.price)
        .reduce((a, b) => a + b, 0);
      this.mindcoinTotalValue = isNaN(total) ? 0 : total;
    },
    goToChooseTrack() {
      this.recalculateMindcoinTotal();
      this.$emit('goToChooseTrack', {
        acceptableAssets: this.acceptableAssets.map(a => a.component),
        mindcoinsTotal: this.mindcoinTotalValue,
        files: [...this.files],
        candidateAssets: [...this.candidateAssets]
      });
    },
    setCandidateAssetRef(ca) {
      if (!ca) return;
      // Check for duplicates
      if (!this.candidateAssets.some(existing => existing.id === ca.file.uuid)) {
        this.candidateAssets = [...this.candidateAssets, { id: ca.file.uuid, component: ca }];
        this.recalculateMindcoinTotal();
      }
    },
    async onFileAdd(file) {
      file.uuid = uuidv4();
      this.files = [...this.files, file];
      this.recalculateMindcoinTotal();
    },
    onFileRemove(file) {
      // Remove file from files
      this.files = this.files.filter(f => f !== file);
      // Clear candidateAssets since they may reference removed files
      this.candidateAssets = [];
      this.recalculateMindcoinTotal();
    },
    async upload({ totalMindcoins, isConfidential, files, candidateAssets, acceptableAssets, chosenETA }) {
      this.isConfidential = isConfidential;
      this.acceptedAssets = acceptableAssets;
      this.chosenETA = chosenETA;
      this.totalMindcoins = totalMindcoins / (this.acceptedAssets.length || 1);
      this.files = files;
      this.candidateAssets = candidateAssets;

      const dialog_options = {
        title: this.$t('new_asset.upload_selected_asset'),
        text: this.$t('new_asset.this_will_cost', {
          price: this.totalMindcoins * (this.acceptedAssets.length || 1),
        }),
        confirmButtonText: this.$t('message.asset_creator_add'),
        cancelButtonText: this.$t('message.cancel'),
      };

      const dialog_result = await genericDialog(dialog_options);
      if (!dialog_result.isConfirmed) {
        return;
      }

      // Check mindcoins balance
      await this.$store.dispatch('get_mindcoin_balance');
      if (this.$store.state.mindcoins < this.totalMindcoins * (this.acceptedAssets.length || 1)) {
        alert_error(this.$t('new_asset.insufficient_credits'));
        return;
      }

      // Upload files
      for (const ca of this.acceptedAssets) {
        this.$emit('exit');
        const formData = new FormData();
        formData.append('file', ca.file);
        formData.append('display_name', ca.info.displayName);
        formData.append('confidential', this.isConfidential);
        formData.append('mindcoins', this.totalMindcoins);
        formData.append('expedite', this.chosenETA);
        console.log('expedite', this.chosenETA);
        if (ca.info.type === 'image') {
          formData.append('is_brand', ca.questions.isBrand || '0');
          formData.append('tag', ca.questions.type || 'other');
        }

        const axiosConfig = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };

        this.loading = true;
        try {
          const response = await axios.post('/api/cas/assets/upload_new', formData, axiosConfig);
          
          if (response.status === 200) {
            const sorted_data = response.data || null;

            toast_success(`Concept(s) ${ca.info.displayName} uploaded, you will be notified when your concept(s) is analysed`);
            this.$store.dispatch('get_mindcoin_balance');

            // Process valid response data
            if (sorted_data) {
              const url_string = "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wtt_";
              for (const item of sorted_data) {
                this.imageList.push({
                  url: `${url_string}${item.file_identifier}`,
                  display_name: item.display_name,
                  id: item.id,
                });
              }
              this.isModalVisible = true;
            }
          } else {
            console.warn('Unexpected status code:', response.status);
          }

          // Remove uploaded asset
          this.files = this.files.filter(f => f !== ca.file);
          this.candidateAssets = this.candidateAssets.filter(asset => asset !== ca);
        } catch (error) {
          if (error.response && error.response.status === 403 && error.response.data.insufficient_credits) {
            alert_error(this.$t('new_asset.insufficient_credits'));
            break;
          } else if (error.response) {
            console.error('Server error:', error.response);
            alert_error(this.$t('message.general_server_error'));
          } else {
            console.error('Network or unexpected error:', error);
            alert_error(this.$t('message.general_server_error'));
          }
        } finally {
          this.loading = false;
          this.$emit('resetSearch');
        }
      }
    },
  },
}
</script>


<style lang="scss" scoped>
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .popup-container-add {
    position: relative;
    background: #ffffff;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    // height: 85%;
    max-width: 1000px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
  }
.upload-item-card {
    width: 1000px;
    max-width: 80vw;
    height: 800px;
    overflow: hidden;
    display: flex;
    flex-flow: column;
}

.upload-info .bottom-text {
    font-size: 1rem;
    font-family: 'Open Sans';
    font-weight: 500;
    text-align: center;
    margin-top: 20px;
}

.upload-info .top-text {
    font-size: 1.675rem;
    font-family: 'Open Sans';
    font-weight: 500;
    text-align: center;
    text-transform: capitalize;
}

.dialogs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;
}

.dialogs p {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 5px;
    font-weight: bolder;
}

.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.buttons button {
    margin: 20px 5px !important;
}

.upload-list {
    flex: 1;
    overflow-x: hidden;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
}

.q-card__section.q-card__section--vert.row.items-center.q-pb-none {
    position: absolute;
    right: 0;
    z-index: 1;
    top: -11px;
    display: block;
    text-align: revert;
}

.upload-info .top-text {
    font-weight: bold;
    text-transform: capitalize;
}

// The design of the modal
.modal-content {
    max-height: 80vh;
    overflow-y: auto;
    background-color: #f0f0f0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.close-icon {
    font-size: 1.2rem;
    font-weight: bold;
}

.header-text {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-right: 50px;
}

.header-text p {
    margin: 0;
}

.image-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.image-label {
    flex: 1;
    text-align: left;
    font-weight: bold;
    margin-right: 10px;
}

.image-frame {
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-button {
    width: 200px;
    flex-shrink: 0;
    margin-top: 10px;
}

.button_pay {
    padding: 0.5vw 1.0vw;
    background: #0A00FF;
    border-radius: 2vw;
    font-style: normal;
    font-weight: 1000;
    font-size: 0.8vw;
    color: #ffffff !important;
    cursor: pointer;
    text-transform: capitalize;
    transition: all 1s;
}

.button_pay a {
    color: #ffffff !important;
}

.button_pay:hover {
    background-color: #92a0b3;
}

.popup-footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.back-button,
.next-button {
  background: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 15px;
  font-size: 14px;
  cursor: pointer;
  margin-right: 0px; /* Spacing between buttons */
  transition: background 0.3s ease;
}

.next-button {
  /* If you want to differentiate the 'Next' button in some way, you can do it here */
  background: #7878e9; /* Slightly different shade, if desired */
}
.next-button:hover {
  /* If you want to differentiate the 'Next' button in some way, you can do it here */
  background: #3e3e86; /* Slightly different shade, if desired */
}
.back-button:hover {
  /* If you want to differentiate the 'Next' button in some way, you can do it here */
  background: #777777; /* Slightly different shade, if desired */
}

.header-icon{
  margin-right: 10px;
}
</style>