const asset_picker_translation = {
    en: {
        message: {
            choose_from_library: "Choose from Library",
            find_all_asset: "Find a concept",
            find_an_asset: "Find image/media concept",
            nothing_selected: "Nothing selected",
            about_this_asset: "About this Asset",
            all: "All",
            images: "Images",
            words: "Keywords/Slogans",
            multimedia: "Multimedia",
            state: "State",
            type: "Type",
            positioning_date: "Positioning date",
            not_yet_positioned: "This word/image is not yet mapped in our network",
            select_asset_before_adding: "Please select an asset before adding",
        },
    },
    nl: {
        message: {
            choose_from_library: "Kies van Library",
            find_all_asset: "Zoek een concept",
            find_an_asset: "Zoek image/media concept",
            nothing_selected: "Niets geselecteerd",
            about_this_asset: "Asset informatie",
            images: "Afbeeldingen",
            words: "Keywords/Slogans",
            multimedia: "Multimedia",
            state: "Staat",
            type: "Type",
            positioning_date: "Positie datum",
            not_yet_positioned: "Dit woord/deze afbeelding werd nog niet volledig gemapped",
            select_asset_before_adding: "Gelieve een Asset te selecteren om toe te voegen", 
        },
    }
};

export { asset_picker_translation };
