<template>
    <loading-app-wide v-if="loading" />
  <div 
    class="asset" 
    :class="{ selected: isSelected, 'store-asset': storeAsset, 'non-clickable': asset.tag === 'dictionary_word' }" 
    @click="select"
  >
  
    <div class="thumb-container">
      <div class="thumb" v-if="!asset.type_.isMultimedia || asset.type_.isAudio">
        <span class="asset-icon-text">
          {{ asset.display_name }}
        </span>
        <div class="ms-asset-types">
          <font-awesome-icon v-if="asset.type_.isAudio" icon="volume-up" />
        </div>
      </div>
      <div class="thumb" :style="thumbStyle" v-else>
        <div class="ms-asset-types">
          <font-awesome-icon v-if="asset.type_.isVideo" icon="video" />
        </div>
      </div>

      <div v-if="hasMultiplePositionings" class="backdrop"></div>

      <button 
        v-if="isMyLibrary && asset.tag !== 'dictionary_word'" 
        class="open-tools" 
        @click.stop="toggleMenu" 
        :disabled="asset.lifecycle !== 4"
      >
        <font-awesome-icon :icon="['fas', 'bars']" style="color: #0a00ff;" />
      </button>

      <!-- Dollar Icon Toggle -->


      <div 
  v-if="isMyLibrary && asset.lifecycle === 4 && !ratingLoading" 
  class="checkbox-container" 
  @mouseenter="showTooltip = true" 
  @mouseleave="showTooltip = false"
  @click.stop="toggleDollarIcon"
>
  <label 
    class="checkbox-label"
    :class="{
      'filled': trackingAutoTag === 1 && dollarStatus === 1,
      'empty': trackingAutoTag === 1 && dollarStatus === 2,
      'white-icon': trackingAutoTag === 0 && dollarStatus === 1,
      'white-box': trackingAutoTag === 0 && (dollarStatus === 0 || dollarStatus === 2)
    }"
    :style="{
      backgroundColor: trackingAutoTag === 1 && dollarStatus === 2 ? '#7878e9' : ''
    }"
  >
    <font-awesome-icon v-if="trackingAutoTag === 1 && dollarStatus === 1 || trackingAutoTag === 0 && dollarStatus === 1" :icon="currencyIcon" />
  </label>

  <!-- Tooltip -->
  <transition name="fade">
    <div v-if="showTooltip && !overrideTooltip" class="tooltip">
      {{ dollarStatus === 1 
        ? $t('new_asset.dollar_tooltip1')
        : dollarStatus === 2 
        ? $t('new_asset.dollar_tooltip2')
        : $t('new_asset.dollar_tooltip3')
      }}
    </div>
  </transition>

  <!-- Override Tooltip -->
  <transition name="fade">
    <div v-if="overrideTooltip" class="tooltip override-tooltip">
      {{ dollarStatus === 1 
        ? $t('new_asset.dollar_tooltip_overridden1')
        : $t('new_asset.dollar_tooltip_overridden2')
      }}
    </div>
  </transition>
</div>


      <jumpstart 
        v-if="menu" 
        v-model="menu"
        :asset="asset"
        :selectedPositioning="selectedPositioning"
      />
    </div>
  </div>
</template>

<script>
import Jumpstart from './sidebar/Jumpstart.vue';
import LoadingAppWide from '@/components/common/LoadingAppWide.vue';
import { alert_error, genericDialog, toast_success } from '@/helpers/alert_helper.js';
import axios from 'axios';

export default {
  components: { Jumpstart, LoadingAppWide },
  props: {
    asset: { required: true, type: Object },
    isModal: { type: String },
    storeAsset: { required: false, default: false, type: Boolean },
    isMyLibrary: { default: false, type: Boolean }
  },
  emits: ['assetSelected'],
  data() {
    return {
      isSelected: false,
      objectFit: 'cover',
      price: 0,
      menu: false,
      selectedPositioning: {},
      dollarStatus: null, // 0 = Not Eligible, 1 = Eligible, 2 = Disabled by User
      trackingAutoTag: null,
      ratingLoading: false,
      star_rating: 0,
      currentlang: '',
      showTooltip: false,
      overrideTooltip: false,
      loading: false,
    };
  },
  created() {
    this.currentlang = this.$store.getters.getEnvVar('VUE_APP_DEFAULT_LANGUAGE') || 'nl';
  },
  computed: {
    hasMultiplePositionings() {
      return this.asset.positioning_count > 1;
    },
    thumbStyle() {
      let url = '';
      if (this.asset.type_.isImage) {
        url = this.asset.urls.thumb_medium;
      } else if (this.asset.type_.isVideo) {
        url = this.asset.urls.gif;
      }
      return {
        'background-image': `url("${url}")`,
        'background-size': this.objectFit,
        'background-repeat': 'no-repeat',
        'background-position': 'center'
      };
    },
    currencyIcon() {
      return this.currentlang === 'nl' ? ['fas', 'euro-sign'] : ['fas', 'dollar-sign'];
    }
  },
  methods: {
    select() {
      this.$emit('assetSelected', this.asset);
      this.isSelected = true;
    },
    unselect() {
      this.isSelected = false;
    },
    determineObjectFit() {
      if (this.asset.type_.isImage) {
        const image = new Image();
        image.src = this.asset.urls.thumb_medium;
        image.onload = () => {
          const ratio = image.width / image.height;
          this.objectFit = ratio <= 1.5 && ratio >= (1 / 1.5) ? 'cover' : 'contain';
        };
      }
    },
    toggleMenu() {
      this.menu = !this.menu;
    },
    async toggleDollarIcon() {
      if (this.asset.lifecycle !== 4) return;
      // Toggle: If 1 → Change to 2 (Disabled by User)
      this.loading = true;
      const newStatus = this.dollarStatus === 1 ? 2 : 1;
      this.dollarStatus = newStatus;
      if (this.trackingAutoTag !== this.dollarStatus) {
            this.overrideTooltip = true;
      }else{
        this.overrideTooltip = false;
      }
      try {
        const response = await axios.post('/api/cas/assets/toggle-tracking', {
          assetId: this.asset.id,
          status: newStatus
        });
        if (response.status === 200 && response.data.success == true) {
                toast_success(`Your Brand Asset has been successfully refrained from tracking.`);
                this.$store.dispatch('get_mindcoin_balance');
          }
      } catch (error) {
        console.error('Error updating dollar icon status:', error);
      } finally {
        this.loading = false;
      }
    },
    async fetchDollarIconStatus(asset) {
      try {
        const response = await axios.get('/api/get-dollar-icon-status', {
          params: { assetId: asset.id, isBrand: asset.brand}
        });
        if (response.status === 200 && response.data.hasOwnProperty('status')) {
          this.dollarStatus = response.data.status;
          this.trackingAutoTag = response.data.tracking_auto_tag;
          if (this.trackingAutoTag !== this.dollarStatus) {
            this.overrideTooltip = true;
          }
          // Verify fetched status and update if necessary
          // if (this.dollarStatus === 0 && this.star_rating > 3.5 && this.isPublic && this.asset.brand === 1) { 
          //   this.dollarStatus = 1; // Set as eligible
          //   await this.updateAutotagStatus(1); // Sync backend
          // }
          // if (this.dollarStatus === 1 || this.dollarStatus === 2 && this.star_rating < 3.5 || !this.isPublic || this.asset.brand === 0) { 
          //   this.dollarStatus = 0; // Set as ineligible
          //   await this.updateDollarStatus(0); // Sync backend
          // }
        } else {
          this.dollarStatus = 0; // Default to not eligible
        }
      } catch (error) {
        console.error('Error fetching dollar icon status:', error);
        this.dollarStatus = 0;
      }
    },
    async updateDollarStatus(newStatus) {
      try {
        await axios.post('/api/cas/assets/toggle-tracking', {
          assetId: this.asset.id,
          status: newStatus
        });
      } catch (error) {
        console.error('Error updating dollar icon status:', error);
      }
    },
    async updateAutotagStatus(newStatus) {
      try {
        await axios.post('/api/cas/assets/toggle-tracking-auto-tag', {
          assetId: this.asset.id,
          status: newStatus
        });
      } catch (error) {
        console.error('Error updating dollar icon status:', error);
      }
    },
    async fetchRating(asset) {
      this.ratingLoading = true;
      if (asset.asset_mime_type === "image/png"){
      }
      try {
        const response = await axios.get('/api/cas/concept_rating/get_scores', {
          params: { concept_id: asset.id }
        });
        if (response.status === 200) {
          this.star_rating = response.data.star_count.toFixed(2);
          this.isPublic = response.data.is_present_in_store_assets;
          await this.fetchDollarIconStatus(asset); // Fetch dollar status after rating
        }
      } catch (error) {
        console.error('Error fetching rating:', error);
      } finally {
        this.ratingLoading = false;
      }
    }
  },
  async mounted() {
    this.determineObjectFit();
  },
  watch: {
    asset: {
      deep: true,
      immediate: true,
      handler(asset) {
        if (Object.keys(asset).length === 0 || asset.tag === 'dictionary_word') return;
        axios.get(`/api/cas/assets/positionings/${asset.id}`).then(response => {
          this.positioning_surveys = response.data;
          this.selectedPositioning = this.positioning_surveys[0];
        });
        this.fetchRating(asset);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.asset {
  position: relative;
  width: 125px;
  height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  text-align: center;
}

.thumb-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.asset:hover {
  transform: scale(1.05);
}

.asset-icon-text {
  font-size: 0.9rem;
  font-weight: 500;
  text-align: center;
  color: #333;
}

.non-clickable {
  cursor: not-allowed;
  opacity: 0.6;
}

.thumb {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.open-tools {
  position: absolute;
  top: 5px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #333;
}

.open-tools:hover {
  color: #0731ee;
  transform: scale(1.1);
}

/* Dollar Icon */
.checkbox-container {
  position: absolute;
  bottom: 6px;
  right: 10px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.checkbox-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: 1px solid #7878e9;
  border-radius: 4px;
  cursor: pointer;
}

.checkbox-label.disabled {
  opacity: 0.5;
  background-color: #7878e9;
  cursor: not-allowed;
}

/* Case 1: trackingAutoTag === 1 && dollarStatus === 1 */
.filled {
  background-color: #7878e9;
  color: white;
}

/* Case 2: trackingAutoTag === 1 && dollarStatus === 2 (White box, No icon) */
.empty {
  background-color: #7878e9;
  background: #7878e9;
}

/* Case 3: trackingAutoTag === 0 && dollarStatus === 1 (White box, Dollar icon) */
.white-icon {
  background-color: white;
  color: #7878e9;
}

/* Case 4: trackingAutoTag === 0 && dollarStatus === 0 or 2 (Completely White) */
.white-box {
  background-color: white;
  color: transparent;
}

input[type="checkbox"] {
  display: none;
}

/* Custom Tooltip */
.tooltip {
  position: absolute;
  bottom: 0%;
  right: 50%;
  transform: translateX(18%);
  width: 180px; /* Increased width for better readability */
  background: rgba(0, 0, 0, 0.85);
  color: white;
  text-align: center;
  padding: 8px;
  border-radius: 6px;
  font-size: 10px;
  line-height: 1.5;
  white-space: normal;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}

/* Tooltip Show Effect */
.checkbox-container:hover .tooltip {
  opacity: 1;
  visibility: visible;
}

/* Override Tooltip */
.override-tooltip {
  background: rgba(255, 69, 0, 0.85); /* Red color to indicate override */
  font-weight: bold;
}

/* Smooth Fade Transition */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
