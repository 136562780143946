<template>
    <section class="middle-section">
      <div class="container">
        <div class="inspiration-pg library-pg">
          <div class="popup-select-asset">
            <div class="lft-col-md-1">
              <div class="colm-1-top">
                <ul>
                 
                  <li
                    v-bind:class="!active.length ? 'active' : ''"
                    v-on:click="handleActive('mindspeller')"
                  >
                    Mindspeller Library
                  </li>
                   <li
                    v-bind:class="active.length ? 'active' : ''"
                    v-on:click="handleActive('library')"
                  >
                    My Library
                  </li>
                </ul>
              </div>
  
              <div class="top-btn-col">
                <div class="col-btn-md-1">
                  <div v-if="active.length">
                    <MyLibrary
                      @clicked="onClickChild"
                      :onPositioningSelected="onPositioningSelected"
                    />
                  </div>
                  <div v-else>
                    <Mindspeller
                      @clicked="onClickChild"
                      :onPositioningSelected="onPositioningSelected"
                    />
                  </div>
                </div>
              </div>
            </div>
  
            <div class="rgt-col-md-1 scroll">
              <!-- <div class="rightBar scroll"> -->
              <sidebar
                :show_positionings="true"
                :asset="selected_asset"
                :assetType="active === 'my_library' ? assetType : 'store'"
                :showAdditionalOptions="showAdditionalOptions"
                :onPositioningSelected="onPositioningSelected"
                :onStoreAssetsPurchased="onStoreAssetsPurchased"
              >
              </sidebar>
              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import MyLibrary from './myLibrary.vue';
  import Mindspeller from './mindspeller.vue';
  import SearchBar from '../asset_library/SearchBar.vue';
  import Sidebar from '../asset_library/sidebar/Sidebar.vue';
  
  export default {
    emits: ['assetAndPositioningSelected'],
    name: 'Library',
    components: {
      MyLibrary,
      Mindspeller,
      SearchBar,
      Sidebar,
    },
    data() {
      return {
        // active: 'my_library',
         assetType: '',
        selected_asset: {},
      };
    },
    props: {
      active:{
        default:'',
        type:String,
      },
      standalone: {
        default: false,
        type: Boolean,
      },
      suppressVideo: {
        type: Boolean,
        default: false,
      },
      positioned_assets_only: {
        type: Boolean,
        default: false,
      },
      openLibrary: {
        required: false,
        default: false,
        type: Boolean,
      },
    },
    mounted: function() {
      if (this.$props.openLibrary) {
        this.$refs['search-bar'].onAddNewAsset();
      }
    },
    computed: {
      // we only want to show the store in the standalone version (i.e. in
      // the Library app)
      showStore: function() {
        return this.standalone;
      },
      // only enable additional options (such as asset tracking toggle and
      // jumpstart) in the standalone version
      showAdditionalOptions: function() {
        return true;
      },
      showAddAssetButton: function() {
        return this.standalone;
      },
    },
    methods: {
       handleActive: function (value) {
        if(value === 'mindspeller') {
          this.active = ''
        }
        else if (value === 'library') {
          this.active = 'my_library'
        }
        this.selected_asset = {};
      },
      onSearch: async function(filter) {
        this.filter = filter;
      },
      onClickChild(value1, value2) {
        this.selected_asset = value1;
        this.assetType = value2;
      },
  
      onPositioningSelected: function(positioning) {
        this.selected_positioning = positioning;
        this.$emit(
          'assetAndPositioningSelected',
          this.selected_asset,
          this.selected_positioning
        );
      },
      onStoreAssetsPurchased: function(asset) {
        this.$refs['store-grid'].reFetchAssets(asset);
        if (this.$refs['search-bar'].assetState == 'being_positioned') {
          this.selected_asset = {};
          return;
        } else {
          this.$refs['asset-grid'].addAsset(asset);
          this.selected_asset = {};
          return;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .rightBar.scroll {
      max-height: 100vh !important;
      overflow-x: hidden;
      margin-bottom: 50px;
      padding-top: 8vw;
  }
  
  .lft-col-md-1 {
    height: 100vh;
     padding-top: 7vw !important;
  }
  
  .library-pg .popup-select-asset .rgt-col-md-1 {
    height: 100vh;
  }
  
  .rgt-col-md-1 {
    padding-top: 8vw;
  }
  </style>
  