<template>
    <div class="button_book_tour" @click="bookTour">
      <font-awesome-icon :icon="['fas', 'envelope']" class="icon" />
      <a href="https://www.mindspeller.com/contact/" target="_blank">Contact Us</a>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Tour',
    methods: {
      bookTour() {
        window.open("https://www.mindspeller.com/contact/", "_blank");
      }
    }
  };
  </script>
  
  <style scoped>
  .button_book_tour {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    font-size: large;
    font-weight: 700;
    padding: 10px;
    box-sizing: border-box;
    background-color: #7878e9; /* Background color for the box */
    border: 1px solid #ccc; /* Border for the box */
    border-radius: 10px; /* Rounded corners for the box */
    cursor: pointer;
    transition: all 1s;
  }
  
  .button_book_tour a {
    color: #ffffff !important;
    text-decoration: none; /* Remove underline from the link */
  }
  
  .icon {
    width: 40px;
    margin-right: 10px;
    color: #fff;
  }
  </style>