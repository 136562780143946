<template>
    <div class="customizebutton-wrapper">
      <!-- Customize Button -->
      <button class="customize-button" @click="openCustomizePopup">
      MY NETWORK
      </button>
  
      <!-- Step 1: Customize Popup -->
      <CustomizePopup 
        v-if="showCustomizePopup" 
        @exit="closeCustomizePopup" 
        @openPricingPopup="openPricingPopup" 
      />
  
      <!-- Step 2: Pricing Popup -->
      <PricingPopup 
        v-if="showPricingPopup" 
        @exit="closePricingPopup" 
        @openSummaryPopup="openSummaryPopup"
        @back="goBackToCustomize" 
      />
  
      <!-- Step 3: Summary Popup -->
      <SummaryPopup 
        v-if="showSummaryPopup" 
        @exit="closeSummaryPopup" 
        @back="goBackToPricing"
        @redirectToSubscription="handleSubscribe"
        :assetCount="summaryData.assetCount"
        :setupFee="summaryData.setupFee"
        :totalCharge="summaryData.totalCharge"
        :totalMindcoins="summaryData.totalMindcoins"
    />
    </div>
  </template>
  
  <script>
  import CustomizePopup from "./CustomizePopup.vue";
  import PricingPopup from "./PricingPopup.vue";
  import SummaryPopup from "./SummaryPopup.vue";
  import Credits from '@/components/header/Credits.vue';
  
  export default {
    name: "CustomizeButton",
    components: { CustomizePopup, PricingPopup, SummaryPopup, Credits },
    data() {
      return {
        showCustomizePopup: false,
        showPricingPopup: false,
        showSummaryPopup: false,
        summaryData: {}
      };
    },
    methods: {
      openCustomizePopup() {
        this.showCustomizePopup = true;
      },
      closeCustomizePopup() {
        this.showCustomizePopup = false;
      },
  
      openPricingPopup() {
        this.showCustomizePopup = false; // Close CustomizePopup
        this.showPricingPopup = true;
      },
      closePricingPopup() {
        this.showPricingPopup = false;
      },
  
      openSummaryPopup(data) {
        this.showPricingPopup = false; // Close PricingPopup
        this.showSummaryPopup = true;
        this.summaryData = data;    
      },
      closeSummaryPopup() {
        this.showSummaryPopup = false;
      },
  
      // Navigation Backwards
      goBackToCustomize() {
        this.showPricingPopup = false;
        this.showCustomizePopup = true;
      },
      goBackToPricing() {
        this.showSummaryPopup = false;
        this.showPricingPopup = true;
      },

      handleSubscribe() {
        this.$refs.credits.handleSubscribe();
      }
    }
  };
  </script>
  
  <style scoped>
  /* Wrapper for Customize Button */
  .customizebutton-wrapper {
    margin-bottom: 10px;
  }
  
  /* Customize Button */
  .customize-button {
    background-color: #7878e9;
    color: white;
    border: none;
    padding: 18px 50px;
    white-space: nowrap;
    cursor: pointer;
    border-radius: 18px;
    font-size: 20px;
    font-weight: 600;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    text-align: center;
    z-index: 3;
    margin-top: 10px;
  }
  
  .customize-button:hover {
    background-color: #454583;
    transform: translateY(-2px);
  }
  </style>
  