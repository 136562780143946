<template>
  <add-new-asset-dialog ref="add-new-asset-dialog" @resetSearch="resetSearch">
  </add-new-asset-dialog>
  <search-bar
    @search="onSearch"
    ref="search-bar"
    :allowed_types="['all', 'images', 'words', 'multimedia']"
    :positioned_assets_only="positioned_assets_only"
    :standalone="standalone"
    :popup_picker="popup_picker"
    :suppress-video="suppressVideo"
    :totalAssets="totalAssets"
    :totalDictWords="totalDictWords"
    :total_assets="total_assets"
    :disable_tracking="disable_tracking"
    :libraryKey="libraryKey"
    @wordSelected="handleWordSelected"
    @resetSearch="resetSearch"
  >
  </search-bar>
  <div class="Show-col-asst">
    <asset-grid
      ref="asset-grid"
      :filter="filter"
      :showAddAssetButton="showAddAssetButton"
      @assetClick="onAssetClick"
      @addNewAsset="onAddNewAsset"
      :libraryKey="libraryKey"
      :onCountTotalAssets="onCountTotalAssets"
      :isModal="isModal"
    >
    </asset-grid>
    <store-grid
      v-if="showStore"
      ref="store-grid"
      :filter="filter"
      @storeAssetClick="onStoreAssetClick"
    >
    </store-grid>
  </div>
</template>

<script>
import AddNewAssetDialog from '@/components/add_asset/AddNewAssetDialog.vue';
import AssetGrid from './AssetGrid.vue';
import SearchBar from './SearchBar.vue';
import StoreGrid from './StoreGrid.vue';
import AssetCreator from '../asset_library/AssetCreator.vue';

export default {
  components: {
    AssetGrid,
    SearchBar,
    StoreGrid,
    AddNewAssetDialog,
    AssetCreator,
  },
  // emits this event in case this component is embedded in another one (e.g.
  // AssetPicker)
  emits: ['assetAndPositioningSelected', 'clicked', 'wordSelected'],

  props: {
    standalone: {
      default: false,
      type: Boolean,
    },
    popup_picker: {
      default: false,
      type: Boolean,
    },
    show_positionings: {
      default: false,
      type: Boolean,
    },
    suppressVideo: {
      type: Boolean,
      default: false,
    },
    positioned_assets_only: {
      type: Boolean,
      default: false,
    },
    libraryKey: {
      type: String,
      default: false,
    },
    isModal: {
      type: String,
    },
    disable_tracking:{
      type:Boolean,
      default:false
    }
  },

  data: function() {
    return {
      filter: {},
      selected_asset: {},
      selected_positioning: {},
      // assetType can be 'owned', 'store' or '' (if no asset is selected)
      assetType: '',
      showAddAsset: false,
      totalAssets: 0,
      totalDictWords: 0,
      total_assets: 0,
    };
  },

  computed: {
    // we only want to show the store in the standalone version (i.e. in
    // the Library app)
    showStore: function() {
      return this.standalone;
    },
    // only enable additional options (such as asset tracking toggle and
    // jumpstart) in the standalone version
    showAdditionalOptions: function() {
      return this.standalone;
    },
    showAddAssetButton: function() {
      return this.standalone;
    },
  },

  methods: {
    onAddNewAsset: function() {
      this.$refs['add-new-asset-dialog'].open();
    },

    onSearch: async function(filter) {
      this.filter = filter;
    },
    onAssetClick: function(asset) {
      this.showAddAsset = true;
      this.resetStoreSelection();
      this.selected_asset = asset;
      this.assetType = 'owned';
      this.$emit(
        'assetAndPositioningSelected',
        this.selected_asset,
        this.selected_positioning
      );
      this.$emit('clicked', this.selected_asset, this.assetType);
    },
    onStoreAssetClick: function(asset) {
      this.$refs['asset-grid'].resetSelection();
      this.selected_asset = asset;
      this.assetType = 'store';
    },

    resetSelection: function() {
      this.$refs['asset-grid'].resetSelection();
      this.resetStoreSelection();
    },
    resetStoreSelection: function() {
      if (this.showStore) {
        this.$refs['store-grid'].resetSelection();
      }
    },
    resetSearch: function() {
      this.$refs['asset-grid'].resetSearch();
    },
    onAssetStateChanged: function(val) {
      this.$refs['search-bar'].onAssetStateChanged(val);
    },
    onCountTotalAssets: function(val, word_val, total_assets) {
      this.totalAssets = val;
      this.totalDictWords = word_val;
      this.total_assets = total_assets;
    },
    handleWordSelected(selectedWord) {
      this.$emit('wordSelected', selectedWord);
      
    },
  },
};
</script>

<style lang="scss" scoped>
.lft-col-md-1 {
  width: 700px;
}
</style>
