<template>
  <loading-app-wide v-if="loading" />
  <div>
    
    <add-asset
      ref="add-multimedia-asset-modal"
      :fileType="fileType"
      @goToChooseTrack="handleGoToChooseTrack"
      @resetSearch="$emit('resetSearch')"
      @goBackMain="handleGoBackMain"
      @exit = "closeAll"
    >
    </add-asset>
    <!-- Render ChooseTrack if showChooseTrack is true -->
    <choose-track
    ref="choose-track"
      @goToCheckoutbox="handleGoToCheckoutbox"
      @goBack="handleGoBackFromChooseTrack"
      @resetSearch="$emit('resetSearch')"
      @openCalendar=""openCalendar
      @exit = "closeAll"
    ></choose-track>
    <checkoutbox
    ref="checkout-box"
      @exit = "closeAll"
      @goBack="handleGoBackFromCheckoutbox"
      @upload="openUpload"
      @resetSearch="$emit('resetSearch')">
    </checkoutbox>
    <add-text-asset
      ref="add-text-asset-modal"
       @textUploadTriggered="resetFilterBarType"
      @goToChooseTrack="handleGoToChooseTrack"
      @resetSearch="$emit('resetSearch')"
      @goBackMain="handleGoBackMain"
      @exit = "closeAll"
    >
    </add-text-asset>
    <!-- <Calendar ref="calendar" @exitCalendar="exitCalendar"></Calendar> -->
    <q-dialog v-model="visible">
      <q-card class="selector-card">
        <q-card-section class="row items-center q-pb-none">
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section>
          <div class="top-text">{{ $t('new_asset.add_new_item') }}</div>
        </q-card-section>

        <div class="panes">
          <div class="pane-wrapper left">
            <div class="pane">
              <div class="big-button">
                <q-btn size="48px" round dense @click="addTextAsset">
                  <span class="circular-icon">
                    <img src="@/assets/icons/upload.svg" />
                  </span>
                </q-btn>
              </div>
              <div class="bottom-info">
                <div class="icons">
                  <img class="sm-icon" src="@/assets/icons/align-left.svg" />
                  <img class="sm-icon" src="@/assets/icons/type.svg" />
                </div>
                <div style="text-align: center;">
                  {{ $t('new_asset.add_words') }}
                  <br>
                  {{word_price}} Mindcoins
                </div>
              </div>
            </div>
          </div>
          <div class="pane-wrapper">
            <div class="pane">
              <div class="big-button">
                <q-btn
                  size="48px"
                  round
                  dense
                  @click="addMultimediaAsset('img')"
                >
                  <span class="circular-icon">
                    <img src="@/assets/icons/upload.svg" />
                  </span>
                </q-btn>
              </div>
              <div class="bottom-info">
                <div class="icons">
                  <img class="sm-icon" src="@/assets/icons/image-thick.svg" />
                </div>
                <div style="text-align: center;">
                  {{ $t('new_asset.upload_image') }}
                  <br>
                  {{image_price}} Mindcoins
                </div>
              </div>
            </div>
          </div>
          <div class="pane-wrapper right">
            <div class="pane">
              <div class="big-button">
                <q-btn
                  size="48px"
                  round
                  dense
                  @click="addMultimediaAsset('file')"
                >
                  <span class="circular-icon">
                    <img src="@/assets/icons/upload.svg" />
                  </span>
                </q-btn>
              </div>
              <div class="bottom-info">
                <div class="icons">
                  <!-- <img class="sm-icon" src="@/assets/icons/image-thick.svg"/> -->
                  <img class="sm-icon" src="@/assets/icons/film.svg" />
                  <img class="sm-icon" src="@/assets/icons/music.svg" />
                </div>
                <div style="text-align: center;">
                  {{ $t('new_asset.upload_multimedia') }}
                  <br>
                  {{av_price}} Mindcoins
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!addTextOpened" class="supported-assets-message">
          <font-awesome-icon :icon="['fas', 'info-circle']" />
          {{ $t('new_asset.add_new_asset_tooltip') }} 
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>


<script>
import AddAsset from '@/components/add_asset/AddAsset.vue';
import ChooseTrack from './ChooseTrack.vue';
import AddTextAsset from '@/components/add_asset/AddTextAsset.vue';
import Checkoutbox from './Checkoutbox.vue';
import LoadingAppWide from '@/components/common/LoadingAppWide.vue';
import Calendar from '@/components/header/Calendar.vue';



export default {
  components: { AddAsset, AddTextAsset, ChooseTrack, Checkoutbox, LoadingAppWide, Calendar },
  emits: ['resetSearch'],
  data() {
    return {
      visible: false,
      fileType: '',
      loading: false,
      word_price: null,
      image_price: null,
      av_price: null,
      selectedAssets: [],
      mindcoinsTotal: 0,
      totalCost: 0,
      storedCandidateAssets: [],
      storedFiles: [],
      // NEW: Keep track of the last opened popup or modal.
      // Possible values: 'add-multimedia-asset-modal', 'add-text-asset-modal', 'choose-track', 'checkout-box'
      lastOpenedPopup: null,
      addTextOpened: false,
    };
  },
  created() {
    this.word_price = this.$store.getters.get_price('private_word_asset')
    this.image_price = this.$store.getters.get_price('private_image_asset')
    this.av_price = this.$store.getters.get_price('private_av_asset')
  },
  methods: {
    open() {
      // Check if there is a memory of the last opened popup
      if (this.lastOpenedPopup) {
        // If we have a recorded last opened modal, open it directly
        // along with any stored data it needs.
        switch (this.lastOpenedPopup) {
          case 'add-multimedia-asset-modal':
          this.addTextOpened = false;
            this.$refs['add-multimedia-asset-modal'].open({ files: this.storedFiles, candidateAssets: this.storedCandidateAssets });
            break;
          case 'add-text-asset-modal':
            this.$refs['add-text-asset-modal'].open({ files: this.storedFiles, candidateAssets: this.storedCandidateAssets });
            this.addTextOpened = true;
          break;
          case 'choose-track':
            this.$refs['choose-track'].open();
            break;
          case 'checkout-box':
            this.$refs['checkout-box'].open({ files: this.storedFiles, mindcoinsTotal: this.mindcoinsTotal, acceptableAssets: this.selectedAssets  });
            break;
          default:
            // If it's not recognized, just open the default dialog
            this.visible = true;
        }
      } else {
        // If no memory is stored, open the default top-level dialog
        this.visible = true;
      }
    },
    close() {
      this.visible = false;
    },
    openCalendar(){
      this.$refs['calendar'].toggleCalendar();
    },
    exitCalendar(){
      this.$refs['calendar'].toggleCalendar();
    },
    addMultimediaAsset(value) {
      this.fileType = value;
      this.addTextOpened = false;
      this.lastOpenedPopup = 'add-multimedia-asset-modal'; // Store memory of this modal
      this.$refs['add-multimedia-asset-modal'].open();
      this.selectedAssets = [];
      this.mindcoinsTotal = 0;
      this.storedFiles = [];
      this.storedCandidateAssets = [];
      this.close();
    },
    addTextAsset() {
      this.lastOpenedPopup = 'add-text-asset-modal'; // Store memory of this modal
      this.addTextOpened = true;
      this.$refs['add-text-asset-modal'].open();
      this.selectedAssets = [];
      this.mindcoinsTotal = 0;
      this.storedFiles = [];
      this.storedCandidateAssets = [];
      this.close();
    },
    handleGoToChooseTrack({ acceptableAssets, mindcoinsTotal, files, candidateAssets }) {
      // Store assets and go to choose track
      this.selectedAssets = acceptableAssets;
      this.mindcoinsTotal = mindcoinsTotal;
      this.storedFiles = files;
      this.storedCandidateAssets = candidateAssets;
      if(this.addTextOpened){
        this.$refs['add-text-asset-modal'].closeVisible();
      }else{
        this.$refs['add-multimedia-asset-modal'].closeVisible();
      }

      this.lastOpenedPopup = 'choose-track'; // Update memory
      this.$refs['choose-track'].open();
    },
    handleGoBackFromChooseTrack() {
      // Go back to AddAsset modal
      this.$refs['choose-track'].close();
      if(this.addTextOpened){
        this.lastOpenedPopup = 'add-text-asset-modal';
        this.$refs['add-text-asset-modal'].open({ files: this.storedFiles, candidateAssets: this.storedCandidateAssets });
      }else{
        this.lastOpenedPopup = 'add-multimedia-asset-modal';
        this.$refs['add-multimedia-asset-modal'].open({ files: this.storedFiles, candidateAssets: this.storedCandidateAssets });
      }
    },
    handleGoBackMain() {
      // Go back to the initial dialog
      if(this.addTextOpened){
        this.$refs['add-text-asset-modal'].close();
      }else{
        this.$refs['add-multimedia-asset-modal'].close();
      }
      this.lastOpenedPopup = null; // Reset memory since we are going back to the main dialog
      this.open();
    },
    handleGoToCheckoutbox() {
      // Move from choose track to checkout box
      if(this.addTextOpened){
        this.$refs['add-text-asset-modal'].close();
      }else{
        this.$refs['add-multimedia-asset-modal'].close();
      }
      this.$refs['choose-track'].close();

      this.lastOpenedPopup = 'checkout-box'; // Update memory
      this.$refs['checkout-box'].open({files: this.storedFiles, mindcoinsTotal: this.mindcoinsTotal, acceptableAssets: this.selectedAssets });
    },
    handleGoBackFromCheckoutbox() {
      // Go back to choose track
      this.$refs['checkout-box'].close();
      this.lastOpenedPopup = 'choose-track'; // Update memory
      this.$refs['choose-track'].open();
    },
    closeAll(){
      this.$refs['checkout-box'].close();
      this.$refs['choose-track'].close();
      this.$refs['add-multimedia-asset-modal'].close();
      this.$refs['add-text-asset-modal'].close();
      // this.addTextOpened = false;
      this.close();
    },
    openUpload({totalMindcoins, isConfidential, chosenETA}){
      this.totalCost = totalMindcoins;
      this.isConfidential = isConfidential;
      this.chosenETA = chosenETA;
      this.loading = true;
      if(this.addTextOpened){
        this.$refs['add-text-asset-modal'].upload({totalMindcoins:this.totalCost, isConfidential:this.isConfidential, files: this.storedFiles, candidateAssets: this.storedCandidateAssets, acceptableAssets: this.selectedAssets, chosenETA: this.chosenETA});
      }else{
        this.$refs['add-multimedia-asset-modal'].upload({totalMindcoins:this.totalCost, isConfidential:this.isConfidential, files: this.storedFiles, candidateAssets: this.storedCandidateAssets, acceptableAssets: this.selectedAssets, chosenETA: this.chosenETA});
      }
      this.loading = false;
      this.selectedAssets = [];
      this.mindcoinsTotal = [];
      this.storedFiles = [];
      this.storedCandidateAssets = [];
      this.totalCost = [];
      this.isConfidential = [];
      this.chosenETA = [];
      this.addTextOpened = false;
      this.lastOpenedPopup = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.q-dialog__inner--minimized > div {
  max-width: initial;
}
.selector-card {
  width: calc(100vw * 0.4);
  min-width: 600px;
  min-height: 350px;
  height: auto;
  overflow: unset;
  max-height: initial;
}
.top-text {
  font-size: 1.675rem;
  font-family: 'Open Sans';
  font-weight: 500;
  // text-transform: capitalize;
  text-align: center;
}
.panes {
  display: flex;
  height: 60%;
      width: 95%;
    margin: 0 auto;
}
.panes .left {
  justify-content: flex-end;
}
.panes .right {
  justify-content: flex-start;
}
.pane-wrapper {
  display: flex;
  align-items: center;
}
.big-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom-info div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.circular-icon {
  width: 48px;
  height: 48px;
  background-color: #000aff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.icons {
  margin: 10px 0 0 0;
  flex-basis: 100%;
}
.description {
  text-transform: capitalize;
}
.sm-icon {
  margin: 0 5px 0 5px;
  height: 20px;
  width: 20px;
}

.q-card__section.q-card__section--vert.row.items-center.q-pb-none {
  position: absolute;
  right: 0;
  z-index: 1;
  top: -11px;
  display: block;
  text-align: revert;
}

.panes {
    display: flex;
    height: 60%;
    justify-content: space-between;
    align-items: flex-start;
}

.panes div {
    width: 100%;
}
.supported-assets-message {
  text-align: center;
  font-size: 13px;
  color: #000000;
  margin-top: 20px;
  padding: 10px;
  border-top: 1px solid #eee;
  font-weight: 500;
}
.supported-assets-message .warning-icon {
  color: #ff9800; /* A warm orange color for the warning icon */
  margin-right: 8px;
}
</style>
