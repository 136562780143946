<template>
  <div>
    <div v-if="isLoading" class="loading-overlay">
      <div class="loading-spinner"></div>
    </div>
    <survey-side-bar
      @ai-tool-clicked="controlpopup"
      @reset-clicked="resetTable"
      @no-idea-clicked="setNoIdea"
      @delete-clicked="setDeleted"
    />
    <div
      v-if="!visible"
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
      "
    >
      <input
        type="text"
        class="form-control"
        placeholder="survey id"
        aria-label="surveyid"
        aria-describedby="basic-addon1"
        v-model="surveyId"
        style="width: 10vw;"
      />
      <input
        type="text"
        class="form-control"
        placeholder="token"
        aria-label="surveyid"
        aria-describedby="basic-addon1"
        v-model="trimmedToken"
        style="width: 30vw;"
      />
      <div>
        <q-btn
          @click="search"
          class="btn"
          type="button"
          style="margin-right: 10px;"
          >Search</q-btn
        >
      </div>
    </div>
    <p v-if="searchClicked" class="welcome-text">
      Review the human associations entered after exposure to your concept.
      Assess if they require editing, deletion or removal.
    </p>
    <div style="margin-top: 50px;" id="container"></div>
    <!-- Buttons Section -->
    <div v-if="searchClicked" class="button-container">
      <div class="button-row">
        <div class="tooltip-container">
        <button @click="controlpopup" title="Open Popup" class="openpopup-button">
            <font-awesome-icon :icon="['fas', 'brush']" style="color: #ffffff; margin-right: 10px;" />
            AI Tool
        </button>
        <div class="tooltip-text">
          Use the AI Tool to analyze and flagging potential typos, 'No Idea' entries, and more.
        </div>
        </div>
    <div class="tooltip-container">
        <button
          @click="showResetDialog = true"
          class="reset-button"
        >
          <font-awesome-icon
            :icon="['fas', 'sync-alt']"
            style="color: #ffffff; margin-right: 10px;"
          />Reset
        </button>
        <div class="tooltip-text">
          This button will reset all changes made to the table and reload the original data.
        </div>
        </div>
        <div class="tooltip-container">
        <button
          @click="showNoIdeaDialog = true"
          class="no-idea-button"
          :disabled="!anyAssociationSelected"
        >
          <font-awesome-icon
            :icon="['fas', 'question']"
            style="color: #ffffff; margin-right: 10px;"
          />No Idea
        </button>
        <div class="tooltip-text">
          This button will delete all selected responses. Use it when responses are invalid or irrelevant.
        </div>
        </div>
        <div class="tooltip-container">
        <button
          @click="showDeleteDialog = true"
          class="delete-button"
          :disabled="!anyAssociationSelected"
        >
          <font-awesome-icon
            :icon="['fas', 'trash-alt']"
            style="color: #ffffff; margin-right: 10px;"
          />Delete
        </button>
        <div class="tooltip-text">
          This button will label all selected responses as 'No Idea'. Use it to group variations like '??', '/', 'dsjfkm', 'no idea whatsoever', 'I don’t know', '123', 'xyz', etc.
        </div>
        </div>
      </div>
    </div>

    <q-dialog v-model="showPopup" persistent class="popup-dialog">
      <div class="popup-outer">

        <a class="close-btn" @click="closePopup">
          <img :src="require('@/assets/times-light.svg')" width="12" height="19" />
        </a>
        <div class="popup-container">
          <div class="popup-header">
              <h2>Review words tagged by the AI Tool</h2>
            </div>
        <div class="content-flex">
          <div class="table-wrapper">

            <table id="popup-example" class="scrollable-popup-table">
              <thead class="sticky-header" style="z-index: auto; top: 0;">
                <tr class="header-row">
                  <th style="width: 20%;">Raw Survey Associations</th>
                  <th style="width: 5%;">Count</th>
                  <th style="width: 50%;">Action</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(item, index) in popupData" :key="index">
                  <tr>
                    <td style="white-space: nowrap; overflow: auto;">
                      <input v-model="item.rawWord" @input="updateSelectedTypo(item)" @change="handleTypoSelection(item, item.rawWord)" />
                    </td>
                    <td style="white-space: normal;">{{ item.countR }}</td>
                    <td>
                      <div class="action-boxes">
                        <label>
                          <input
                            type="checkbox"
                            :checked="item.deleteAction"
                            @change="handleCheckboxChange(item, 'delete')"
                          />
                          Delete
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            :checked="item.noIdeaAction"
                            @change="handleCheckboxChange(item, 'noIdea')"
                          />
                          No Idea
                        </label>

                        <label>
                          <input
                            type="checkbox"
                            :checked="item.typoAction"
                            :disabled="item.disableTypo"
                            @change="handleCheckboxChange(item, 'typo')"
                          />
                          Typo
                        </label>

                        <span
                          class="info-icon"
                          v-if="item.memorytypo && !anySuggestionSameAsRaw"
                          @mouseover="item.showInfo = true"
                          @mouseleave="item.showInfo = false"
                        >
                          <font-awesome-icon
                            :icon="['fas', 'exclamation-triangle']"
                          />
                          <div v-if="item.showInfo" class="info-box">
                            <b>This word has been flagged as a typo by the AI.</b>
                          </div>
                        </span>
                        <span
                          class="info-icon"
                          v-if="item.memorynoIdea && !item.memorytypo"
                          @mouseover="item.showInfo = true"
                          @mouseleave="item.showInfo = false"
                        >
                          <font-awesome-icon
                            :icon="['fas', 'exclamation-triangle']"
                          />
                          <div v-if="item.showInfo" class="info-box">
                            <b>This word has been flagged as a no-idea by the AI.</b>
                          </div>
                        </span>
                        <span
                          class="info-icon"
                          v-if="item.memorynoIdea && item.memorytypo"
                          @mouseover="item.showInfo = true"
                          @mouseleave="item.showInfo = false"
                        >
                          <font-awesome-icon
                            :icon="['fas', 'exclamation-triangle']"
                          />
                          <div v-if="item.showInfo" class="info-box">
                            <b>This word has been flagged as gibberish by the AI.</b>
                            <small> (Check for typo options)</small>
                          </div>
                        </span>
                        <span
                          class="check-icon"
                          v-if="!item.memorynoIdea && !item.memorytypo && !item.memorydelete"
                          @mouseover="item.showInfo = true"
                          @mouseleave="item.showInfo = false"
                        >
                          <font-awesome-icon :icon="['fas', 'check-circle']" />
                          <div v-if="item.showInfo" class="info-box">
                            <b>This word has been flagged relevant by the AI.</b>
                          </div>
                        </span>
                      </div>

                      <div
                        v-if="
                          item.showTypoOptions && item.typoSuggestions.length > 0
                        "
                        class="typo-options"
                      >
                        <label
                          v-for="(suggestion, typoIndex) in item.typoSuggestions"
                          :key="typoIndex"
                        >
                          <input
                            type="radio"
                            :name="'typo_' + index"
                            :value="suggestion"
                            v-model="item.selectedTypo"
                            @change="handleTypoSelection(item, suggestion)"
                          />
                          {{ suggestion }}
                        </label>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>

          <!-- Stimulus Image/Video/Audio Display Section -->
          <div class="big-box">
            <!-- First row: Stimulus content -->
            <div v-if="stimulus" class="stimulus-content">
              <img v-if="stimulus && stimulus.type.isImage" :src="stimulus.src" alt="Stimulus Image" @load="imageLoaded = true" @error="imageLoaded = false">
              
              <div v-if="stimulus.type.isVideo">
                <video controls>
                  <source :src="stimulus.src" :type="stimulus.mime">
                </video>
              </div>
              
              <div v-if="stimulus.type.isAudio">
                <audio controls>
                  <source :src="stimulus.src" :type="stimulus.mime">
                </audio>
              </div>
              
              <div v-if="stimulus" style="text-align: center; font-size: 15px;">
                {{ stimulus.word }}
              </div>
            </div>
            
            <!-- Second row: Buttons -->
            <div class="popup-footer">
              <div class="tooltip-container">
              <button @click="closePopup" class="btnpop btn-closepopup" >         
              <div class="tooltip-text">
                This will close the popup with the tags saved unless the browser session is closed or reloaded.
              </div>CLOSE</button>
              </div>
              <div class="tooltip-container">
              <button @click="submitPopup" class="btnpop btn-submit">        
              <div class="tooltip-text">
                This will push the tags to the main table where further changes can be made.
              </div>SUBMIT</button>
              </div>
              <div class="tooltip-container">
              <button @click="resetPopupSelections" class="btnpop btn-reset">        
              <div class="tooltip-text">
                This will reset all the changes and return to the tags generated by the AI.
              </div>RESET</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
        <!-- Move buttons below stimulus section -->
        
    </q-dialog>


    <!-- Table Section -->
    <div v-if="searchClicked" class="table-container">
      <table id="example" class="display" style="width: 100%;">
        <!-- Table Headers -->
        <thead v-if="searchClicked" class="sticky-header-main">
          <tr class="header-row">
            <th style="width: 1%;"></th>
            <th style="white-space: normal;">Raw Survey Associations</th>
            <th style="width: 5%;">Count</th>
            <th style="white-space: normal;">Edited Survey Associations</th>
            <th style="width: 5%;">Count</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(word, index) in currentDataTable" :key="index">
            <tr :class="{ 'alternate-row': word % 2 === 1 }">
              <td style="text-align: center;">
                <input type="checkbox" v-model="word.selected" />
              </td>
              <td style="white-space: normal;">{{ word.rawWord }}</td>
              <td style="white-space: normal;">{{ word.countR }}</td>
              <td
                @click="openEditModal(index)"
                :style="{
                  color:
                    word.editedWord.toLowerCase() === 'no idea'
                      ? '#ff8800'
                      : word.editedWord.toLowerCase() === 'deleted'
                      ? '#ff0400'
                      : word.suggestionApplied
                      ? '#10760e'
                      : word.isEdited
                      ? '#10760e'
                      : '',
                }"
                contenteditable="true"
              >
                {{ word.editedWord }}
              </td>
              <td
                :style="{
                  color:
                    word.editedWord.toLowerCase() === 'no idea'
                      ? '#ff8800'
                      : word.editedWord.toLowerCase() === 'deleted'
                      ? '#ff0400'
                      : '',
                }"
              >
                {{ word.countE }}
              </td>
            </tr>
          </template>
        </tbody>

        <!-- Table Footer -->
        <tfoot>
          <!-- This section can be left empty if you don't want the "Showing x to y of z entries" footer -->
        </tfoot>
      </table>
    </div>



    <!-- Modal for editing -->
    <div v-if="editingIndex !== null" class="modal">
      <div class="modal-content" style="display: flex; gap: 20px;">
        <div>
          <button @click="closeEditModal" class="close-button">X</button>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            gap: 1px;
            justify-content: center;
            align-items: center;
          "
        >
          <input
            v-model="editedWord"
            ref="editInput"
            class="edit-input"
            @keyup.enter="submitEditedWords"
            :disabled="!currentDataTable[editingIndex].editable"
            autofocus
          />
          <button
            @click="submitEditedWords"
            class="submit-button"
            :disabled="!currentDataTable[editingIndex].editable"
          >
            Submit
          </button>
        </div>
      </div>
    </div>

    <!-- Reset Dialog -->
    <div v-if="showResetDialog" class="dialog-background">
      <div class="dialog">
        <p>Are you sure you want to reset?</p>
        <div class="dialog-buttons">
          <button @click="resetTable">Yes</button>
          <button @click="showResetDialog = false">No</button>
        </div>
      </div>
    </div>

    <!-- No Idea Dialog -->
    <div v-if="showNoIdeaDialog" class="dialog-background">
      <div class="dialog">
        <p>Are you sure you want to set all selected rows to "No Idea"?</p>
        <div class="dialog-buttons">
          <button @click="setNoIdea">Yes</button>
          <button @click="showNoIdeaDialog = false">No</button>
        </div>
      </div>
    </div>

    <!-- Delete Dialog -->
    <div v-if="showDeleteDialog" class="dialog-background">
      <div class="dialog">
        <p>Are you sure you want to delete all selected rows?</p>
        <div class="dialog-buttons">
          <button @click="setDeleted">Yes</button>
          <button @click="showDeleteDialog = false">No</button>
        </div>
      </div>
    </div>

    <q-dialog v-model="showErrorPopup">
      <div class="error-popup">
        <p>
          An error occurred while fetching responses. This may be due to a network issue or an internal error. Please try again by clicking the "Retry" button below.
        </p>
        <p>
          If this issue persists please reload the page.
        </p>
        <q-btn label="Retry" @click="retryFetchingResponses" class="btn-retry" />
      </div>
    </q-dialog>
    <!-- submit and save-->
    <div
      v-if="searchClicked"
      style="display: flex; justify-content: center; margin-top: 20px;"
    >
      <!-- submit -->
      <q-btn
        label="submit"
        @click="openPopupSubmit"
        style="margin-right: 30px;"
      />
      <q-dialog v-model="showSubmitPopup">
        <div
          class="popup"
          style="background-color: white; border-radius: 8px; padding: 20px;"
        >
          <!-- Text you want to add inside the dialog -->
          <p style="font-weight: bolder;">Warning:</p>
          <p>
            Submission is irreversible (the sumbitted data will be used to
            position the concept in the network). Strictly do not submit the same concept more than once.
          </p>
          <p style="font-style: italic;">
            Please confirm that you have organisation superadmin authorization
            to perform this operation
          </p>
          <!-- Buttons inside the popup -->
          <div style="text-align: right;">
            <q-btn
              label="Cancel"
              @click="closePopupSubmit"
              class="btn btn-cancel"
              style="margin-right: 10px;"
            />
            <q-btn
              label="Submit"
              @click="submitSurvey"
              class="btn btn-confirm"
            />
          </div>
        </div>
      </q-dialog>


      <!-- save -->
      <!-- <q-btn label="save" @click="openPopupSave" style="margin-right: 30px;" />
      <q-dialog v-model="showSavePopup">
        <div
          class="popup"
          style="background-color: white; border-radius: 8px; padding: 20px;"
        >

          <p style="font-weight: bolder;">Warning:</p>
          <p>
            Saving is irreversible (you will no longer be able to access the
            page).
          </p>
          <p style="font-style: italic;">
            Please confirm that you have organisation superadmin authorization
            to perform this operationa
          </p>

          <div style="text-align: right;">
            <q-btn
              label="Cancel"
              @click="closePopupSave"
              class="btn btn-cancel"
              style="margin-right: 10px;"
            />
            <q-btn label="Save" @click="saveSurvey" class="btn btn-confirm" />
          </div>
        </div>
      </q-dialog> -->
    </div>

    <!-- footer pagination -->
    <div v-if="searchClicked" class="q-pa-lg flex flex-center">
      <q-pagination
        v-model="current"
        :max="page"
        direction-links
        @update:model-value="handlePaginationInput"
      />
    </div>
    <div class="auto-save-info">
        <p>
          <i class="info-icon"></i>  Your tagging progress for each concept is automatically saved in your browser. You can navigate between pages, clean each concept, and submit your changes all-together only once.
          <strong>Data will be lost if you refresh or close the browser session.</strong>
        </p>
        <p><strong>You are obliged to finish cleaning all the concepts before clicking submit for a streamlined process.</strong></p>
      </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import axios from "axios";
import { toast_success } from "@/helpers/alert_helper.js";
import $ from "jquery";
import "datatables.net";
import "datatables.net-rowgroup";
import FullScreenPreviewSurveyCleaning from "@/components/common/FullScreenPreviewSurveyCleaning";

export default {
  components: { FullScreenPreviewSurveyCleaning},
  props: {
        word: {
            required: true,
            type: String,
        },
        survey: {
            required: true,
            type: Object,
        },

    },

  setup() {
    return {
      current: ref(1),
    };
  },
  data() {
    return {
      searchClicked: false,
      showPopup: false,
      visible: false,
      isLoading: false,
      selectedOption: "en",
      currentDataTable: [],
      currentDataTableToSend: [],
      token: "",
      envVars: {},
      editedWords: [],
      highChartCatagories: [],
      highChartSeries: [],
      resultList: [],
      removeList: [],
      adaptList: [],
      noIdeaList: [],
      currentAssociations: [],
      showDeletePopup: false,
      showSubmitPopup: false,
      showSavePopup: false,
      showNoIdeaPopup: false,
      popupInput: "",
      currentStimulus: "",
      CurrentStimulusName: "",
      currentIndex: null,
      totalCount: null,
      page: null,
      stimulusData: [],
      error: null,
      surveyId: null,
      editingIndex: null,
      editedWord: "",
      originalUneditedWord: "",
      table: $("#example").DataTable({
        language: {
          emptyTable: " ",
        },
        searching: null,
        info: null,
        ordering: null,
        processing: null,
        paging: null,
        lengthChange: null,
        columnDefs: [{ targets: "_all", visible: true }],
        order: [[3, "desc"]],
        rowGroup: {
          startRender: function (rows, group) {
            let count = rows
              .data()
              .pluck(3)
              .map((val) => parseInt(val, 10))
              .reduce((acc, val) => acc + val, 0);
            return;
          },
          endRender: null,
          dataSrc: 0,
        },
      }),
      popupTable: null,
      showResetDialog: false,
      showNoIdeaDialog: false,
      showDeleteDialog: false,
      anyAssociationSelected: false,
      disabledPages: [],

      popupData: [],
      typoSuggestionList: [],
      popUpRemoveList: [],
      popUpNoIdeaList: [],
      popUpTypoList: [],
      initialpopupData: [],
      showInfo: false,
      stimuli: null,
      stimulus: null,
      src: null,
      envVars: {},
      imageLoaded: false,
      chartInstance: null,
      cachedBackendData: [],
      isFetching: false,
      showErrorPopup: false,
    };
  },
  mounted() {
    window.addEventListener("beforeunload", this.clearLocalStorage);
    this.$nextTick(() => {
      //this.renderDataTable();
    });
  },
  watch: {
    currentDataTable: {
      immediate: true,
      deep: true,
      handler(newValue) {
        // Call checkAssociationSelection to update button states
        this.checkAssociationSelection();
        // Save currentDataTable to local storage
        localStorage.setItem(
          `survey-${this.surveyId}-stimulus-${this.currentStimulus}`,
          JSON.stringify(newValue)
        );
      },
    },
    // Watcher for removeList
    removeList: {
      deep: true,
      handler(newValue) {
        localStorage.setItem(
          `survey-${this.surveyId}-stimulus-${this.currentStimulus}-removeList`,
          JSON.stringify(newValue)
        );
      },
    },
    // Watcher for adaptList
    adaptList: {
      deep: true,
      handler(newValue) {
        localStorage.setItem(
          `survey-${this.surveyId}-stimulus-${this.currentStimulus}-adaptList`,
          JSON.stringify(newValue)
        );
      },
    },
    // Watcher for noIdeaList
    noIdeaList: {
      deep: true,
      handler(newValue) {
        localStorage.setItem(
          `survey-${this.surveyId}-stimulus-${this.currentStimulus}-noIdeaList`,
          JSON.stringify(newValue)
        );
      },
    },
  },
  beforeDestroy() {
    if (this.chartInstance) {
      this.chartInstance.destroy();
      this.chartInstance = null;
    }
  },
  computed: {
    type_name: function () {
      if (this.stimulus) {
          return this.getTypeName(this.stimulus.type);
      }
      else {
          return '......';
      }
    },
    trimmedToken: {
      get() {
        return this.token.trim();
      },
      set(value) {
        this.token = value.trim();
      },
    },
  },
  created: function () {
    this.loadEnvVar("VUE_APP_API");
  },
  methods: {
  
    clearLocalStorage() {
      // Create an array to store the keys to be removed
      const keysToRemove = [];

      // Iterate over all keys in localStorage
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        // Check if the key starts with 'survey-{this.surveyId}-stimulus-'
        if (key.startsWith(`survey-${this.surveyId}-stimulus-`)) {
          keysToRemove.push(key);
        }
      }

      // Remove the keys
      for (const key of keysToRemove) {
        localStorage.removeItem(key);
      }
    },
    handleCommentChange(item, commentType) {
        if (!item) return;

        // Reset initial state
        item.disableTypo = false;
        item.disableDelete = false;
        item.disableNoIdea = false;
        item.deleteAction = false;
        item.typoAction = false;
        item.noIdeaAction = false;

        // Check typo suggestions
        var anySuggestionSameAsRaw = item.typoSuggestions && item.typoSuggestions.some(
          function(s) {
            return s.toLowerCase() === item.rawWord.toLowerCase();
          }
        );

        if (anySuggestionSameAsRaw) {
          item.typo = false;
          item.memorytypo = false;
          item.disableTypo = true;
          item.deleteAction = false;
          item.memorydelete = false;
          if(item.memorynoIdea){
            item.noIdeaAction = true;
          }
          return;
        }

        const isMemoryMode = !Array.isArray(this.cachedBackendData) || 
                            this.cachedBackendData.length === 0;

        switch(commentType) {
          case "typo":
            if ((isMemoryMode && item.memorytypo) || (!isMemoryMode && item.typo)) {
              item.disableDelete = false;
              item.disableNoIdea = false;
              item.typoAction = true;
              item.showTypoOptions = true;
              item.noIdeaAction = false;
            }
            break;

          case "noIdea":
            if ((isMemoryMode && item.memorynoIdea && !item.memorytypo) || (!isMemoryMode && item.noIdea && !item.typo)) {
              item.noIdeaAction = true;
              item.deleteAction = false;
              item.disableTypo = true;
              item.showTypoOptions = false;
            } else if ((isMemoryMode && item.noIdea && item.memorytypo)) {
              item.noIdeaAction = true;
              item.deleteAction = false;
              item.typoAction = false;
              item.disableTypo = false;;
              item.showTypoOptions = false;
            }
            break;

          case "delete":
            if ((isMemoryMode && item.delete) || (!isMemoryMode && item.delete)) {
              item.deleteAction = true;
              item.disableTypo = !item.memorytypo;
              item.typoAction = false;
              item.noIdeaAction = false;
              item.showTypoOptions = false;
            }
            break;

          case "default":
            if (isMemoryMode || !isMemoryMode){
              item.deleteAction = false;
              item.noIdeaAction = false;
              item.showTypoOptions = false;
              item.disableTypo = true;
            }
            
        }

        // Apply memory mode specific rules
        if (isMemoryMode && item.memorynoIdea && item.memorytypo || !isMemoryMode && item.noIdea && item.typo) {
          item.noIdeaAction = false;
          item.deleteAction = true;
          item.disableTypo = false;
          item.typoAction = false;
          item.showTypoOptions = false;
        }

        if (isMemoryMode && !item.memorynoIdea && !item.memorytypo && !item.delete || !isMemoryMode && !item.noIdea && !item.typo  && !item.delete) {
          item.noIdeaAction = false;
          item.deleteAction = false;
          item.disableTypo = true;
          item.typoAction = false;
          item.showTypoOptions = false;
        }
      },

    handleCheckboxChange(item, checkboxType) {

        this.isCurrentlyChecked = item[checkboxType + "Action"];
        item.deleteAction = false;
        item.noIdeaAction = false;
        item.typoAction = false;
        item.showTypoOptions = false;
        if (!this.isCurrentlyChecked) {
          switch (checkboxType) {
            case "delete":
              item.deleteAction = true;
              item.noIdeaAction = false;
              item.typoAction = false;
              break;
            case "noIdea":
              item.deleteAction = false;
              item.noIdeaAction = true;
              item.typoAction = false;
              break;
            case "typo":
              item.deleteAction = false;
              item.noIdeaAction = false;
              item.typoAction = true;
              item.showTypoOptions = true;
              break;
          }
        }

      
      this.updateCachedBackendData();
        
    },

  handleTypoSelection(item,suggestion) {
    item.selectedTypo = suggestion;
    item.typoAction = true;
    item.showTypoOptions = true;
    this.cachedBackendData = this.popupData.map((item) => ({
      rawWord: item.rawWord,
      countR: item.countR,
      comment: item.comment,
      wordsIds: item.wordsIds || [],
      delete: item.delete || false,
      memorynoIdea: item.memorynoIdea || false,
      memorytypo: item.memorytypo || false,
      deleteAction: item.deleteAction || false,
      noIdeaAction: item.noIdeaAction || false,
      typoAction: item.typoAction || false,
      selectedTypo: item.selectedTypo || '', 
      typoSuggestions: item.typoSuggestions || [],
      popUpeditedWord: item.selectedTypo || item.rawWord,
    }));
  },
  updateCachedBackendData() {
    this.cachedBackendData = this.popupData.map((item) => ({
      rawWord: item.rawWord,
      countR: item.countR,
      comment: item.comment,
      wordsIds: item.wordsIds || [],
      delete: item.delete || false,
      memorynoIdea: item.memorynoIdea || false,
      memorytypo: item.memorytypo || false,
      deleteAction: item.deleteAction || false,
      noIdeaAction: item.noIdeaAction || false,
      typoAction: item.typoAction || false,
      selectedTypo: item.selectedTypo || '', 
      typoSuggestions: item.typoSuggestions || [],
      popUpeditedWord: item.selectedTypo || item.rawWord,
    }));
  },
    toggleTypoOptions(item) {
      item.showTypoOptions = item.typoAction;
    },
    updateSelectedTypo(item) {
      item.selectedTypo = item.rawWord;
    },
    setComment(item, comment) {
      item.comment = comment;
    },
    checkAssociationSelection() {
      this.anyAssociationSelected = this.currentDataTable.some(
        (item) => item.selected
      );
    },
    prepareChartData() {
        this.highChartCatagories = [];
        this.highChartSeries = [];
        let noIdeaCount = 0;
        const countMap = new Map();

        this.currentDataTable.forEach((item) => {
            if (item.countE !== "-") {
                const word = item.editedWord.toLowerCase(); 
                if (word === 'no idea') {
                    noIdeaCount += parseInt(item.countE);
                } else {
                    if (countMap.has(word)) {
                        countMap.set(word, countMap.get(word) + parseInt(item.countE));
                    } else {
                        countMap.set(word, parseInt(item.countE));
                    }
                }
            }
        });

        countMap.forEach((count, word) => {
            this.highChartCatagories.push(word);
            this.highChartSeries.push(count);
        });

        if (noIdeaCount > 0) {
            this.highChartCatagories.push('no idea');
            this.highChartSeries.push(noIdeaCount);
        }

        if (this.renderChart) {
            this.renderChart();
        }
    },
    refreshChart() {
      if (this.chartInstance && this.chartInstance.forExport) { 
        this.chartInstance.destroy();
      }
      this.renderChart(); 
    },
    renderDataTable() {
      this.table.clear(false).draw();
      this.currentDataTable.forEach((data) => {
        this.table.row
          .add([data.rawWord, data.countR, data.editedWord, data.countE])
          .draw(true);
      });
    },
    renderChart() {

          if (this.chartInstance) {
            this.chartInstance.destroy();
            this.chartInstance = null;
          }

          const options = {
            chart: {
              type: "column",
              renderTo: 'container'
            },
            title: {
              text: "Edited Survey Result",
            },
            xAxis: {
              categories: this.highChartCatagories.flat(),
              crosshair: true,
            },
            yAxis: {
              min: 0,
              title: {
                text: "Number of Answers",
              },
            },
            series: [
              {
                name: "Edited Survey Answers",
                data: this.highChartSeries.flat(),
              },
            ],
          };

          this.chartInstance = Highcharts.chart(options);
        },
    prepareCurrentDataTable: async function (currentDataTable) {
      this.showPopup = false;
      this.highChartCatagories = [];
      this.highChartSeries = [];

      // Check if data for the currentStimulus is available in local storage
      const storedData = localStorage.getItem(
        `survey-${this.surveyId}-stimulus-${this.currentStimulus}`
      );
      if (storedData) {
        // Load data from local storage
        this.currentDataTable = JSON.parse(storedData);
      } else {
        // Prepare currentDataTable as usual
        currentDataTable.length = 0;
        this.wordId = [];
        this.resultList.forEach((item) => {
          this.wordId.push(item.Association_id);
        });
        this.currentAssociations.forEach((item) => {
          currentDataTable.push({
            rawWord: item[1],
            countR: item[2],
            editedWord: item[1],
            countE: item[2],
            editable: true,
            selected: false,
            wordsIds: item[0],
          });
        });
      }
      this.currentDataTableToSend = this.currentDataTable;
      this.prepareChartData();
      this.searchClicked = true;
      this.renderDataTable();
      this.renderChart();
    },

    fetchstimulusData: async function(id, token) {
        try {
            const responsestimulus = await axios.post(`/surveys/assets_source?id=${id}&token=${token}`, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            this.stimuli = responsestimulus.data;
            
            this.imageLoaded = false;
            this.stimulus = this.stimuli.find(item => item.word_id === Number(this.currentStimulus));
            
          } catch (error) {
            this.error = error.message;
            console.log(this.error);
        }
    },
    getTypeName: function (type) {
        if (type.isImage) {
            return this.$t("message.image").toLowerCase();
        }
        if (type.isAudio) {
            return this.$t("message.audio").toLowerCase();
        }
        if (type.isVideo) {
            return this.$t("message.video").toLowerCase();
        }
        else {
            return this.$t("message.word").toLowerCase();
        }
    },

    controlpopup: async function(){

      if (Array.isArray(this.cachedBackendData) && this.cachedBackendData.length > 0) {
        console.log(this.cachedBackendData);
      this.preparePopup2();

      } else {
        await this.preparePopup(this.currentDataTable);
      }

    },
    preparePopup: async function(currentDataTable) {
      if (this.isFetching) {
        return;
      }

      this.isFetching = true;
      this.isLoading = true;

      const assetresponsestimulus = await axios.post(`/surveys/assets_source?id=${this.surveyId}&token=${this.token}`, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            this.assetstimuli = assetresponsestimulus.data;
            
            this.imageLoaded = false;
            this.assetstimulus = this.assetstimuli.find(item => item.word_id === Number(this.currentStimulus));

      const words = this.currentDataTableToSend.map((item) => item.rawWord);
      const context_words = this.currentDataTableToSend.map((item) => item.rawWord);
      const asset_name = this.assetstimulus;
      try {
        const sendDataBE = { words: words, context_words: context_words, asset_name};
        const responseBE = await axios.post(
          "api/cas/survey_cleaning/analyze_gibberish_no_idea",
          sendDataBE,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
       
        if (
          !responseBE.data ||
          !Array.isArray(responseBE.data) ||
          responseBE.data.length < 2
        ) {
          console.error("Invalid or empty response data:", responseBE.data);
          this.showErrorPopup = true;
          this.errorMessage = "An error occurred while fetching responses. This may be due to a network issue or an internal error. Please try again by clicking the 'Retry' button below.";
          return;
        }
        if (responseBE.data && Array.isArray(responseBE.data)) {
          this.popupData = responseBE.data.map((item) => ({
            item,
            wordsIds: item.wordsIds || [],
            noIdea: false,
            memorynoIdea: false,
            typo: false,
            memorytypo: false,
            deleteAction: false,
            noIdeaAction: false,
            typoAction: false,
          }));
        } else {
          console.error(
            "Failed to fetch or invalid data format:",
            responseBE.data
          );
        }


      this.wordId = [];
      this.popupData = [];
      this.resultList.forEach((item) => {
        this.wordId.push(item.Association_id);
      });
      this.currentAssociations.forEach((item) => {
        this.popupData.push({
          rawWord: item[1],
          countR: item[2],
          comment: item[3],
          wordsIds: item[0] || [],
          isCurrentlyChecked: false,
          gibberish: false,
          noIdea: false,
          memorynoIdea: false,
          typo: false,
          memorytypo:false,
          delete: false,
          deleteAction: false,
          noIdeaAction: false,
          typoAction: false,
          showTypoOptions: false,
          selectedTypo: '',
          disableDelete: false,
          disableNoIdea: false,
          disableTypo: false,
          isTypoUpdated: false,
          suggestionApplied: false,
          anySuggestionMatchesRaw: false,
        });
      });

      this.fetchstimulusData(this.surveyId, this.token);
      this.$nextTick(() => {
        this.openPopup();
      });
      console.log(responseBE.data);
    

        let noIdeaMap = new Map();
        let typoMap = new Map();
        let typoSuggestionsMap = new Map();


        if (responseBE.data[0] && responseBE.data[0].noidea) {
          responseBE.data[0].noidea.forEach((item) => {
            noIdeaMap.set(item.word, item.result === "Yes");
          });
        }


        if (responseBE.data[1] && responseBE.data[1].typo) {
          responseBE.data[1].typo.forEach((item) => {
            typoMap.set(item.word, item.isTypo);
            typoSuggestionsMap.set(item.word, item.suggestions || []);
          });
        }


        this.popupData.forEach((item) => {
          item.noIdea = noIdeaMap.get(item.rawWord) == true || false;
          item.memorynoIdea = noIdeaMap.get(item.rawWord) == true || false;
          item.typo = typoMap.get(item.rawWord) == true || false;
          item.memorytypo = typoMap.get(item.rawWord) == true || false;
          item.typoSuggestions = typoSuggestionsMap.get(item.rawWord) || [];

          });
        this.popupData.forEach((item) => {
          if (!Array.isArray(item.wordsIds)) {
            item.wordsIds = [];
          }
        });

        this.initialpopupData = this.popupData.map((item) => ({
          id: item.wordsIds,
          delete: item.delete,
          noIdea: item.memorynoIdea,
          typo: item.memorytypo,
        }));
        this.popupData.forEach((item) => {
          // First handle typo state as it might affect noIdea
            if (item.noIdea && item.typo){
              this.handleCommentChange(item, "delete");
            }
            if (!item.noIdea && !item.typo) {
              this.handleCommentChange(item, "default");
            }

            if (item.noIdea && !item.typo) {
              this.handleCommentChange(item, "noIdea");
            }
            else{
              this.handleCommentChange(item, "typo");
            }
            
        });
      } catch (error) {
        console.error("Failed to send data:", error);
      } finally {
          this.isLoading = false;
          this.isFetching = false;
        }
    },
    
    retryFetchingResponses() {
      this.showErrorPopup = false; // Close the error popup
      this.preparePopup(this.currentDataTable);
    },

    preparePopup2(){
    try{
      this.wordId = [];
      this.popupData = [];
      this.resultList.forEach((item) => {
        this.wordId.push(item.Association_id);
      });
      this.currentAssociations.forEach((item) => {
        this.popupData.push({
          rawWord: item[1],
          countR: item[2],
          comment: item[3],
          wordsIds: item[0] || [],
          isCurrentlyChecked: false,
          showTypoOptions: false,
          disableDelete: false,
          disableNoIdea: false,
          disableTypo: false,
          showTypoOptions: false,
          suggestionApplied: false,
          anySuggestionMatchesRaw: false,
          selectedTypo: '',
        });
      });

      this.fetchstimulusData(this.surveyId, this.token);
      this.$nextTick(() => {
        this.openPopup();
      })
      this.popupData = this.cachedBackendData.map(item => ({
      rawWord: item.rawWord,
      countR: item.countR,
      comment: item.comment,
      wordsIds: item.wordsIds || [],
      delete: item.delete || false,
      memorynoIdea: item.memorynoIdea || false,
      memorytypo: item.memorytypo || false,
      typo: item.memorytypo || false,
      deleteAction: item.deleteAction || false,
      noIdeaAction: item.noIdeaAction || false,
      typoAction: item.typoAction || false,
      selectedTypo: item.selectedTypo || '',
      typoSuggestions: item.typoSuggestions || [],
    }));

    this.initialpopupData = this.popupData.map((item) => ({
          id: item.wordsIds,
          delete: item.delete,
          noIdea: item.memorynoIdea,
          typo: item.memorytypo,
        }));

    this.popupData.forEach((item) => {
      if (!Array.isArray(item.wordsIds)) {
        item.wordsIds = [];
      }
    });
    this.popupData.forEach((item) => {
      let assocIds = item.wordsIds; 


      assocIds.forEach(id => {
        if (item.deleteAction) {
          item.delete = true;
          item.typoAction = false;
          item.showTypoOptions = false;
          console.log("deleted", item.rawWord);
          this.handleCommentChange(item, "delete", id);
        }
        if (item.noIdeaAction) {
          item.noIdea = true;
          item.typoAction = false;
          item.showTypoOptions = false;
          this.handleCommentChange(item, "noIdea", id);
        }
        if (item.typoAction) {
          item.typo=true;
          item.showTypoOptions = true;
          this.handleCommentChange(item, "typo", id);
        }
        if(!item.selectedTypo) {
          item.editedWord = item.rawWord;
          item.isEdited = false;
        }
        if (!item.deleteAction && !item.noIdeaAction && !item.typoAction && !item.memorytypo) {
        item.delete = false;
        item.noIdea = false;
        item.typo = false;
        item.disableTypo = true;
        this.handleCommentChange(item, "default", id);
      }
      });


    });
    
  } catch (error) {
        console.error("Failed to send data:", error);
      }
    },

    openPopup() {
      this.showPopup = true;
      console.log("popupdata before sort", this.popupData);
      this.popupData = this.popupData.map((data) => ({
        ...data,
        priority: data.noIdeaAction || data.typoAction || data.deleteAction ? 1 : 0, // Add priority
      }));
      this.popupData.sort((a, b) => b.priority - a.priority);
      console.log("popupdata after sort", this.popupData);
      this.popupTable = $("#popup-example").DataTable({
        language: {
          emptyTable: " ",
        },
        searching: null,
        info: null,
        ordering: null,
        processing: null,
        paging: null,
        lengthChange: null,
        columnDefs: [{ targets: "_all", visible: true },{targets: [0],visible: false,}],
        // order: [[14, "desc"]],
        rowGroup: {
          startRender: function (rows, group) {
            let count = rows
              .data()
              .pluck(2)
              .map((val) => parseInt(val, 10))
              .reduce((acc, val) => acc + val, 0);
            return;
          },
          endRender: null,
          dataSrc: 0,
        },
      });
      this.popupData.forEach((data) => {
        this.popupTable.row
          .add([
            data.priority,
            data.rawWord,
            data.countR,
            data.comment,
            data.deleteAction,
            data.noIdeaAction,
            data.typoAction,
          ])
          .draw(false);
      });
      this.showPopup = true;
    },
    closePopup() {
      if (
        this.popUpRemoveList.length > 0 ||
        this.popUpNoIdeaList.length > 0 ||
        this.popUpTypoList.length > 0
      ) {
        this.showPopup = false;
        this.popupData = [];
        this.popupTable = null;
        this.foundRemove = [];
        this.foundNoIdea = [];
        this.typoData = [];

        // Retrieve the lists from localStorage
        let storedRemoveList = JSON.parse(
          localStorage.getItem(
            `survey-${this.surveyId}-stimulus-${this.currentStimulus}-removeList`
          )
        ) || [];

        let storedNoIdeaList = JSON.parse(
          localStorage.getItem(
            `survey-${this.surveyId}-stimulus-${this.currentStimulus}-noIdeaList`
          )
        ) || [];

        let storedAdaptList = JSON.parse(
          localStorage.getItem(
            `survey-${this.surveyId}-stimulus-${this.currentStimulus}-adaptList`
          )
        ) || [];

        this.currentDataTable.forEach((item) => {
          let foundRemove = this.popUpRemoveList.includes(item.wordsIds[0]);
          let foundNoIdea = this.popUpNoIdeaList.includes(item.wordsIds[0]);
          let typoData = this.popUpTypoList.find(
            (typo) => typo.id === item.wordsIds[0]
          );

          if (foundRemove) {
            // Update the word in currentDataTable
            item.editedWord = "Deleted";
            item.countE = "-";
            item.editable = false;
            item.isTypoUpdated = false;
            item.suggestionApplied = false;

            // For each Association_id associated with the word
            item.wordsIds.forEach((associationId) => {
              // Remove from storedNoIdeaList if it exists
              const indexInNoIdea = storedNoIdeaList.findIndex(
                (entry) => entry.Association_id === associationId
              );
              if (indexInNoIdea !== -1) {
                storedNoIdeaList.splice(indexInNoIdea, 1);
              }

              // Remove from storedAdaptList if it exists
              const indexInAdaptList = storedAdaptList.findIndex(
                (entry) => entry.Association_id === associationId
              );
              if (indexInAdaptList !== -1) {
                storedAdaptList.splice(indexInAdaptList, 1);
              }

              // Add to storedRemoveList if not already present
              const existsInRemoveList = storedRemoveList.some(
                (entry) => entry.Association_id === associationId
              );
              if (!existsInRemoveList) {
                storedRemoveList.push({
                  Association_id: associationId,
                  Association: item.rawWord,
                });
              }
            });
          } else if (foundNoIdea) {
            // Update the word in currentDataTable
            item.editedWord = "No idea";
            item.editable = false;
            item.isTypoUpdated = false;
            item.suggestionApplied = false;

            // For each Association_id associated with the word
            item.wordsIds.forEach((associationId) => {
              // Remove from storedRemoveList if it exists
              const indexInRemoveList = storedRemoveList.findIndex(
                (entry) => entry.Association_id === associationId
              );
              if (indexInRemoveList !== -1) {
                storedRemoveList.splice(indexInRemoveList, 1);
              }

              // Remove from storedAdaptList if it exists
              const indexInAdaptList = storedAdaptList.findIndex(
                (entry) => entry.Association_id === associationId
              );
              if (indexInAdaptList !== -1) {
                storedAdaptList.splice(indexInAdaptList, 1);
              }

              // Add to storedNoIdeaList if not already present
              const existsInNoIdeaList = storedNoIdeaList.some(
                (entry) => entry.Association_id === associationId
              );
              if (!existsInNoIdeaList) {
                storedNoIdeaList.push({
                  Association_id: associationId,
                  Association: item.rawWord,
                });
              }
            });
          } else if (typoData) {
            // Update the word in currentDataTable
            item.editedWord = typoData.selectedTypo;
            item.suggestionApplied = typoData.selectedTypo !== item.rawWord;
            item.isEdited = item.suggestionApplied;
            item.editable = true;
            item.countE = item.countR;

            // For each Association_id associated with the word
            item.wordsIds.forEach((associationId) => {
              // Remove from storedRemoveList if it exists
              const indexInRemoveList = storedRemoveList.findIndex(
                (entry) => entry.Association_id === associationId
              );
              if (indexInRemoveList !== -1) {
                storedRemoveList.splice(indexInRemoveList, 1);
              }

              // Remove from storedNoIdeaList if it exists
              const indexInNoIdeaList = storedNoIdeaList.findIndex(
                (entry) => entry.Association_id === associationId
              );
              if (indexInNoIdeaList !== -1) {
                storedNoIdeaList.splice(indexInNoIdeaList, 1);
              }

              // Add to storedAdaptList
              storedAdaptList.push({
                Association_id: associationId,
                Adapt: item.editedWord,
                Association: item.rawWord,
              });
            });

            // Remove duplicates from storedAdaptList
            storedAdaptList = storedAdaptList.filter(
              (item, index, self) =>
                index ===
                self.findIndex(
                  (t) => t.Association_id === item.Association_id
                )
            );
          } else {
            item.editedWord = item.rawWord;
            item.countE = item.countR;
          }
        });

        // Save the updated lists back to localStorage
        localStorage.setItem(
          `survey-${this.surveyId}-stimulus-${this.currentStimulus}-removeList`,
          JSON.stringify(storedRemoveList)
        );

        localStorage.setItem(
          `survey-${this.surveyId}-stimulus-${this.currentStimulus}-noIdeaList`,
          JSON.stringify(storedNoIdeaList)
        );

        localStorage.setItem(
          `survey-${this.surveyId}-stimulus-${this.currentStimulus}-adaptList`,
          JSON.stringify(storedAdaptList)
        );

        // Update the UI
        this.searchClicked = true;
        this.prepareChartData();
        this.renderDataTable();
        this.renderChart();
        this.stimulus = false;
        this.popUpRemoveList = [];
        this.popUpNoIdeaList = [];
        this.popUpTypoList = [];
        this.isEdited = false;
      } else {
        this.showPopup = false;
      }
    },

    submitPopup() {
      for (let i = 0; i < this.currentDataTable.length; i++) {
        this.currentDataTable[i].suggestionApplied = false;
        this.currentDataTable[i].isEdited = false;
      }
      this.typoList = [];
      this.popUpRemoveList = [];
      this.popUpNoIdeaList = [];
      this.popUpTypoList = [];
      this.popupData.forEach((item) => {
        if (item.deleteAction) {
          (item.wordsIds || []).forEach((wordId) => {
            this.popUpRemoveList.push(wordId);
          });
        }
        if (item.noIdeaAction) {
          (item.wordsIds || []).forEach((wordId) => {
            this.popUpNoIdeaList.push(wordId);
          });
        }
        if (item.typoAction && item.selectedTypo) {
          item.wordsIds.forEach((wordId) => {
            const selectedTypo = item.selectedTypo || item.rawWord;
            this.popUpTypoList.push({ id: wordId, selectedTypo: selectedTypo });
          });
        }
      });
      this.currentDataTable.forEach(item => {
          const popupChange = this.popupData.find(popupItem => 
              popupItem.wordsIds.some(id => item.wordsIds.includes(id))
          );

          if (popupChange) {
              // Update the edited word and counts
              if (popupChange.noIdeaAction) {
                item.editedWord = "No idea";
                item.countE = popupChange.countR;  // Ensure the count matches for each unique entry
              } else if (popupChange.typoAction) {
                item.editedWord = popupChange.selectedTypo;
                item.countE = popupChange.countR;  // Ensure the count matches for each unique entry
              } else {
                item.editedWord = popupChange.rawWord;
                item.countE = popupChange.countR;  // Ensure the count matches for each unique entry
              }
          }
      });

      

      this.showNoIdeaDialog = false;
      this.mergeNoIdeaEntries();
      localStorage.removeItem(`survey-${this.surveyId}-stimulus-${this.currentStimulus}-removeList`);
      localStorage.removeItem(`survey-${this.surveyId}-stimulus-${this.currentStimulus}-noIdeaList`);
      localStorage.removeItem(`survey-${this.surveyId}-stimulus-${this.currentStimulus}-adaptList`);

      // Collect all editing indices and edited words
      const edits = this.popupData
        .filter(item => item.selectedTypo)
        .map(item => {
          const editingIndex = this.currentDataTable.findIndex(dataItem =>
            dataItem.wordsIds.some(id => item.wordsIds.includes(id))
          );
          return { editingIndex, editedWord: item.selectedTypo };
        });

      // Call submitEditedWords for each collected edit
      edits.forEach(edit => {
        if (edit.editingIndex !== -1) {
          this.editingIndex = edit.editingIndex;
          this.editedWord = edit.editedWord;
          console.log(this.editingIndex, this.editedWord);
          this.$nextTick(() => {
            this.submitEditedWords();
          });
        }
      });
      this.closePopup();
      
    },
    resetPopupSelections() {
      this.cachedBackendData = null;
      this.noIdeaAction = false;
      this.popupData.forEach((item) => {
        const initial = this.initialpopupData.find(
          (initItem) => initItem.id === item.wordsIds
        );

        if (initial) {
          item.delete = initial.memorydelete;
          item.noIdea = initial.memorynoIdea;
          item.typo = initial.memorytypo;
          item.showTypoOptions = initial.showTypoOptions;
          item.noIdeaAction = false;
          item.deleteAction = false;
          item.typoAction = false;
          this.handleCommentChange(item, "noIdea");
          this.handleCommentChange(item, "delete");
          this.handleCommentChange(item, "typo");
          item.isTypoUpdated = false;
          item.suggestionApplied = false;
          if(!item.suggestionApplied){
            item.selectedTypo = item.rawWord;
          }
        }
      });


    },

    resetTable() {
      this.cachedBackendData = null;
      this.removeList = [];
      this.adaptList = [];
      this.noIdeaList = [];
      this.typoList = [];
      this.adaptList = [];
      for (let i = 0; i < this.currentDataTable.length; i++) {
        this.currentDataTable[i].editedWord = this.currentDataTable[i].rawWord;
        this.currentDataTable[i].countE = this.currentDataTable[i].countR;
        this.currentDataTable[i].editable = true;
        this.currentDataTable[i].selected = false;
        this.currentDataTable[i].isTypoUpdated = false;
        this.currentDataTable[i].suggestionApplied = false;
        this.currentDataTable[i].isEdited = false;
      }
      this.showResetDialog = false;
      this.prepareChartData();
    },
    setNoIdea() {
      // Retrieve the lists from localStorage
      let storedNoIdeaList = JSON.parse(
        localStorage.getItem(`survey-${this.surveyId}-stimulus-${this.currentStimulus}-noIdeaList`)
      ) || [];

      let storedRemoveList = JSON.parse(
        localStorage.getItem(`survey-${this.surveyId}-stimulus-${this.currentStimulus}-removeList`)
      ) || [];

      let storedAdaptList = JSON.parse(
        localStorage.getItem(`survey-${this.surveyId}-stimulus-${this.currentStimulus}-adaptList`)
      ) || [];

      for (let i = 0; i < this.currentDataTable.length; i++) {
        if (
          this.currentDataTable[i].selected &&
          this.currentDataTable[i].editedWord.trim().toLowerCase() !== 'no idea' &&
          this.currentDataTable[i].editedWord.trim().toLowerCase() !== 'deleted'
        ) {
          // Update the word in currentDataTable
          this.currentDataTable[i].editedWord = "No idea";
          this.currentDataTable[i].editable = false;

          // For each Association_id associated with the word
          this.currentDataTable[i].wordsIds.forEach((associationId) => {
            // Remove from storedRemoveList if it exists
            const indexInRemoveList = storedRemoveList.findIndex(
              (item) => item.Association_id === associationId
            );
            if (indexInRemoveList !== -1) {
              storedRemoveList.splice(indexInRemoveList, 1);
            }

            // Remove from storedAdaptList if it exists
            const indexInAdaptList = storedAdaptList.findIndex(
              (item) => item.Association_id === associationId
            );
            if (indexInAdaptList !== -1) {
              storedAdaptList.splice(indexInAdaptList, 1);
            }

            // Add to storedNoIdeaList if not already present
            const existsInNoIdeaList = storedNoIdeaList.some(
              (item) => item.Association_id === associationId
            );
            if (!existsInNoIdeaList) {
              storedNoIdeaList.push({
                Association_id: associationId,
                Association: this.currentDataTable[i].rawWord,
              });
            }
          });

          // Deselect the row
          this.currentDataTable[i].selected = false;
        }
      }

      // Save the updated lists back to localStorage
      localStorage.setItem(
        `survey-${this.surveyId}-stimulus-${this.currentStimulus}-removeList`,
        JSON.stringify(storedRemoveList)
      );

      localStorage.setItem(
        `survey-${this.surveyId}-stimulus-${this.currentStimulus}-adaptList`,
        JSON.stringify(storedAdaptList)
      );

      localStorage.setItem(
        `survey-${this.surveyId}-stimulus-${this.currentStimulus}-noIdeaList`,
        JSON.stringify(storedNoIdeaList)
      );

      this.prepareChartData();
      this.showNoIdeaDialog = false;
    },

    mergeNoIdeaEntries() {
      const noIdeaEntries = this.currentDataTable.filter(item => item.editedWord.toLowerCase() === "No idea");
      const aggregatedCounts = noIdeaEntries.reduce((acc, curr) => {
          return acc + (isNaN(parseInt(curr.countE)) ? 0 : parseInt(curr.countE));
      }, 0);

      noIdeaEntries.forEach(item => {
          item.countE = '-';
      });

      if (noIdeaEntries.length > 0) {
          noIdeaEntries[0].countE = aggregatedCounts.toString();
      }
      this.prepareChartData(); 
    },
      
    setDeleted() {
      // Retrieve the lists from localStorage
      let storedNoIdeaList = JSON.parse(
        localStorage.getItem(`survey-${this.surveyId}-stimulus-${this.currentStimulus}-noIdeaList`)
      ) || [];

      let storedRemoveList = JSON.parse(
        localStorage.getItem(`survey-${this.surveyId}-stimulus-${this.currentStimulus}-removeList`)
      ) || [];

      let storedAdaptList = JSON.parse(
        localStorage.getItem(`survey-${this.surveyId}-stimulus-${this.currentStimulus}-adaptList`)
      ) || [];

      for (let i = 0; i < this.currentDataTable.length; i++) {
        if (
          this.currentDataTable[i].selected &&
          this.currentDataTable[i].editedWord.trim().toLowerCase() !== "deleted"
        ) {
          // Update the word in currentDataTable
          this.currentDataTable[i].editedWord = "Deleted";
          this.currentDataTable[i].countE = "-";
          this.currentDataTable[i].editable = false;

          // For each Association_id associated with the word
          this.currentDataTable[i].wordsIds.forEach((associationId) => {
            // Remove from storedNoIdeaList if it exists
            const indexInNoIdea = storedNoIdeaList.findIndex(
              (item) => item.Association_id === associationId
            );
            if (indexInNoIdea !== -1) {
              storedNoIdeaList.splice(indexInNoIdea, 1);
            }

            // Remove from storedAdaptList if it exists
            const indexInAdaptList = storedAdaptList.findIndex(
              (item) => item.Association_id === associationId
            );
            if (indexInAdaptList !== -1) {
              storedAdaptList.splice(indexInAdaptList, 1);
            }

            // Add to storedRemoveList if not already present
            const existsInRemoveList = storedRemoveList.some(
              (item) => item.Association_id === associationId
            );
            if (!existsInRemoveList) {
              storedRemoveList.push({
                Association_id: associationId,
                Association: this.currentDataTable[i].rawWord,
              });
            }
          });

          // Deselect the row
          this.currentDataTable[i].selected = false;
        }
      }

      // Save the updated lists back to localStorage
      localStorage.setItem(
        `survey-${this.surveyId}-stimulus-${this.currentStimulus}-noIdeaList`,
        JSON.stringify(storedNoIdeaList)
      );

      localStorage.setItem(
        `survey-${this.surveyId}-stimulus-${this.currentStimulus}-adaptList`,
        JSON.stringify(storedAdaptList)
      );

      localStorage.setItem(
        `survey-${this.surveyId}-stimulus-${this.currentStimulus}-removeList`,
        JSON.stringify(storedRemoveList)
      );

      this.prepareChartData();
      this.showDeleteDialog = false;
    },
    
    handlePaginationInput(pageNumber) {
      if (
        this.disabledPages.includes(pageNumber) &&
        pageNumber < this.totalCount
      ) {
        this.current = pageNumber + 1;
        this.handlePaginationInput(pageNumber + 1);
      }
      this.currentStimulus = this.stimulusData[pageNumber - 1];
      this.CurrentStimulusName = this.resultList.find(
        (item) => item.Stimulus_id === this.currentStimulus
      ).Stimulus;
      this.$emit("word-updated", this.currentStimulus);
      this.currentIndex = pageNumber;
      this.currentAssociations = this.resultList
        .filter((item) => item.Stimulus_id === this.currentStimulus)
        .map((item) => [[item.Association_id], item.Association]);
      this.currentAssociations = this.currentAssociations.reduce(
        (freqArray, token) => {
          const index = freqArray.findIndex((pair) => pair[1] === token[1]);
          if (index !== -1) {
            freqArray[index][0].push(...token[0]);
            freqArray[index][2]++;
          } else {
            freqArray.push([[...token[0]], token[1], 1]);
          }
          return freqArray;
        },
        []
      );
      this.cachedBackendData = null;
      this.currentDataTable = [];
      this.popupData = [];
      // Load lists from localStorage
      this.removeList = JSON.parse(
        localStorage.getItem(
          `survey-${this.surveyId}-stimulus-${this.currentStimulus}-removeList`
        )
      ) || [];
      this.adaptList = JSON.parse(
        localStorage.getItem(
          `survey-${this.surveyId}-stimulus-${this.currentStimulus}-adaptList`
        )
      ) || [];
      this.noIdeaList = JSON.parse(
        localStorage.getItem(
          `survey-${this.surveyId}-stimulus-${this.currentStimulus}-noIdeaList`
        )
      ) || [];
      this.typoList = [];
      this.popUpNoIdeaList = [];
      this.popUpTypoList = [];
      this.popUpRemoveList = [];
      this.initialpopupData = [];
      this.prepareCurrentDataTable(this.currentDataTable);

    },
    openEditModal(index) {
      this.editingIndex = index;
      this.editedWord = this.currentDataTable[index].editedWord;
      this.originalUneditedWord = this.currentDataTable[index].uneditedWord;
      this.$nextTick(() => {
        this.$refs.editInput.focus();
      });
    },
    submitEditedWords() {
      if (this.editingIndex !== null) {
        this.currentDataTable[this.editingIndex].isEdited = true;

        // Retrieve the lists from localStorage
        let storedAdaptList = JSON.parse(
          localStorage.getItem(`survey-${this.surveyId}-stimulus-${this.currentStimulus}-adaptList`)
        ) || [];

        let storedNoIdeaList = JSON.parse(
          localStorage.getItem(`survey-${this.surveyId}-stimulus-${this.currentStimulus}-noIdeaList`)
        ) || [];

        let storedRemoveList = JSON.parse(
          localStorage.getItem(`survey-${this.surveyId}-stimulus-${this.currentStimulus}-removeList`)
        ) || [];

        // Trim the edited word
        const editedWordsTrimmed = this.editedWord.trim();

        // Check for duplicate edited words
        const editedIndex = this.currentDataTable.findIndex((currentDataTable) => {
          const employeeEditedWordsTrimmed = currentDataTable.editedWord.trim();
          const regex = new RegExp(`^${editedWordsTrimmed}$`, "i");
          return (
            regex.test(employeeEditedWordsTrimmed) &&
            currentDataTable !== this.currentDataTable[this.editingIndex]
          );
        });
        console.log(this.editingIndex, editedWordsTrimmed);
        if (editedIndex !== -1) {
          // Merge counts if duplicate edited word exists
          const currentCountE =
            this.currentDataTable[this.editingIndex].countE === "-"
              ? 0
              : parseInt(this.currentDataTable[this.editingIndex].countE);
          const existingCountE =
            this.currentDataTable[editedIndex].countE === "-"
              ? 0
              : parseInt(this.currentDataTable[editedIndex].countE);
          const totalCountE = currentCountE + existingCountE;
          this.currentDataTable[editedIndex].countE =
            totalCountE === 0 ? "-" : totalCountE.toString();
          this.currentDataTable[this.editingIndex].countE = "-";
          this.currentDataTable[this.editingIndex].editedWord = "-";
          this.currentDataTable[this.editingIndex].editable = false;

          // Merge wordsIds
          this.currentDataTable[editedIndex].wordsIds = [
            ...new Set([
              ...this.currentDataTable[editedIndex].wordsIds,
              ...this.currentDataTable[this.editingIndex].wordsIds,
            ]),
          ];
        } else {
          // Update the edited word with proper casing
          this.currentDataTable[this.editingIndex].editedWord =
            editedWordsTrimmed.charAt(0).toUpperCase() + editedWordsTrimmed.slice(1);
        }

        // For each Association_id associated with the word
        this.currentDataTable[this.editingIndex].wordsIds.forEach((associationId) => {
          // Remove from storedNoIdeaList if it exists
          const indexInNoIdea = storedNoIdeaList.findIndex(
            (item) => item.Association_id === associationId
          );
          if (indexInNoIdea !== -1) {
            storedNoIdeaList.splice(indexInNoIdea, 1);
          }

          // Remove from storedRemoveList if it exists
          const indexInRemoveList = storedRemoveList.findIndex(
            (item) => item.Association_id === associationId
          );
          if (indexInRemoveList !== -1) {
            storedRemoveList.splice(indexInRemoveList, 1);
          }

          // Check if Association_id exists in storedAdaptList
          const indexInAdaptList = storedAdaptList.findIndex(
            (item) => item.Association_id === associationId
          );
          if (indexInAdaptList !== -1) {
            // Replace the adaptation
            storedAdaptList[indexInAdaptList].Adapt = this.editedWord.trim();
          } else {
            // Add new adaptation
            storedAdaptList.push({
              Association_id: associationId,
              Adapt: this.editedWord.trim(),
            });
          }
        });

        // Save the updated lists back to localStorage
        localStorage.setItem(
          `survey-${this.surveyId}-stimulus-${this.currentStimulus}-noIdeaList`,
          JSON.stringify(storedNoIdeaList)
        );

        localStorage.setItem(
          `survey-${this.surveyId}-stimulus-${this.currentStimulus}-removeList`,
          JSON.stringify(storedRemoveList)
        );

        localStorage.setItem(
          `survey-${this.surveyId}-stimulus-${this.currentStimulus}-adaptList`,
          JSON.stringify(storedAdaptList)
        );

        this.closeEditModal();
      }
      this.prepareChartData();
      this.popupInput = [];
    },
    closeEditModal() {
      this.editingIndex = null;
      this.editedWords = "";
      this.originalUneditedWord = "";
    },
    search() {
      this.fetchData(this.surveyId, this.token, this.selectedOption);
    },
    loadEnvVar: function (varName) {
      let varValue = "";
      if (typeof process.env[varName] !== "undefined") {
        varValue = process.env[varName];
      } else if (document.querySelector(`meta[name="${varName}"]`) !== null) {
        varValue = document.querySelector(`meta[name="${varName}"]`).content;
      }
      this.envVars[varName] = varValue;
    },
    async fetchData(id, token, selectedOption) {
      this.isLoading = true;
      try {
        const response = await axios.post(
          `/surveys/allData_en?id=${id}&token=${token}&lan=${selectedOption}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        this.visible = true;
        this.resultList = response.data.result_list;
        this.stimulusData = this.resultList.reduce((acc, item) => {
          if (!acc.includes(item.Stimulus_id)) {
            acc.push(item.Stimulus_id);
          }
          return acc;
        }, []);
        this.currentStimulus = this.stimulusData[0];
        this.CurrentStimulusName = this.resultList.find(
          (item) => item.Stimulus_id === this.currentStimulus
        ).Stimulus;
        this.currentIndex = 1;
        let surveyObject = { surveyId: this.surveyId, token: this.token };
        this.$emit("survey-updated", surveyObject);
        this.$emit("word-updated", this.currentStimulus);
        this.totalCount = this.stimulusData.length;
        this.page = this.totalCount;
        this.currentAssociations = this.resultList
          .filter((item) => item.Stimulus_id === this.currentStimulus)
          .map((item) => [[item.Association_id], item.Association]);
        this.currentAssociations = this.currentAssociations.reduce(
          (freqArray, token) => {
            const index = freqArray.findIndex((pair) => pair[1] === token[1]);
            if (index !== -1) {
              freqArray[index][0].push(...token[0]);
              freqArray[index][2]++;
            } else {
              freqArray.push([[...token[0]], token[1], 1]);
            }
            return freqArray;
          },
          []
        );
        this.currentDataTable = [];
        this.showPopup = false;
        this.prepareCurrentDataTable(this.currentDataTable);
        this.isLoading = false;
      } catch (error) {
        this.error = error.message;
        this.isLoading = false;
      }
    },
    // saveSurvey: async function () {
    //   const deletes = this.removeList.map((item) => item.Association_id);
    //   const no_ideas = this.noIdeaList.map((item) => {
    //     if (!deletes.includes(item.Association_id)) {
    //       deletes.push(item.Association_id);
    //     }
    //     return { association_id: item.Association_id, stimulus_id: null };
    //   });

    //   const temp = this.currentDataTable
    //     .filter((item) => item.suggestionApplied || item.isEdited)
    //     .map((item) => {
    //       return {
    //         response_row_id: item.wordsIds[0],
    //         adapt_to: item.editedWord,
    //       };
    //     });
    //   const adapts = temp.filter(
    //     (element) => !deletes.includes(element.response_row_id)
    //   );

    //   const sendData = {
    //     survey_id: this.surveyId,
    //     deletes: deletes,
    //     adapts: adapts,
    //     no_ideas: no_ideas,
    //   };
    //   // const jsonData = JSON.stringify(sendData);

    //   // if (this.surveyId) {
    //   //   try {
    //   //     const response = await axios.post(
    //   //       `/restful/surveys/${this.surveyId}/response_review_histogram_save`,
    //   //       jsonData,
    //   //       {
    //   //         headers: {
    //   //           "Content-Type": "application/json",
    //   //         },
    //   //       }
    //   //     );
    //   //     toast_success("Save successfully");
    //   //   } catch (error) {
    //   //     console.error("Error:", error);
    //   //   }
    //   // }


    //   this.removeList = [];
    //   this.noIdeaList = [];
    //   this.adaptList = [];
    //   this.disabledPages.push(this.currentIndex);
    //   this.current = this.currentIndex + 1;
    //   this.closePopupSave();
    //   this.handlePaginationInput(this.current);
    // },

    submitSurvey: async function () {
      this.removeList = JSON.parse(
      localStorage.getItem(
        `survey-${this.surveyId}-stimulus-${this.currentStimulus}-removeList`
      )
      ) || [];
      this.adaptList = JSON.parse(
        localStorage.getItem(
          `survey-${this.surveyId}-stimulus-${this.currentStimulus}-adaptList`
        )
      ) || [];
      this.noIdeaList = JSON.parse(
        localStorage.getItem(
          `survey-${this.surveyId}-stimulus-${this.currentStimulus}-noIdeaList`
        )
      ) || [];

      const deletes = this.removeList.map((item) => item.Association_id);
      const no_ideas = this.noIdeaList.map((item) => {
        if (!deletes.includes(item.Association_id)) {
          deletes.push(item.Association_id);
        }
        return { association_id: item.Association_id, stimulus_id: null };
      });

      // // Check for words that are '-' after lumping in spelling correction in currentDataTable and add their Association_id to deletes list
      // this.currentDataTable.forEach((item) => {
      //   if (item.editedWord === '-') {
      //     item.wordsIds.forEach((id) => {
      //       if (!deletes.includes(id)) {
      //         deletes.push(id); // Add to deletes list if not already present
      //       }
      //     });
      //   }
      // });

      const temp = this.adaptList.map(item => {
            return {
                'response_row_id': item.Association_id,
                'adapt_to': item.Adapt
            };
        });
      const adapts = temp.filter(
        (element) => !deletes.includes(element.response_row_id)
      );

      const sendData = {
        survey_id: this.surveyId,
        deletes: deletes,
        adapts: adapts,
        no_ideas: no_ideas,
      };
      const jsonData = JSON.stringify(sendData);
      if (this.surveyId) {
        try {
          const response = await axios.post(
            `/restful/surveys/${this.surveyId}/response_review_histogram`,
            jsonData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          toast_success("Submit successfully");
          this.visible = false;
        } catch (error) {
          console.error("Error:", error);
        }
      }
      localStorage.removeItem(
        `survey-${this.surveyId}-stimulus-${this.currentStimulus}-removeList`
      );
      localStorage.removeItem(
        `survey-${this.surveyId}-stimulus-${this.currentStimulus}-adaptList`
      );
      localStorage.removeItem(
        `survey-${this.surveyId}-stimulus-${this.currentStimulus}-noIdeaList`
      );
      this.closePopupSubmit();
    },
    openPopupDelete() {
      this.showDeletePopup = true;
    },
    openPopupSubmit() {
      this.showSubmitPopup = true;
    },
    openPopupSave() {
      this.showSavePopup = true;
    },
    openPopupNoIdea() {
      this.showNoIdeaPopup = true;
    },
    closePopupDelete() {
      this.showDeletePopup = false;
    },
    closePopupSubmit() {
      this.showSubmitPopup = false;
    },
    closePopupNoIdea() {
      this.showNoIdeaPopup = false;
    },
    closePopupSave() {
      this.showSavePopup = false;
    },
  },
};
</script>

<style scoped>
/* CSS code to ensure table columns and checkboxes are properly aligned */
.histogram {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.bar {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.label {
  width: 200px;
}

.label-modify {
  cursor: pointer;
  width: 200px;
}

.delete-no-idea {
  margin-left: 10px;
}

.count-bar {
  height: 20px;
  background-color: rgb(65, 65, 247);
  margin-left: 30px;
}

.count {
  font-weight: bold;
}

.input-box {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 10px;
}

.btn {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.btnpop {
  padding: 10px 15px;
  font-size: 15px;
  border: none;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
}

.btn-ghost {
  background-color: transparent;
  color: #007bff;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.loading-spinner {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-top-color: #000;
  animation: spin 1s infinite linear;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
}

.btn-ghost {
  background-color: #71797e;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
}

.btn-cancel {
  background-color: #ffffff;
  color: #fff;
  border: none;
  border-color: rgb(87, 84, 84);
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
}

.btn-confirm {
  background-color: #71797e;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
}

.container {
  display: flex;
  justify-content: space-between;
}

.left-align {
  align-self: flex-start;
  margin-top: 50px;
}

.right-align {
  align-self: flex-end;
  margin-top: 50px;
}

.icon-container {
  position: relative;
  display: inline-block;
}

.custom-tooltip {
  font-size: 20px;
  width: 100px;
  background-color: #f0f0f0;
  color: #333;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  white-space: normal;
  overflow-wrap: break-word;
}

.icon {
  cursor: pointer;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.with-background {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  padding: 10px;
}

.original-entry {
  width: auto;
  font-size: 11px;
  line-height: 0.8;
  color: #333;
  padding: 10px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

#example {
  width: 90%;
  border-collapse: collapse;
  margin-top: 0px;
}

#popup-example {
  width: 100%;
  border-collapse: collapse;
  margin-top: auto;
}

.popup-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  padding: 20px;
  overflow-y: auto;
}

.popup-outer {
  background: white;
  border-radius: 10px;
  width: 90%;
  max-width: 1200px;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.popup-header {
  background-color: #007bff;
  color: white;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  padding: 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 16px;
  color: white;
}

.close-btn img {
  width: 16px;
  height: 16px;
}

.popup-header h2 {
  position: sticky;
  top: 0;
  margin: 0;
  font-size: 15px;
  color: #333;
}
#example th,
#example td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

#popup-example th,
#popup-example td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  padding: 15px 0px;
}

#example th {
  background-color: #f2f2f2;
}

#popup-example th {
  background-color: #f2f2f2;
}

.reset-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.reset-button:hover {
  background-color: #0056b3;
}
.openpopup-button {
  padding: 10px 20px;
  background-color: #098c25;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.openpopup-button:hover {
  background-color: #00b336a4;
}

.no-idea-button {
  padding: 10px 20px;
  background-color: #ff8800;
  color: #fff;
  border: none;
  
  border-radius: 5px;
  cursor: pointer;
}

.no-idea-button:hover {
  background-color: #d6770a;
}

.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-text {
  visibility: hidden;
  width: 170px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Adjust based on tooltip placement */
  left: 75%;
  margin-left: -125px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 14px;
}

.auto-save-info {
  background-color: #f9f9f9;
  color: #333;
  padding: 10px;
  border-left: 4px solid #ff9800;
  font-size: 14px;
  margin-bottom: 15px;
}
.info-icon {
  font-style: normal;
  font-weight: bold;
  color: #ff9800;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.delete-button {
  padding: 10px 20px;
  background-color: #ff0400;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.delete-button:hover {
  background-color: #c20505;
}

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  position: relative;
  padding: 20px;
  border-radius: 5px;
  width: 200px;
}

.edit-input {
  width: 100%;
  margin-bottom: 10px;
}

.submit-button {
  width: 100%;
}

#example tbody tr.alternate-row td {
  background-color: #f9f9f9;
}

#popup-example tbody tr.alternate-row td {
  background-color: #f9f9f9;
}

.dialog-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.dialog h2 {
  margin-top: 0;
  margin-bottom: 10px;
  color: #333;
}

.dialog p {
  margin-bottom: 20px;
  color: #666;
  font-weight: bold;
}

.dialog-buttons {
  display: flex;
  justify-content: center;
}

.dialog-buttons button {
  margin: 0 10px;
  padding: 8px 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.dialog-buttons button:hover {
  background-color: #007bff;
  color: #fff;
}

.dialog-buttons button:last-child {
  background-color: #ccc;
  color: #333;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #555;
}

.error-popup {
  padding: 20px;
  text-align: center;
  background-color: #ffcccc;
  border-radius: 8px;
  color: #b20000;
}

.btn-retry {
  margin-top: 10px;
  background-color: #007bff;
  color: #fff;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.button-container .button-row {
  display: flex;
}

.button-container button {
  margin-right: 10px;
}

.button-container button:last-child {
  margin-right: 0;
}

.table-container {
  width: 100%;
  max-height: 220px;
  min-height: 220px;
  overflow-y: auto;
  padding: 0;
}

.close-button:hover {
  color: #333;
}

#container {
  width: 90%;
  height: 400px;
  margin: 0 auto;
  margin-bottom: 15px;
}

.table-container #example-wrapper .dt-search label[for="dt-search-1"]::after {
  content: "";
  display: inline-block;
  width: 10px;
}

.table-container #example-wrapper .dt-search input#dt-search-1 {
  margin-left: 10px;
}

.table-container #example-wrapper .dt-search label[for="dt-search-1"] {
  margin-right: 10px;
}

.table-container
  #popup-example-wrapper
  .dt-search
  label[for="dt-search-1"]::after {
  content: "";
  display: inline-block;
  width: 10px;
}

.table-container #popup-example-wrapper .dt-search input#dt-search-1 {
  margin-left: 10px;
}

.table-container #popup-example-wrapper .dt-search label[for="dt-search-1"] {
  margin-right: 10px;
}

.sticky-header-main th {
  position: sticky;
  top: 0;
  background-color: #f2f2f2;
}

.sticky-header th {
  position: sticky;
  top: 0;
  background-color: #f2f2f2;
  z-index: 1;
}

/* .table-wrapper {
  max-height: calc(100vh - 280px);
  overflow-y: auto;
} */

.welcome-text {
  margin-left: 0px;
  text-align: left;
  color: #333;
  font-size: 16px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.5;
  margin-bottom: 20px;
}

.header-row th {
  background-color: #4ba1f7 !important;
  color: rgb(255, 255, 255);
}

.dataTables_empty {
  display: none;
}

.dialog-buttons button:last-child:hover {
  background-color: #aaa;
}

.popup-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.close-popup-icon-btn {
  position: absolute;
  top: 30px;
  right: 30px;
  background-color: white;
  border: 2px solid #ccc;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 100;
}

.close-popup-icon-btn:hover {
  background-color: #686060;
}

.popup-footer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-top: 50px;
}

.selectAllDelete {
  display: flex;
  gap: 5px;
}

.selectAllNoIdea {
  display: flex;
  gap: 5px;
}

.selectAllGibberish {
  display: flex;
  gap: 5px;
}

.table-wrapper {
  overflow-y: auto;
  overflow-x: auto;
  max-height: calc(100vh - 180px);
  padding-left: 10px;
  width: 100%;
  position: relative;
}

.table-wrapper table {
  width: 100%;
  border-collapse: collapse;
}

.table-wrapper th,
.table-wrapper td {
  text-align: center;
  vertical-align: middle;
  padding: 10px;
}

.table-wrapper td input[type="checkbox"] {
  margin: 0 auto;
  display: block;
  width: 40px;
  height: 20px;
}

.scrollable-popup-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  white-space: nowrap;
}

.scrollable-popup-table th {
  background-color: #4a90e2;
  color: white;
  font-weight: bold;
  text-align: left;
  padding: 0px;
  border: 1px solid #ddd;
  position: sticky;
  top: 0;
  z-index: 2;
}

/* Ensure table cells don't overflow */
.scrollable-popup-table td {
  padding-top: 100px;
  border: 1px solid #ddd;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Make the table horizontally scrollable when needed */
@media (max-width: 768px) {
  .table-wrapper {
    overflow-x: auto;
  }
  .scrollable-popup-table {
    display: block;
    white-space: nowrap;
  }
}


.comment-boxes {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: space-evenly;
}

.comment-label {
  width: auto;
  border: 0px solid #ccc;
  background-color: #ffffff;
  padding: 5px;
  text-align: center;
  transition: background-color 0.2s, color 0.2s;
  margin-bottom: 5px;
}

.comment-label input[type="checkbox"] {
  position: absolute;
  opacity: 0; /* Hide the checkbox itself */
  background-color: #8fbc8f;
}

.comment-label span {
  pointer-events: none;
  display: block;
  width: 100%;
}

.action-boxes {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  gap: 0px;
}

.action-boxes label {
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}

.action-boxes input[type="checkbox"] {
  margin-right: 0px;
}

.popup-footer {
  display: flex;
  justify-content: center;
  padding: 15px;
  background: #f9f9f9;
  border-top: 1px solid #ddd;
  flex-wrap: wrap;
}

.popup-footer .btnpop {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  flex: 1;
  margin: 5px;
  text-align: center;
  min-width: 100px;
}

@media (max-width: 600px) {
  .popup-footer {
    flex-direction: column;
    align-items: center;
  }
  .popup-footer .btnpop {
    width: 90%;
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .popup-outer {
    width: 95%;
    max-height: 90vh;
  }
  .popup-header {
    font-size: 16px;
    padding: 10px;
  }
  .close-btn {
    top: 10px;
    right: 10px;
  }
  .table-wrapper {
    max-height: 50vh;
  }
}

.btn-reset {
  background-color: #4ba1f7;
  color: #fff;
  border: none;
  border-radius: 5px;
}

.btn-closepopup {
  background-color: #ff0400;
  color: #fff;
  border: none;
  border-radius: 5px;
}

.btn-submit {
  background-color: #10760e;
  color: #fff;
  border: none;
  border-radius: 5px;
}

.popup-footer .btnpop:hover {
  opacity: 0.5;
}
.typo-options {
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 85%;
  align-items: center;
  justify-content: space-evenly;
  white-space: nowrap;
  overflow: auto;
}

.typo-options label {
  margin-left: 5px;
  white-space: nowrap;
  padding-right: 10px;
}

.is-green {
  background-color: #8fbc8f;
}

.info-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: hsl(27, 94%, 43%);
  font-size: 20px;
}
.check-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #10760e;
  font-size: 20px;
}
.info-box {
  position: absolute;
  flex-wrap: nowrap;
  top: 0%;
  right: 0;
  min-width: 400px;
  max-width: 600px;
  /* width: auto; */
  background-color: rgba(249, 249, 249, 1);
  color: #333;
  padding: 2px;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
  display: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  font-size: 15px;
  white-space: nowrap;
}

.info-icon:hover .info-box {
  display: block;
}
.check-icon:hover .info-box {
  display: block;
}
.edited-row td {
  background-color: #32cd32;
}
.asset-image {
    display: block;
    width: 20px;
    height: 70px;
    text-align: center;
    margin: 0 auto;
    padding-top: 25px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.big-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 30%;
  max-height: calc(100vh - 180px);
  padding-top: 25px;
  padding-right: 15px;
  padding-left: 15px;
}

.asset-image img {
    max-width: 50%;
    max-height: 50%;
    object-fit: contain;
}
.content-flex {
  display: flex;
  align-items: start;
  justify-content: center;
  width: 100%;
  height: auto;
  flex-grow: 1;
}


::v-deep .q-pagination .q-btn {
  margin: 0 2px; 
  padding: 8px; 
  z-index: 10; 
}

::v-deep .q-pagination .q-btn:hover {
  z-index: 2; 
}

::v-deep .q-btn__content.text-center.col.items-center.q-anchor--skip.justify-center.row {
  margin: 0; 
}

::v-deep .q-pagination .row{
  --bs-gutter-x: 1;
}
</style>
