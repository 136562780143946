<template>
<section class="middle-section">
    <div class="container">
        <div class="subsc-pg-order">
            <div class="top-pg-hed">
                <h6>Order Summary</h6>
            </div>
            <div class="flx-tow-col">
                <div class="flx-lft-col-box">
                    <div class="high-lavel-col">
                        <div class="lavel-box">High Level</div>
                        <div class="hig-volm-col">
                            <!--<label>Add target Association </label>-->
                            <select class="form-select" aria-label="form-select-sm example">
                                <option selected="">1 month</option>

                            </select>
                            <span class="sml-text">Renew March 1, 2023 for € 800/month </span>
                        </div>
                        <div class="month-pri">€ 800/month<span>1600 Mindcoins</span></div>

                    </div>
                    <div class="ul-col">
                        <h5>Plan Includes</h5>
                        <ul>
                            <li><a href="#">Track your brand image</a></li>
                            <li><a href="#">Track your competitor</a></li>
                            <li><a href="#">Track your associations</a></li>
                            <li><a href="#">Pretest your creatives</a></li>
                            <li><a href="#">Tune your creatives</a></li>
                            <li><a href="#">Impress your audience</a></li>
                            <li><a href="#">Monitor brand equity</a></li>
                        </ul>
                    </div>
                </div>
                <div class="gry-sml-rgt-bx">
                    <div class="top-pg-hed">
                        <h6>Order Summary</h6>
                    </div>
                    <div class="sub-head">
                        <spna class="lft-tx-com">Subtotal (€)</spna>
                        <spna class="rgt-tx-com">€ 1880</spna>
                    </div>
                    <div class="ext-sml-txt">Subtotal includes all applicable taxes</div>
                    <div class="mid-sml-txt">You’re getting the best price we have got</div>

                    <div class="btn-div"><a @click="$router.push('/payment_method')" class="btn3">I’m Ready to Pay</a></div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    name: "OrderSummary"
}
</script>